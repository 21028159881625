import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  title: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
const SMJModelItem = ({ onSelect, disabledSelect, smjModel }) => {
  const { classes, cx } = useStyles();
  return (
    <Card
      onClick={event => {
        event.preventDefault();
        if (!disabledSelect) onSelect();
      }}
      elevation={1}
      className={classes.card}
    >
      <CardContent
        className={cx(classes.content, { selectable: !disabledSelect })}
        classes={{ root: classes.contentRoot }}
      >
        <Typography color="primary" className={classes.title} variant="body2">
          {utils.getLang(`smartmessaging.campaigns.grouped.groupModel.name.${smjModel.key}`)}
        </Typography>
        <Typography color="textPrimary" className={classes.description} variant="caption">
          {utils.getLang(`smartmessaging.campaigns.grouped.groupModel.description.${smjModel.key}`)}
        </Typography>
      </CardContent>
    </Card>
  );
};

SMJModelItem.propTypes = {
  smjModel: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  disabledSelect: PropTypes.bool,
};

SMJModelItem.defaultProps = {
  disabledSelect: false,
  onSelect: () => {},
};

export default SMJModelItem;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MassActionCriteriaField from '../MassActionCriteriaField';
import GroupCriteriaBloc from 'src/components/common/GroupCriteriaBloc';
import { Box } from '@mui/material';

const GroupField = ({
  isWritable,
  groupName,
  value,
  uniqueId,
  model,
  removable,
  validity,
  groupDisplayName,
}) => (
  <Box m={0.5}>
    <GroupCriteriaBloc title={groupDisplayName} showTitle={model.descriptors?.length > 1}>
      {Object.values(value).map(groupCriteriaValue => (
        <Fragment key={`sub${groupCriteriaValue.model.key}`}>
          {Object.entries(groupCriteriaValue.criteriaValues).map(([childUniqueId, subcv]) => (
            <MassActionCriteriaField
              key={childUniqueId}
              isWritable={isWritable}
              uniqueId={childUniqueId}
              groupName={groupName}
              model={groupCriteriaValue.model}
              value={subcv}
              removable={removable}
              validity={validity.validities?.[childUniqueId] || { isValid: true, messages: [] }}
              parent={{ uniqueId, model }}
            />
          ))}
        </Fragment>
      ))}
    </GroupCriteriaBloc>
  </Box>
);

GroupField.propTypes = {
  value: PropTypes.object.isRequired,
  uniqueId: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  removable: PropTypes.bool.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  groupDisplayName: PropTypes.string.isRequired,
};

export default GroupField;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import ClubParameterList from './ClubParameterList';
import SmartMessagingConfig from './SmartMessagingConfig';

import ParametersAppBar from './ParametersAppBar';
import utils from 'src/utils/utils';

const ParametersContent = ({ paramsMap }) => {
  const [selectionMode, showList] = useState(Object.values(paramsMap).length > 1);
  return (
    <div
      style={{
        height: '100vh',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <div style={{ display: 'flex', padding: '10px', height: '50px' }}>
        <Typography variant="h5" color="textSecondary" style={{ margin: 'auto 16px' }}>
          {utils.getLang('smartmessaging.config.mainTitle')}
        </Typography>
      </div>
      <Divider />

      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ParametersAppBar showList={() => showList(true)} selectionMode={selectionMode} />
        {(selectionMode && (
          <ClubParameterList paramsMap={paramsMap} hideList={() => showList(false)} />
        )) || <SmartMessagingConfig />}
      </div>
    </div>
  );
};

ParametersContent.propTypes = {
  paramsMap: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  paramsMap: state.parameters.clubParams,
});

export default connect(mapStateToProps, {})(ParametersContent);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';
import uuid from 'uuid';
import utils from 'src/utils/utils';
import hUtils from 'src/components/common/history/utils';
import FilterType from 'src/enums/parameterType';
import { OperatorTypeById } from 'src/enums/operatorType';
import UsedFilterValue from 'src/components/common/history/UsedFilterValue';

const Typo = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography color="textSecondary" component="span" style={{ ...theme.typography.body2 }}>
      {children}
    </Typography>
  );
};

Typo.propTypes = { children: PropTypes.any.isRequired };

const UsedFilterRender = ({ filter, filtersByKey }) => (
  <div style={{ textAlign: 'left' }}>
    <Typo>
      {`${utils.getLang('smartmessaging.campaign.history.filterBy')} ${utils.getLang(
        `smartmessaging.campaign.history.filterLabel.${filter.key}`
      )} `}
    </Typo>
    <Typo>
      {`${utils.getLang(
        `smartmessaging.recipeSetupOperator.${OperatorTypeById[filter.operatorId]}`
      )} `}
    </Typo>
    <UsedFilterValue filter={filter} filtersByKey={filtersByKey} />
  </div>
);

UsedFilterRender.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

const GroupUsedFilter = ({ parent, filters, filtersByKey }) => (
  <Fragment>
    <Typo>
      {`${utils.getLang('smartmessaging.campaign.history.filterBy')} ${utils.getLang(
        `smartmessaging.campaign.history.filterLabel.${parent.key}`
      )}:`}
    </Typo>
    {Object.values(filters).map(list =>
      list.map(f => (
        <div key={uuid()} style={{ paddingLeft: '8px' }}>
          <Typo>
            {`-${utils.getLang(
              `smartmessaging.campaign.history.filterLabel.${f.key}`
            )} ${utils.getLang(
              `smartmessaging.recipeSetupOperator.${OperatorTypeById[f.operatorId]}`
            )} `}
          </Typo>
          <UsedFilterValue filter={f} filtersByKey={filtersByKey} />
        </div>
      ))
    )}
  </Fragment>
);

GroupUsedFilter.propTypes = {
  parent: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

const UsedFilter = ({ filter, filtersByKey }) => {
  switch (filter.typeId) {
    case FilterType.GROUP:
    case FilterType.CLUBS:
      return (
        <GroupUsedFilter
          filters={hUtils.proceedSetup(filter.values)}
          parent={filter}
          filtersByKey={filtersByKey[filter.key].descriptors.reduce(
            (map, f) => ({ ...map, [f.key]: f }),
            {}
          )}
        />
      );
    default:
      return <UsedFilterRender filter={filter} filtersByKey={filtersByKey} />;
  }
};

UsedFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};
export default UsedFilter;

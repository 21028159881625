import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Radio } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  baseIcon: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    borderRadius: '50%',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  icon: {
    backgroundColor: theme.palette.background.default,
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  const { classes, cx } = useStyles();

  return (
    <Radio
      disableRipple
      checkedIcon={<span className={cx(classes.baseIcon, classes.checkedIcon)} />}
      icon={<span className={cx(classes.baseIcon, classes.icon)} />}
      {...props}
    />
  );
}

export default StyledRadio;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withSteps from 'src/components/common/hocs/withSteps';
import CampaignActions from './CampaignActions';
import MessageEditor from './MessageEditor';
import MessageModelSelector from './MessageModelSelector';

export const [MESSAGE_TYPE, MESSAGE_MODEL, MESSAGE_EDITOR] = [0, 1, 2];
function StepHandler({ currentStep, goToStep, showBtns, ...otherProps }) {
  useEffect(() => {
    switch (currentStep) {
      case MESSAGE_MODEL:
      case MESSAGE_EDITOR:
        showBtns(false);
        break;
      default:
        showBtns(true);
    }
  }, [currentStep, showBtns]);
  switch (currentStep) {
    case MESSAGE_TYPE:
      return <CampaignActions {...otherProps} goToCmpMsgStep={goToStep} />;

    case MESSAGE_MODEL:
      return <MessageModelSelector {...otherProps} goToCmpMsgStep={goToStep} />;

    case MESSAGE_EDITOR:
      return <MessageEditor {...otherProps} goToCmpMsgStep={goToStep} />;
    default:
      return <CampaignActions {...otherProps} goToCmpMsgStep={goToStep} />;
  }
}
StepHandler.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  showBtns: PropTypes.func.isRequired,
};

export default withSteps(StepHandler);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, ListItem, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  menuItemName: {
    color: theme.palette.text.main,
    background: theme.palette.action.hover,
    ...theme.typography.body2,
  },
  menuItem: {
    outline: 'none',
    textOverflow: 'ellipsis',
  },
}));

function CampaignItemMenu({ campaign, options, selectRow }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();

  function onItemClick(option) {
    setAnchorEl(null);
    option.action();
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={anchorEl ? `item-menu-${campaign.id}` : null}
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          selectRow(campaign.id);
          handleClick(e);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{ disablePadding: true }}
        id={`item-menu-${campaign.id}`}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          selectRow(null);
          handleClose();
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
      >
        <ListItem className={classes.menuItemName} divider>
          {campaign.name}
        </ListItem>
        {options.map(
          option =>
            !option.hidden && (
              <MenuItem
                dense
                className={classes.menuItem}
                disabled={(!(option.disabled === undefined) && option.disabled) || false}
                key={option.name}
                onClick={() => onItemClick(option)}
              >
                {option.name}
              </MenuItem>
            )
        )}
      </Menu>
    </div>
  );
}

CampaignItemMenu.propTypes = {
  campaign: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  selectRow: PropTypes.func.isRequired,
};

export default CampaignItemMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@mui/material';
import Language from '@mui/icons-material/Language';
import LangButton from './LangButton';
import { useTheme } from '@mui/material/styles';

const getLangObj = (shortLn, lnsObjs) => {
  const rez = lnsObjs.filter(lnObj => lnObj.shortName === shortLn)[0];
  return rez || lnsObjs.filter(lnObj => lnObj.shortName === 'FR')[0];
};

const getLnObjByLocale = (locale, lnsObjs) => {
  const rez = lnsObjs.filter(lnObj => lnObj.locale === locale)[0];
  return rez || lnsObjs.filter(lnObj => lnObj.shortName === 'FR')[0];
};

function ContentLangBtn({ availableLanguages, currentLang, onContentLangChange, title }) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.secondary,
      }}
    >
      <Icon aria-label="clear" style={{ marginRight: '8px' }}>
        <Language />
      </Icon>
      <LangButton
        {...{
          title,
          langs: availableLanguages,
          currentLang: getLangObj(currentLang, availableLanguages),
          selectLang: locale =>
            onContentLangChange(getLnObjByLocale(locale, availableLanguages).shortName),
        }}
      />
    </div>
  );
}

ContentLangBtn.propTypes = {
  currentLang: PropTypes.string,
  title: PropTypes.string.isRequired,
  onContentLangChange: PropTypes.func.isRequired,
  availableLanguages: PropTypes.array.isRequired,
};

ContentLangBtn.defaultProps = {
  currentLang: null,
};

export default ContentLangBtn;

import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ValidityAdornment from './fields/ValidityAdornment';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  textField: {
    display: 'flex',
    margin: '8px',
  },
  textFieldInput: {
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
  },
}));

const SMJTitleField = ({ value, isValid, onChange }) => {
  const { classes } = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      <TextField
        style={{ flex: 1 }}
        value={value}
        InputProps={{
          className: classes.textFieldInput,
          id: 'cmp-name-ipt',
        }}
        className={classes.textField}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
      <ValidityAdornment
        position="start"
        validity={{
          isValid,
          messages: [utils.getLang('smartmessaging.smartjourney.creator.smjNameRequired')],
        }}
      />
    </div>
  );
};

SMJTitleField.propTypes = {
  value: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

SMJTitleField.defaultProps = {
  value: null,
};

export default SMJTitleField;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@mui/material';
import utils from 'src/utils/utils';

import ValidityAdornment from './ValidityAdornment';

const NotifyContentLabel = ({ contentValidity }) => (
  <div style={{ display: 'flex' }}>
    <Typography
      color={contentValidity.contentIsEmpty ? 'error' : 'textSecondary'}
      style={{
        margin: '5px 10px',
      }}
      variant="subtitle2"
      noWrap
    >
      {utils.getLang('smartmessaging.contentEditor.notify.content')}
    </Typography>
    <ValidityAdornment
      valid={!contentValidity.contentIsEmpty}
      message={utils.getLang('smartmessaging.contentEditor.notify.contentIsEmpty')}
    />
  </div>
);

NotifyContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  contentValidity: state.massAction.contentValidity,
});

export default connect(mapStateToProps, {})(NotifyContentLabel);

import React from 'react';
import { connect } from 'react-redux';
import getApiCaller from 'src/utils/apiClientCaller';

const ApiCallerProvider = ({ doGetApiCaller, children }) => children({ callApi: doGetApiCaller() });

const actionCreators = {
  doGetApiCaller: () => getApiCaller,
};
const Connected = connect(() => ({}), actionCreators)(ApiCallerProvider);

export default Cmp => props => (
  <Connected>{({ callApi }) => <Cmp {...props} callApi={callApi} />}</Connected>
);

import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

export default connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: (state, ownProps) =>
    `/auth/${ownProps.match.params.clientToken}${ownProps.location.search}`,
  // If selector is true, wrapper will not redirect
  authenticatedSelector: state => state.auth.isAuthenticated,
  allowRedirectBack: false,
  // A nice display name for this check
  wrapperDisplayName: 'userIsAuthenticated',
});

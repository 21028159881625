// import React from 'react';
import { connect } from 'react-redux';
import { checkContentValidity } from 'src/reducers/campaignEditor';

import EmailContentLabel from 'src/components/common/contentEditor/EmailContentLabel';

const mapStateToProps = state => ({
  contentValidity: checkContentValidity(state),
});

export default connect(mapStateToProps, {})(EmailContentLabel);

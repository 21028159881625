import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CampaignTarget,
  CampaignInfos,
  CampaignMessage,
  EditorAppBar,
  CampaignSchedule,
} from './editorComponents';
import withSteps from 'src/components/common/hocs/withSteps';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import {
  saveCampaign,
  checkCmpInfosValidity,
  checkCampaignValidity,
} from 'src/reducers/campaignEditor';

import { getSortedCategoryList } from 'src/enums/campaignCategory';
import { deleteCampaign } from 'src/reducers/campaignList';

const mapStateToProps = state => ({
  editedCampaign: state.campaignEditor.editedCampaign,
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
  selectedType: state.campaignEditor.selectedType,
  requestModelsByCategoryId: state.requestModelList.requestModelsByCategoryId,
  sortedCategoryList: getSortedCategoryList(),
  recipeCfgLoaded: state.campaignEditor.recipeFieldsConfig.loaded,
  campaignActions: state.campaignEditor.actions.campaignActions,
  infosValidity: checkCmpInfosValidity(state),
  campaignValidity: checkCampaignValidity(state),
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleNewAboTypes: state.app.wizVilleNewAboRequestModels,
  wizVilleCancelAboTypes: state.app.wizVilleCancelAboRequestModels,
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  wizVilleNewAboActivated: state.app.config.wizVilleNewAboActivated,
  wizVilleCancelAboActivated: state.app.config.wizVilleCancelAboActivated,
  periods: state.campaignEditor.schedule.editedCmpPeriods,
});

const actionCreators = {
  updateEditedCampaign: changes => ({ type: 'UPDATE_EDITED_CAMPAIGN', value: changes }),
  createCampaign: () => ({ type: 'CREATE_CAMPAIGN' }),

  doSaveCampaign: saveCampaign,
  doDeleteCampaign: deleteCampaign,
  selectCampaignType: requestModel => ({
    type: 'SELECT_CAMPAIGN_TYPE',
    value: requestModel,
  }),
  selectOptinType: optinType => ({
    type: 'SELECT_OPTIN_TYPE',
    value: optinType,
  }),
};

export const [INFOS, TARGET, CONTENT, SCHEDULE] = [0, 1, 2, 3];

const StepHandler = ({ currentStep, goToStep, showBtns, ...otherProps }) => {
  useEffect(() => {
    if (currentStep !== CONTENT) showBtns(true);
  }, [currentStep, showBtns]);
  switch (currentStep) {
    case INFOS:
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;

    case TARGET:
      return <CampaignTarget {...otherProps} goToEditorStep={goToStep} />;

    case CONTENT:
      return <CampaignMessage {...otherProps} showBtns={showBtns} goToEditorStep={goToStep} />;
    case SCHEDULE:
      return <CampaignSchedule {...otherProps} goToEditorStep={goToStep} />;
    default:
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;
  }
};

StepHandler.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  showBtns: PropTypes.func.isRequired,
};

function WithAppBar({ goToStep, doDeleteCampaign, doSaveCampaign, backToList, ...props }) {
  const [visibleBtns, showBtns] = useState(true);
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
      }}
    >
      <EditorAppBar
        {...props}
        deleteCampaign={doDeleteCampaign}
        saveCampaign={doSaveCampaign}
        goToEditorStep={goToStep}
        visibleBtns={visibleBtns}
        backToList={backToList}
      />
      <StepHandler {...{ ...props, doSaveCampaign, goToStep, showBtns }} />
    </div>
  );
}

WithAppBar.propTypes = {
  goToStep: PropTypes.func.isRequired,
  doDeleteCampaign: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  backToList: PropTypes.func.isRequired,
};

export default withSteps(connect(mapStateToProps, actionCreators)(WithAppBar));

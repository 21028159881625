import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from '../ReactCKEditor';

const SmsEditor = ({ fields, content, onEditorLoaded, onChange, readOnly }) => (
  <div>
    <CKEditor
      name="objectEditor"
      config={{
        readOnly: !!readOnly,
        fields: fields.fields,
        removeButtons:
          'Underline,Subscript,Superscript,' +
          'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
          'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
          'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
          'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
          'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
          'BidiLtr,BidiRtl,CreatePlaceholder,' +
          'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
        extraPlugins: 'strinsert',
        removePlugins: 'colorbutton,elementspath',
        autoParagraph: false,
        enterMode: 2,
      }}
      events={{
        change: onChange,
        loaded: event => {
          onEditorLoaded(event.editor);
        },
      }}
      content={content}
    />
  </div>
);

SmsEditor.propTypes = {
  fields: PropTypes.any.isRequired,
  content: PropTypes.string.isRequired,
  onEditorLoaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

SmsEditor.defaultProps = {
  readOnly: false,
};

export default SmsEditor;

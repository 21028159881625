const columnNames = [
  // 'id',
  'name',
  // 'enabled',
  // 'networkNodeId',
  // 'clientToken',
  'creationDate',
  'creationUserName',
  'networkNodeName',
  // 'eventDriven',
  // 'recipeId',
  // 'actionType',
  // 'creationUserId',
  // 'requestModelTypeId',
  // 'story',
];

const columnsSorters = {
  id: {
    asc: (a, b) => (a < b ? -1 : 1),
    desc: (a, b) => (a < b ? 1 : -1),
  },
  name: {
    asc(a, b) {
      const sortValueA = a.name ? a.name.toLowerCase() : false;
      const sortValueB = b.name ? b.name.toLowerCase() : false;
      if (!sortValueA) return 1;
      if (!sortValueB) return -1;
      return sortValueA < sortValueB ? -1 : 1;
    },
    desc(a, b) {
      const sortValueA = a.name ? a.name.toLowerCase() : false;
      const sortValueB = b.name ? b.name.toLowerCase() : false;
      if (!sortValueA) return -1;
      if (!sortValueB) return 1;
      return sortValueA > sortValueB ? -1 : 1;
    },
  },
  creationDate: {
    asc: (a, b) => (a.creationDate < b.creationDate ? -1 : 1),
    desc: (a, b) => (a.creationDate < b.creationDate ? 1 : -1),
  },
  networkNodeName: {
    asc(a, b) {
      const sortValueA = a.networkNodeName ? a.networkNodeName.toLowerCase() : false;
      const sortValueB = b.networkNodeName ? b.networkNodeName.toLowerCase() : false;
      if (!sortValueA) return 1;
      if (!sortValueB) return -1;
      return sortValueA < sortValueB ? -1 : 1;
    },
    desc(a, b) {
      const sortValueA = a.networkNodeName ? a.networkNodeName.toLowerCase() : false;
      const sortValueB = b.networkNodeName ? b.networkNodeName.toLowerCase() : false;
      if (!sortValueA) return -1;
      if (!sortValueB) return 1;
      return sortValueA > sortValueB ? -1 : 1;
    },
  },
  creationUserName: {
    asc(a, b) {
      const sortValueA = a.creationUserName ? a.creationUserName.toLowerCase() : false;
      const sortValueB = b.creationUserName ? b.creationUserName.toLowerCase() : false;
      if (!sortValueA) return 1;
      if (!sortValueB) return -1;
      return sortValueA < sortValueB ? -1 : 1;
    },
    desc(a, b) {
      const sortValueA = a.creationUserName ? a.creationUserName.toLowerCase() : false;
      const sortValueB = b.creationUserName ? b.creationUserName.toLowerCase() : false;
      if (!sortValueA) return -1;
      if (!sortValueB) return 1;
      return sortValueA > sortValueB ? -1 : 1;
    },
  },
};

const columnRenderers = {
  creationDate: time => new Date(time).toLocaleDateString(),
};

const renderCell = (colName, massActionCampaign) => {
  if (columnRenderers[colName]) return columnRenderers[colName](massActionCampaign[colName]);
  return massActionCampaign[colName];
};

export default { columnNames, columnsSorters, renderCell };

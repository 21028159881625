import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Fade, Typography } from '@mui/material';

import apiClient from 'src/utils/apiClient';
import customTemplateApi from 'src/utils/api/customTemplateApi';
import getApiCaller from 'src/utils/apiClientCaller';
import utils from 'src/utils/utils';

import MessageModelItem from 'src/components/common/MessageModelItem';
import FileUploaderBtn from 'src/components/common/FileUploaderBtn';
import Loader from 'src/components/common/Loader';
import AccordionItem from 'src/components/common/AccordionItem';

import CardList from 'src/components/common/CardList';
import MessagesLangBtn from './MessagesLangBtn';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';

import {
  selectMailMessageModel,
  selectSmsMessageModel,
  selectCustomTemplate,
  selectUploadedMailModel,
  selectUploadedSmsModel,
  selectNotificationMessageModel,
  selectNotificationUploadedModel,
} from 'src/reducers/campaignEditor';
import { loaderActions } from 'src/reducers/loader';
import ActionType from 'src/enums/actionType';
import {
  MESSAGE_EDITOR,
  MESSAGE_TYPE,
} from 'src/components/campaign/campaignEditor/editorComponents/campaignContent/CampaignMessage';

const MessageTemplateSelectorRender = ({
  templates,
  customTemplates,
  expanded,
  setExpanded,
  onMessageModelSelect,
  goToCmpMsgStep,
  onCustomTplSelect,
  onUploadTemplate,
  langs,
  setLang,
  lang,
}) => (
  <Fade in timeout={500}>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <AccordionItem
          expanded={expanded === 'resagenerics'}
          onChange={(e, value) => {
            setExpanded((value && 'resagenerics') || '');
          }}
          title={utils.getLang('smartmessaging.contentEditor.selectResaGenericTemplate')}
        >
          <div
            style={{
              flex: 1,
              padding: '8px',
              overflow: 'hidden',
            }}
          >
            <div style={{ textAlign: 'right' }}>
              <MessagesLangBtn selectLang={setLang} langs={langs} currentLang={lang} />
            </div>
            {templates.generics.map(messageModel => (
              <MessageModelItem
                key={messageModel.id}
                messageModel={messageModel}
                onMessageModelSelect={onMessageModelSelect}
                lang={lang}
                hideLang={!messageModel.key.slice(-3).match(/_[A-Z]{2}/)}
              />
            ))}
          </div>
        </AccordionItem>
        {!!templates.ready.length && (
          <AccordionItem
            expanded={expanded === 'resaready'}
            onChange={(e, value) => {
              setExpanded((value && 'resaready') || '');
            }}
            title={utils.getLang('smartmessaging.contentEditor.selectResaTemplate')}
          >
            <div
              style={{
                flex: 1,
                padding: '8px',
                overflow: 'hidden',
              }}
            >
              <div style={{ textAlign: 'right' }}>
                <MessagesLangBtn selectLang={setLang} langs={langs} currentLang={lang} />
              </div>
              {templates.ready.map(messageModel => (
                <MessageModelItem
                  key={messageModel.id}
                  messageModel={messageModel}
                  onMessageModelSelect={onMessageModelSelect}
                  lang={lang}
                  hideLang={!messageModel.key.slice(-3).match(/_[A-Z]{2}/)}
                />
              ))}
            </div>
          </AccordionItem>
        )}
        {!!customTemplates.generics.length && (
          <AccordionItem
            expanded={expanded === 'custom'}
            onChange={(e, value) => {
              setExpanded((value && 'custom') || '');
            }}
            title={utils.getLang('smartmessaging.contentEditor.selectGenericCustomTemplate')}
          >
            <CardList
              items={customTemplates.generics}
              onItemClick={(e, tpl) => {
                e.preventDefault();
                onCustomTplSelect(tpl);
              }}
            />
          </AccordionItem>
        )}
        {!!customTemplates.ready.length && (
          <AccordionItem
            expanded={expanded === 'customready'}
            onChange={(e, value) => {
              setExpanded((value && 'customready') || '');
            }}
            title={utils.getLang('smartmessaging.contentEditor.selectCustomTemplate')}
          >
            <div
              style={{
                flex: 1,
                padding: '8px',
                overflow: 'hidden',
              }}
            >
              <CardList
                items={customTemplates.ready}
                onItemClick={(e, tpl) => {
                  e.preventDefault();
                  onCustomTplSelect(tpl);
                }}
              />
            </div>
          </AccordionItem>
        )}

        <AccordionItem
          expanded={expanded === 'uploadTemplate'}
          onChange={(event, value) => {
            setExpanded((value && 'uploadTemplate') || '');
          }}
          title={utils.getLang('smartmessaging.contentEditor.uploadTemplate')}
        >
          <FileUploaderBtn
            selectFileLabel={utils.getLang(
              'smartmessaging.contentEditor.customTemplate.chooseFile'
            )}
            sendFileLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.sendFile')}
            cancelLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.cancel')}
            displayCmp={file => <Typography>{file.name}</Typography>}
            onFileChange={(e, pFile) => {
              if (!e && !pFile) {
                return false;
              }
              const file = pFile || e.target.files[0];
              if (!file) {
                return false;
              }
              return true;
            }}
            onSend={file => {
              const reader = new FileReader();
              reader.onload = () => {
                onUploadTemplate(reader.result);
              };
              reader.readAsText(file);
            }}
          />
        </AccordionItem>
      </div>
      <div style={{ textAlign: 'right', padding: '4px 16px' }}>
        <Button
          style={{ margin: '4px' }}
          variant="text"
          color="primary"
          onClick={() => {
            goToCmpMsgStep(MESSAGE_TYPE);
          }}
        >
          {utils.getLang('smartmessaging.button.contentEditor.cancel')}
        </Button>
      </div>
    </div>
  </Fade>
);

MessageTemplateSelectorRender.propTypes = {
  templates: PropTypes.object.isRequired,
  customTemplates: PropTypes.object.isRequired,
  expanded: PropTypes.string,
  setExpanded: PropTypes.func.isRequired,
  onMessageModelSelect: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  onCustomTplSelect: PropTypes.func.isRequired,
  onUploadTemplate: PropTypes.func.isRequired,
  langs: PropTypes.array.isRequired,
  setLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

MessageTemplateSelectorRender.defaultProps = { expanded: null };

function MessageTemplateSelectorResolver({
  actionTypeId,
  rmTypeId,
  rmId,
  loadFieldsAndContent,
  doGetApiCaller,
  goToCmpMsgStep,
  setLoader,
  doSelectMailMessageModel,
  doSelectSmsMessageModel,
  doSelectCustomTemplate,
  doSelectUploadedMailModel,
  doSelectUploadedSmsModel,
  doSelectNotificationMessageModel,
  doSelectNotificationUploadedModel,
  currentAppLn,
}) {
  const [templates, setTemplates] = useState(null);

  const [lang, setLang] = useState(null);
  const [langs, setLangs] = useState([]);

  useEffect(() => {
    let fetchData;
    if (!templates) {
      fetchData = async () => {
        const apiCaller = doGetApiCaller();
        const list = await apiCaller(apiClient.getMessageModels, [rmId, actionTypeId]);

        const sortedMessages = contentEditorUtils.sortGenericMessageModels(list);
        const customList = await apiCaller(customTemplateApi.getTemplateByRequestModelType, [
          rmTypeId,
          actionTypeId,
        ]);

        const gCustomList = await apiCaller(customTemplateApi.getGenericTemplates, [actionTypeId]);

        const sortedCustoms = contentEditorUtils.sortGenericMessageModels(
          gCustomList.concat(customList || []).filter(ct => !!ct.storedfileId && !!ct.actionTypeId)
        );

        setLang(currentAppLn.shortName);

        const foundLangs = sortedMessages.readyModels
          .map(({ key }) => key.replace(/.*_(.*)/, '$1'))
          .filter(ln => ln.match(/[A-Z]{2}/));

        if (!foundLangs.length) foundLangs.push(currentAppLn.shortName);

        setLangs(foundLangs);
        setTemplates({
          templates: {
            generics: sortedMessages.genericModels, // list.map(model => ({ ...model, generic: true })),
            ready: sortedMessages.readyModels,
          },
          customTemplates: {
            generics: sortedCustoms.genericModels, // list.map(model => ({ ...model, generic: true })),
            ready: sortedCustoms.readyModels,
          },
        });
      };

      if (fetchData) fetchData();
    }
  });
  const [expanded, setExpanded] = useState(null);

  function filterKeyByLn(key) {
    const tplln = key.replace(/.*_(.*)/, '$1');
    return tplln === lang || langs.indexOf(tplln) === -1;
  }

  function filteredTemplates() {
    return {
      ...templates.templates,
      ready: templates.templates.ready.filter(({ key }) => filterKeyByLn(key)),
      generics: templates.templates.generics.filter(({ key }) => filterKeyByLn(key)),
    };
  }

  const handleSelectMessageModel = useCallback(
    async (messageModel, fieldsModels) => {
      switch (actionTypeId) {
        case ActionType.SMS.id:
          await doSelectSmsMessageModel(lang, messageModel, fieldsModels);
          break;
        case ActionType.EMAIL.id:
        case ActionType.EMAIL_COACH.id:
        case ActionType.EMAIL_SPONSORSHIP.id:
        case ActionType.EMAIL_INVITATION.id:
          await doSelectMailMessageModel(lang, messageModel, fieldsModels, actionTypeId);
          break;
        case ActionType.MEMBER_NOTIFICATION.id:
          await doSelectNotificationMessageModel(lang, messageModel, fieldsModels, actionTypeId);
          break;
        default:
          break;
      }
    },
    [
      lang,
      doSelectSmsMessageModel,
      actionTypeId,
      doSelectMailMessageModel,
      doSelectNotificationMessageModel,
    ]
  );

  const onCustomTplSelect = useCallback(
    async tpl => {
      loadFieldsAndContent(async processedFields => {
        await doSelectCustomTemplate(lang, tpl, processedFields.fieldsModels).finally(() =>
          setLoader(false)
        );
        goToCmpMsgStep(MESSAGE_EDITOR, { fields: processedFields, actionTypeId });
      });
    },
    [lang, loadFieldsAndContent, actionTypeId, doSelectCustomTemplate, goToCmpMsgStep, setLoader]
  );

  const onMessageModelSelect = useCallback(
    messageModel => {
      setLoader(true);
      loadFieldsAndContent(async processedFields => {
        // the load content fn to be passed as a callback
        await handleSelectMessageModel(messageModel, processedFields.fieldsModels).finally(() =>
          setLoader(false)
        );
        goToCmpMsgStep(MESSAGE_EDITOR, { fields: processedFields, actionTypeId });
      });
    },
    [setLoader, loadFieldsAndContent, goToCmpMsgStep, actionTypeId, handleSelectMessageModel]
  );

  const onUploadTemplate = useCallback(
    uploadedContent => {
      loadFieldsAndContent(processedFields => {
        switch (actionTypeId) {
          case ActionType.SMS.id:
            doSelectUploadedSmsModel(lang, uploadedContent, processedFields.fieldsModels);
            goToCmpMsgStep(MESSAGE_EDITOR, { fields: processedFields, actionTypeId });
            break;
          case ActionType.EMAIL.id:
          case ActionType.EMAIL_COACH.id:
          case ActionType.EMAIL_SPONSORSHIP.id:
          case ActionType.EMAIL_INVITATION.id:
            doSelectUploadedMailModel(uploadedContent, processedFields.fieldsModels, actionTypeId);
            goToCmpMsgStep(MESSAGE_EDITOR, { fields: processedFields, actionTypeId });
            break;
          case ActionType.MEMBER_NOTIFICATION.id:
            doSelectNotificationUploadedModel(
              lang,
              uploadedContent,
              processedFields.fieldsModels,
              actionTypeId
            );
            goToCmpMsgStep(MESSAGE_EDITOR, { fields: processedFields, actionTypeId });
            break;
          default:
            break;
        }
      });
    },
    [
      lang,
      loadFieldsAndContent,
      doSelectUploadedSmsModel,
      doSelectUploadedMailModel,
      goToCmpMsgStep,
      actionTypeId,
      doSelectNotificationUploadedModel,
    ]
  );

  return (
    (!!templates && (
      <MessageTemplateSelectorRender
        // templates={templates.templates}
        templates={filteredTemplates()}
        langs={langs}
        setLang={setLang}
        lang={lang}
        customTemplates={templates.customTemplates}
        onCustomTplSelect={onCustomTplSelect}
        onMessageModelSelect={onMessageModelSelect}
        goToCmpMsgStep={goToCmpMsgStep}
        expanded={expanded}
        setExpanded={setExpanded}
        onUploadTemplate={onUploadTemplate}
      />
    )) || (
      <Loader
        wrapperClass="ghostWrapperClass"
        text={utils.getLang('smartmessaging.campaigns.mainDataLoading')}
      />
    )
  );
}

const mapStateToProps = state => ({
  currentAppLn: state.app.currentLanguage,
});

const actionCreators = {
  doGetApiCaller: () => getApiCaller,
  doSelectCustomTemplate: selectCustomTemplate,
  doSelectSmsMessageModel: selectSmsMessageModel,
  doSelectMailMessageModel: selectMailMessageModel,
  doSelectUploadedMailModel: selectUploadedMailModel,
  doSelectUploadedSmsModel: selectUploadedSmsModel,
  doSelectNotificationMessageModel: selectNotificationMessageModel,
  doSelectNotificationUploadedModel: selectNotificationUploadedModel,
  setLoader: visible => loaderActions.setLoader(visible),
};

MessageTemplateSelectorResolver.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number,
  loadFieldsAndContent: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  doSelectMailMessageModel: PropTypes.func.isRequired,
  doSelectSmsMessageModel: PropTypes.func.isRequired,
  doSelectCustomTemplate: PropTypes.func.isRequired,
  doSelectUploadedMailModel: PropTypes.func.isRequired,
  doSelectUploadedSmsModel: PropTypes.func.isRequired,
  doSelectNotificationMessageModel: PropTypes.func.isRequired,
  doSelectNotificationUploadedModel: PropTypes.func.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};
MessageTemplateSelectorResolver.defaultProps = {
  rmId: null,
};
const MessageTemplateSelector = props => <MessageTemplateSelectorResolver {...props} />;

export default connect(mapStateToProps, actionCreators)(MessageTemplateSelector);

import React from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(theme => ({
  content: {
    cursor: 'pointer',
    flex: 1,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  title: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CardItem = ({ item, onClick }) => {
  const { classes } = useStyles();
  return (
    <Card
      onClick={event => {
        onClick(event, item);
      }}
      elevation={1}
      className={classes.card}
    >
      <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
        <Typography color="primary" variant="body2">
          {item.name}
        </Typography>
        <Typography className={classes.description} color="textSecondary" variant="caption">
          {item.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

CardItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CardItem;

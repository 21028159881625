import React from 'react';

import PropTypes from 'prop-types';
import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const ContentViewer = ({ onClose, content, open, object }) => {
  const writeHTML = frame => {
    if (!frame) {
      return;
    }
    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
  };

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      hideBackdrop
      onClose={onClose}
      PaperProps={{ style: { width: '50%', height: '60%' } }}
    >
      <DialogTitle style={{ padding: '16px' }}>
        <Typography
          style={{ fontSize: '1rem', color: theme.palette.text.secondary, textAlign: 'left' }}
        >
          {object}
        </Typography>
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: '4px',
            top: '4px',
          }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!!open && (
        <iframe
          title="content"
          src="about:blank"
          scrolling="yes"
          frameBorder="0"
          ref={writeHTML}
          style={{ height: '100%' }}
        />
      )}
    </Dialog>
  );
};

ContentViewer.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  object: PropTypes.string.isRequired,
};

export default ContentViewer;

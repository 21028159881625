import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { FormControl, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import utils from 'src/utils/utils';
import { getTime } from 'date-fns';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  textField: {
    flex: 1,
    fontSize: '0.975rem',
    marginLeft: `${theme.spacing(1)}!important`,
    marginRight: `${theme.spacing(1)}!important`,
  },
}));

const CampaignSchedulerItem = ({
  period,
  removePeriod,
  onPeriodChange,
  index,
  editedCampaign,
  requestModel,
}) => {
  const { classes } = useStyles();
  return (
    <FormControl className={classes.container}>
      <DatePicker
        closeOnSelect
        slotProps={{
          textField: {
            required: true,
          },
          actionBar: {
            actions: [],
          },
        }}
        disabled={!editedCampaign.isWritable}
        className={classes.textField}
        format="dd/MM/yyyy"
        label={utils.getLang('smartmessaging.campaignEditor.scheduler.beginDate')}
        value={period.startDate ? new Date(period.startDate) : null}
        onChange={date => {
          onPeriodChange('startDate', index, date ? getTime(date) : null);
        }}
      />

      <DatePicker
        closeOnSelect
        disabled={!editedCampaign.isWritable}
        className={classes.textField}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
        }}
        format="dd/MM/yyyy"
        label={utils.getLang('smartmessaging.campaignEditor.scheduler.endDate')}
        value={period.endDate ? new Date(period.endDate) : null}
        onChange={date => {
          if (!date || utils.isValidDate(date))
            onPeriodChange('endDate', index, date ? getTime(date) : date);
        }}
      />
      {!editedCampaign.eventDriven && ['hour'].indexOf(requestModel.periodicity) === -1 && (
        <TimePicker
          closeOnSelect
          disabled={!editedCampaign.isWritable}
          ampm={false}
          format="HH:mm"
          label={utils.getLang('smartmessaging.campaignEditor.scheduler.time')}
          slotProps={{
            textField: {
              required: true,
            },
            actionBar: {
              actions: [],
            },
          }}
          value={(period.time && utils.getNewDateWithTime(period.time)) || null}
          onChange={time => {
            onPeriodChange('time', index, time ? time.toLocaleTimeString().slice(0, 5) : time);
          }}
          className={classes.textField}
        />
      )}
      {editedCampaign.isWritable && (
        <IconButton
          onClick={e => {
            e.preventDefault();
            removePeriod(index);
          }}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </FormControl>
  );
};

const actionCreators = {
  removePeriod: index => ({ type: 'REMOVE_PERIOD', value: index }),
  onPeriodChange: (key, index, value) => ({
    type: 'ON_PERIOD_CHANGE',
    value: { key, index, newValue: value },
  }),
};

CampaignSchedulerItem.propTypes = {
  period: PropTypes.object.isRequired,
  removePeriod: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  editedCampaign: PropTypes.object.isRequired,
  requestModel: PropTypes.object.isRequired,
};

export default connect(null, actionCreators)(CampaignSchedulerItem);

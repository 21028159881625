import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiReactSelect from 'src/components/common/MuiReactSelect';
import utils from 'src/utils/utils';

const diffusionTypes = ['legal', 'advertising', 'news'];

const DiffusionTypeButton = ({
  selectedDiffusionType,
  setDiffusionType,
  actionType,
  stopSmsText,
}) => (
  <MuiReactSelect
    error={!selectedDiffusionType}
    onChange={value => {
      setDiffusionType(value, actionType, stopSmsText);
    }}
    isMulti={false}
    value={(selectedDiffusionType && [selectedDiffusionType]) || []}
    options={diffusionTypes.map(dt => ({
      label: utils.getLang(`smartmessaging.diffusionType.${dt}`),
      value: dt,
    }))}
  />
);

DiffusionTypeButton.propTypes = {
  selectedDiffusionType: PropTypes.string,
  setDiffusionType: PropTypes.func.isRequired,
  actionType: PropTypes.number,
  stopSmsText: PropTypes.string.isRequired,
};

DiffusionTypeButton.defaultProps = {
  selectedDiffusionType: null,
  actionType: null,
};

const mapStateToProps = state => ({
  selectedDiffusionType: state.massAction.selectedDiffusionType,
  stopSmsText: state.app.config.stopSmsText,
});

const actionCreators = {
  setDiffusionType: (diffusionType, actionType, stopSmsText) => ({
    type: 'SET_DIFFUSION_TYPE',
    value: { diffusionType, actionType, stopSmsText },
  }),
};

export default connect(mapStateToProps, actionCreators)(DiffusionTypeButton);

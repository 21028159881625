import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Card, CardContent, CardHeader, FormControl, FormLabel } from '@mui/material';

import utils from 'src/utils/utils';
import SelectedCriteriaList from './SelectedCriteriaList';

const useStyles = makeStyles()(theme => ({
  title: { fontSize: '1rem', color: theme.palette.text.secondary },
  cardHeader: { backgroundColor: theme.palette.grey[100] },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: '10px 15px' },
}));

const SelectedCriteriaGroupContainer = ({ groupName, isWritable }) => {
  const { classes } = useStyles();
  return (
    <Card style={{ overflow: 'visible' }} elevation={0}>
      <FormControl style={{ display: 'block' }}>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title,
            action: classes.action,
            root: classes.cardHeaderTitleRoot,
          }}
          title={
            <FormLabel style={{ fontSize: '0.875rem' }}>
              {utils.getLang(`smartmessaging.massAction.groupFilterBy.${groupName}`)}
            </FormLabel>
          }
        />
        <CardContent className={classes.cardContent}>
          <SelectedCriteriaList groupName={groupName} isWritable={isWritable} />
        </CardContent>
      </FormControl>
    </Card>
  );
};

SelectedCriteriaGroupContainer.propTypes = {
  groupName: PropTypes.string.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default SelectedCriteriaGroupContainer;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  FormLabel,
  FormControl,
  ListItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { connect } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import utils from 'src/utils/utils';

import Filter from './Filter';

const useStyles = makeStyles()(theme => ({
  label: theme.typography.body2,
  cardHeader: { backgroundColor: theme.palette.grey[200] },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
  root: { padding: '10px 16px' },
  menuItemName: {
    color: theme.palette.text.main,
    background: theme.palette.action.hover,
    ...theme.typography.body2,
  },
}));

const ActiveFilterCtnRender = ({
  fields,
  criteriaKey,
  showRecipeSingleParamMenu,
  singleParamMenuAnchor,
  removeActiveField,
  addActiveField,
  addGroupSubFieldLine,
  mandatory,
  invalidities,
  isWritable,
}) => {
  const { classes } = useStyles();
  return (
    <Card elevation={0} style={{ overflow: 'visible' }}>
      <CardHeader
        className={classes.cardHeader}
        classes={{
          title: classes.title,
          action: classes.action,
        }}
        title={
          <FormControl style={{ display: 'block' }} required={mandatory} color="primary">
            <FormLabel className={classes.label}>
              {utils.getLang(`smartmessaging.availableRecipeField.${criteriaKey}`)}
            </FormLabel>
          </FormControl>
        }
        action={
          isWritable &&
          ((fields.length && fields.length < fields[0].model.allowedOperators.length) ||
            !mandatory) && (
            <div>
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  showRecipeSingleParamMenu(criteriaKey, event.currentTarget);
                }}
                aria-owns={
                  (singleParamMenuAnchor.key === criteriaKey && `menu-${criteriaKey}`) || null
                }
                aria-haspopup="true"
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                MenuListProps={{ disablePadding: true }}
                id={`menu-${criteriaKey}`}
                anchorEl={
                  (singleParamMenuAnchor.key === criteriaKey && singleParamMenuAnchor.anchorEl) ||
                  null
                }
                open={Boolean(
                  singleParamMenuAnchor.key && singleParamMenuAnchor.key === criteriaKey
                )}
                onClose={event => {
                  event.preventDefault();
                  showRecipeSingleParamMenu(false, false);
                }}
                PaperProps={{
                  style: {
                    minWidth: 150,
                  },
                }}
              >
                <ListItem divider dense className={classes.menuItemName}>
                  {utils.getLang(`smartmessaging.availableRecipeField.${criteriaKey}`)}
                </ListItem>
                {fields.length && fields.length < fields[0].model.allowedOperators.length && (
                  <MenuItem
                    dense
                    onClick={() => {
                      if (!fields[0].parentKey) {
                        addActiveField(fields[0].model);
                      } else {
                        addGroupSubFieldLine({
                          model: fields[0].model,
                          parentKey: fields[0].parentKey,
                        });
                      }
                      showRecipeSingleParamMenu(false, false);
                    }}
                  >
                    {utils.getLang('smartmessaging.campaignEditor.recipeConfig.addLine')}
                  </MenuItem>
                )}
                {!mandatory && !fields[0].parentKey && (
                  <MenuItem
                    dense
                    onClick={event => {
                      event.preventDefault();
                      removeActiveField(fields[0].model.key);
                    }}
                  >
                    {utils.getLang('smartmessaging.campaignEditor.recipeConfig.removeCriteria')}
                  </MenuItem>
                )}
              </Menu>
            </div>
          )
        }
      />
      <CardContent className={classes.cardContent}>
        {fields.map(field => (
          <Filter
            key={`fieldKey-${fields.indexOf(field)}`}
            isWritable={isWritable}
            removableLine={Boolean(isWritable && fields.length > 1)}
            fieldConfig={field}
            invalidities={invalidities}
          />
        ))}
      </CardContent>
    </Card>
  );
};

ActiveFilterCtnRender.defaultProps = {
  singleParamMenuAnchor: { key: null, anchorEl: null },
};

ActiveFilterCtnRender.propTypes = {
  fields: PropTypes.array.isRequired,
  criteriaKey: PropTypes.string.isRequired,
  showRecipeSingleParamMenu: PropTypes.func.isRequired,
  singleParamMenuAnchor: PropTypes.object,
  removeActiveField: PropTypes.func.isRequired,
  addActiveField: PropTypes.func.isRequired,
  addGroupSubFieldLine: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  mandatory: PropTypes.bool.isRequired,
  invalidities: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    singleParamMenuAnchor: state.campaignEditorView.recipeSingleParamMenuAnchor,
  }),
  {
    showRecipeSingleParamMenu: (fieldKey, target) => ({
      type: 'SHOW_SINGLE_PARAM_MENU',
      value: { key: fieldKey, anchorEl: target },
    }),
    removeActiveField: key => ({ type: 'REMOVE_ACTIVE_FIELD', value: key }),
    addActiveField: fieldModel => ({ type: 'ADD_ACTIVE_FIELD', value: fieldModel }),
    addGroupSubFieldLine: fieldModel => ({ type: 'ADD_GROUP_FIELD_LINE', value: fieldModel }),
  }
)(ActiveFilterCtnRender);

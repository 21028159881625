import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Typography } from '@mui/material';
import utils from 'src/utils/utils';
import NotFound from '../common/NotFound';
import SmartjourneyList from './list/SmartjourneyList';
import GroupCreator from './creator/GroupCreator';
import SmartjourneyEditor from './editor/SmartjourneyEditor';
import withDataResolver from '../common/withDataResolver';
import smjSelectors from 'src/selectors/smartjourney';
import { initData, createSMJ, loadSMJs } from 'src/reducers/smartjourney';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';

function WESmartjourneyList({ hideBackBtn, showBackBtn, ...others }) {
  useEffect(() => {
    showBackBtn();
    return () => {
      hideBackBtn();
    };
  }, [hideBackBtn, showBackBtn]);
  return <SmartjourneyList {...others} {...{ hideBackBtn, showBackBtn }} />;
}

WESmartjourneyList.propTypes = {
  hideBackBtn: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
};

function WEGroupCreator({ hideBackBtn, showBackBtn, ...others }) {
  useEffect(() => {
    showBackBtn();
    return () => {
      hideBackBtn();
    };
  }, [hideBackBtn, showBackBtn]);
  return <GroupCreator {...others} {...{ hideBackBtn, showBackBtn }} />;
}

WEGroupCreator.propTypes = {
  hideBackBtn: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
};

function WESmartjourneyEditor({ hideBackBtn, showBackBtn, ...others }) {
  useEffect(() => {
    showBackBtn();
    return () => {
      hideBackBtn();
    };
  }, [hideBackBtn, showBackBtn]);
  return <SmartjourneyEditor {...others} {...{ hideBackBtn, showBackBtn }} />;
}

WESmartjourneyEditor.propTypes = {
  hideBackBtn: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
};

function Content({
  currentStep,
  goToStep,
  doClearData,
  showBackBtn,
  hideBackBtn,
  createCampaign,
  ...otherProps
}) {
  switch (currentStep) {
    case 0:
      return (
        <WESmartjourneyList
          {...otherProps}
          smjModelById={otherProps.smjModelList.reduce(
            (m, model) => ({ ...m, [model.id]: model.key }),
            {}
          )}
          {...{
            showBackBtn,
            hideBackBtn,
            goToSMJStep: goToStep,
          }}
        />
      );
    case 1:
      return (
        <WEGroupCreator
          {...otherProps}
          {...{
            showBackBtn,
            hideBackBtn,
            goToSMJStep: goToStep,
          }}
        />
      );
    case 2:
      return (
        <WESmartjourneyEditor
          {...otherProps}
          {...{
            showBackBtn,
            hideBackBtn,
            createCampaign,
            doClearData,
            goToSMJStep: goToStep,
          }}
        />
      );
    default:
      return <NotFound />;
  }
}

Content.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  doClearData: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
  hideBackBtn: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
};

const BackBtn = ({ onBack }) => (
  <Button
    variant="text"
    style={{
      textTransform: 'none',
    }}
    onClick={e => {
      e.preventDefault();
      onBack();
    }}
  >
    <Typography variant="body2" color="textSecondary">
      {utils.getLang('smartmessaging.buttonLabel.backToList')}
    </Typography>
  </Button>
);

BackBtn.propTypes = { onBack: PropTypes.func.isRequired };

function WithStep({
  doClearData,
  smjModelList,
  generateSMJ,
  doLoadSMJs,
  receiveRecipes,
  requestModelByRMTypeId,
  setBackFn,
  enableBackBtn,
  createCampaign,
  currentLanguage,
}) {
  const [currentStep, goToStep] = useState(0);
  const [extraProps, setExtraProps] = useState({});

  const enhancedGoToStep = useCallback((step, newExtraProps) => {
    setExtraProps(newExtraProps || {});
    goToStep(step);
  }, []);

  const backToList = useCallback(
    override => {
      if (override) override();
      else {
        doClearData();
        goToStep(0);
      }
    },
    [doClearData, goToStep]
  );

  const showBackBtn = useCallback(
    btlOverride => {
      setBackFn(() => () => backToList(btlOverride));
      enableBackBtn(true);
    },
    [setBackFn, enableBackBtn, backToList]
  );

  const hideBackBtn = useCallback(() => {
    setBackFn(() => () => {});
    enableBackBtn(false);
  }, [setBackFn, enableBackBtn]);

  return (
    <Content
      currentStep={currentStep}
      goToStep={enhancedGoToStep}
      backToList={backToList}
      smjModelList={smjModelList}
      generateSMJ={({ smj, cmpList, language }) => {
        generateSMJ({ smj, cmpList, language }, newGroup =>
          enhancedGoToStep(2, { smartjourney: newGroup })
        );
      }}
      doLoadSMJs={doLoadSMJs}
      receiveRecipes={receiveRecipes}
      requestModelByRMTypeId={requestModelByRMTypeId}
      showBackBtn={showBackBtn}
      hideBackBtn={hideBackBtn}
      createCampaign={createCampaign}
      doClearData={doClearData}
      currentLanguage={currentLanguage}
      {...extraProps}
    />
  );
}

WithStep.propTypes = {
  doClearData: PropTypes.func.isRequired,
  smjModelList: PropTypes.array.isRequired,
  generateSMJ: PropTypes.func.isRequired,
  doLoadSMJs: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  receiveRecipes: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  setBackFn: PropTypes.func.isRequired,
  enableBackBtn: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
};

const resolvers = {
  resolve: props => async () => {
    await props.doLoadData();
  },
  loader: true,
  onResolved: () => async () => {},
};

const mapStateToProps = state => ({
  smjModelList: smjSelectors.getSMJModelList(state.smartjourney),
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  currentLanguage: state.app.currentLanguage.shortName,
});

const actionCreators = {
  doLoadData: initData,
  generateSMJ: createSMJ,
  doLoadSMJs: loadSMJs,
  receiveRecipes: recipeList => ({ type: 'RECEIVE_RECIPES', value: recipeList }),
  createCampaign: groupId => ({ type: 'CREATE_CAMPAIGN', value: { groupId } }),
};

export default connect(mapStateToProps, actionCreators)(withDataResolver(resolvers)(WithStep));

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, IconButton, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers';
import { parse, format } from 'date-fns';

import utils from 'src/utils/utils';
import OperatorSelect from 'src/components/common/OperatorSelect';
import ValidityAdornment from './ValidityAdornment';

const DateMassActionField = ({
  classes,
  fieldConfig,
  onChange,
  removeLine,
  onOperatorChange,
  removable,
  validity,
  isWritable,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.massAction.filterLabel.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect
          fieldConfig={fieldConfig}
          onChange={onOperatorChange}
          disabled={!isWritable}
        />
      </div>
      <DatePicker
        closeOnSelect
        slotProps={{
          textField: { style: { flex: 1 } },
          actionBar: {
            actions: [],
          },
        }}
        disabled={!isWritable}
        style={{
          fontSize: '0.875rem',
          flex: '1',
        }}
        format="dd/MM/yyyy"
        value={
          (fieldConfig.value &&
            fieldConfig.value.values &&
            fieldConfig.value.values.length &&
            fieldConfig.value.values[0] &&
            parse(fieldConfig.value.values[0], 'yyyy/MM/dd', new Date())) ||
          null
        }
        onChange={date => {
          onChange(fieldConfig, format(date, 'yyyy/MM/dd'));
        }}
      />
      {isWritable && removable && (
        <InputAdornment style={{ height: 'unset' }} position="start">
          <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)} size="large">
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      )}
    </div>
  </FormControl>
);

DateMassActionField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default DateMassActionField;

import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CascadingMenuItem from './CascadingMenuItem';

const useStyles = makeStyles()(theme => ({
  filteritem: {
    color: theme.palette.text.secondary,
    marginTop: '8px',
    marginBottom: '8px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const renderMenuItems = (menuItems, classes) =>
  menuItems.map(menuItem => {
    if (menuItem.subMenuItems) {
      return (
        <CascadingMenuItem
          key={menuItem.label}
          caption={menuItem.label}
          menuItems={menuItem.subMenuItems}
        />
      );
    }
    return (
      <div key={menuItem.key}>
        <Typography variant="body2" className={classes.filteritem} onClick={menuItem.onClick}>
          {menuItem.label}
        </Typography>
      </div>
    );
  });

function CascadingMenu({ menuItems }) {
  const { classes } = useStyles();
  return (
    <Paper elevation={1} style={{ padding: '4px 16px' }}>
      {renderMenuItems(menuItems, classes)}
    </Paper>
  );
}
CascadingMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
};

export default CascadingMenu;

/* Example of menuItems:
[
    {
        'key': 'item1',
        'caption': 'Item 1',
        'onClick': (event) => function () {
        }
    },
    {
        'key': 'item2',
        'caption': 'Item 2',
        'onClick': (event) => function () {
        }
    },
    {
        'key': 'item3',
        'caption': 'Item 3',
        'subMenuItems': [
            {
                'key': 'item1',
                'caption': 'Item 1',
                'onClick': (event) => function () {
                }
            },
            {
                'key': 'item2',
                'caption': 'Item 2',
                'onClick': (event) => function () {
                }
            }
        ]
    }
];
 */

import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';

const LoaderContainer = ({ isLoading, children }) =>
  isLoading ? (
    <Loader wrapperClass="none" wrapperStyle={{ position: 'relative', height: '96px' }} />
  ) : (
    children
  );

LoaderContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

export default LoaderContainer;

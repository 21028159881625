import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, Typography, AppBar, Toolbar, IconButton, Tooltip } from '@mui/material';
import PreviousIcon from '@mui/icons-material/ChevronLeft';
import { saveParams } from 'src/reducers/parameters';
import utils from 'src/utils/utils';

const ParamListBtn = ({ onClick }) => {
  const theme = useTheme();
  return (
    <Tooltip title="Editer les paramètres pour un autre club">
      <IconButton
        aria-label="clear"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
        style={{ color: theme.palette.primary.contrastText }}
        size="large"
      >
        <PreviousIcon />
      </IconButton>
    </Tooltip>
  );
};

ParamListBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles()(theme => ({
  button: {
    margin: theme.spacing(0.5),
  },
}));

const ParametersAppBar = ({
  doSaveParams,
  isValid,
  clubName,
  showList,
  selectionMode,
  multiParams,
}) => {
  const { classes } = useStyles();
  return (
    <AppBar
      style={{
        position: 'relative',
      }}
      color="primary"
    >
      <Toolbar style={{ paddingLeft: multiParams && !selectionMode ? '8px' : '16px' }}>
        {!selectionMode && multiParams && <ParamListBtn onClick={showList} />}
        {!!selectionMode && (
          <Typography variant="h5" color="inherit">
            {utils.getLang('smartmessaging.config.appBarTitle.paramsList')}
          </Typography>
        )}

        {!selectionMode && (
          <Typography variant="h5" color="inherit">
            {(clubName === 'default' &&
              ((multiParams && utils.getLang('smartmessaging.config.appBarTitle.default')) ||
                utils.getLang('smartmessaging.config.appBarTitle.singleClub'))) ||
              utils.stringFormat(utils.getLang('smartmessaging.config.appBarTitle'), [clubName])}
          </Typography>
        )}

        {!selectionMode && (
          <Button
            style={{ marginLeft: 'auto' }}
            disabled={!isValid}
            variant="text"
            color="secondary"
            className={classes.button}
            onClick={e => {
              e.preventDefault();
              doSaveParams();
            }}
          >
            {utils.getLang('smartmessaging.config.save')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

ParametersAppBar.propTypes = {
  clubName: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  selectionMode: PropTypes.bool.isRequired,
  doSaveParams: PropTypes.func.isRequired,
  showList: PropTypes.func.isRequired,
  multiParams: PropTypes.bool.isRequired,
};

ParametersAppBar.defaultProps = {
  clubName: '',
};

const mapStateToProps = state => ({
  multiParams: Object.keys(state.parameters.clubParams).length > 1,
  clubName:
    (state.parameters.clubParams[state.parameters.currentParams] &&
      state.parameters.clubParams[state.parameters.currentParams].clubName) ||
    '',
  isValid: !Object.values(state.parameters.invalidities).length,
});

const actionCreators = {
  doSaveParams: saveParams,
};

export default connect(mapStateToProps, actionCreators)(ParametersAppBar);

import React from 'react';
import utils from 'src/utils/utils';
import SMJCampaignItem from './SMJCampaignItem';

const SMJAvailableCmpList = ({ campaigns, onSelect, selection, requestModelByRMTypeId }) =>
  Object.entries(campaigns).map(([rmId, list]) =>
    list.map(cmp => (
      <SMJCampaignItem
        checked={selection.indexOf(cmp.key) !== -1}
        title={utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${cmp.key}`)}
        description={utils.getLang(
          `smartmessaging.campaigns.grouped.campaignModel.description.${cmp.key}`
        )}
        type={utils.getLang(
          `smartmessaging.requestmodel.label.${requestModelByRMTypeId[rmId].name}`
        )}
        key={cmp.key}
        onSelect={checked => {
          onSelect(cmp.key, checked);
        }}
      />
    ))
  );

export default SMJAvailableCmpList;

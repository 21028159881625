import React from 'react';
import PropTypes from 'prop-types';
import CardItem from './CardItem';

const CardList = ({ items, onItemClick }) => (
  <div>
    {items.map(item => <CardItem key={item.id} item={item} onClick={e => onItemClick(e, item)} />)}
  </div>
);

CardList.propTypes = {
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default CardList;

import React, { useState, Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  menuItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '240px',
  },
  lng: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '12px 10px',
    transition: 'all 200ms ease-in-out 0s',
    display: 'inline-block',
    opacity: 0.8,
    '&:hover': { opacity: 1 },
  },
}));

function MessagesLangBtn({ langs, currentLang, selectLang }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();

  function handleClose() {
    setAnchorEl(null);
  }

  const handleClick = useCallback(e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  return (
    <Fragment>
      <div onClick={handleClick} className={classes.lng}>
        {currentLang}
      </div>
      <Menu
        MenuListProps={{ disablePadding: true }}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          handleClose();
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
      >
        {langs.map(l => (
          <MenuItem
            dense
            className={classes.menuItem}
            selected={l === currentLang}
            disabled={l === currentLang}
            key={l}
            onClick={() => {
              handleClose();
              selectLang(l);
            }}
          >
            {utils.getLang(`smartmessaging.language.${l}`)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

MessagesLangBtn.propTypes = {
  selectLang: PropTypes.func.isRequired,
  langs: PropTypes.array.isRequired,
  currentLang: PropTypes.string.isRequired,
};

export default MessagesLangBtn;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';
import { useTheme } from '@mui/material/styles';

const StopSmsMessage = ({ length, warningColor, stopSmsText }) => (
  <Fragment>
    {` (+ ${stopSmsText.length} ${utils.getLang(
      'smartmessaging.contentEditor.sms.stopSmsWarning.chars'
    )} '${stopSmsText}')`}
    <span style={{ color: warningColor, fontWeight: 500 }}>
      {` = ${length} ${utils.getLang('smartmessaging.contentEditor.sms.stopSmsWarning.chars')}`}
    </span>
  </Fragment>
);

StopSmsMessage.propTypes = {
  length: PropTypes.number.isRequired,
  warningColor: PropTypes.string.isRequired,
  stopSmsText: PropTypes.string.isRequired,
};

const SmsValidity = ({ contentValidity, selectedDiffusionType, stopSmsText }) => {
  const theme = useTheme();
  return (
    !!contentValidity &&
    !!contentValidity.gsm7BitValidation && (
      <Typography
        color="textSecondary"
        style={{ margin: '5px 10px', overflow: 'visible' }}
        variant="body1"
        noWrap
      >
        {`${utils.getLang('smartmessaging.contentEditor.sms.content.messageLength')} : `}
        {selectedDiffusionType === 'advertising'
          ? contentValidity.gsm7BitValidation.length - stopSmsText.length
          : contentValidity.gsm7BitValidation.length}

        {selectedDiffusionType === 'advertising' ? (
          <StopSmsMessage
            length={contentValidity.gsm7BitValidation.length}
            stopSmsText={stopSmsText}
            warningColor={theme.palette.error.main}
          />
        ) : (
          ''
        )}
        <br />
        {`${utils.getLang(
          'smartmessaging.contentEditor.sms.content.numberOfSms'
        )} : ${contentValidity.gsm7BitValidation.numOfPart || 1}`}
        <br />
        {`${utils.getLang(
          'smartmessaging.contentEditor.sms.content.invalidChars'
        )} : ${contentValidity.gsm7BitValidation.invalidChar &&
          contentValidity.gsm7BitValidation.invalidChar.length &&
          contentValidity.gsm7BitValidation.invalidChar}` || 0}
      </Typography>
    )
  );
};

SmsValidity.propTypes = {
  contentValidity: PropTypes.object.isRequired,
  selectedDiffusionType: PropTypes.string,
  stopSmsText: PropTypes.string.isRequired,
};

SmsValidity.defaultProps = {
  selectedDiffusionType: null,
};

const mapStateToProps = state => ({
  contentValidity: state.massAction.contentValidity,
  selectedDiffusionType: state.massAction.selectedDiffusionType,
  stopSmsText: state.app.config.stopSmsText,
});

export default connect(mapStateToProps, null)(SmsValidity);

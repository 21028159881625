import React from 'react';
import { IconButton, InputAdornment, TextField, FormControl, FormLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import IntegerFormat from 'src/components/common/IntegerFormat';
import OperatorSelect from 'src/components/common/OperatorSelect';
import ValidityAdornment from './ValidityAdornment';
import utils from 'src/utils/utils';

const IntegerRecipeFieldRender = ({
  fieldConfig,
  onChange,
  removeLine,
  removable,
  onOperatorChange,
  classes,
  validity,
  disabled,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment validity={validity} required={fieldConfig.model.mandatory} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.recipeFieldName.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect fieldConfig={fieldConfig} onChange={onOperatorChange} disabled={disabled} />
      </div>
      <TextField
        disabled={disabled}
        style={{ width: '100%' }}
        onChange={event => {
          onChange(fieldConfig, event.target.value);
        }}
        InputProps={{
          inputComponent: IntegerFormat,
          'aria-label': 'Description',
        }}
        value={
          (fieldConfig.value &&
            fieldConfig.value.values &&
            fieldConfig.value.values.length &&
            fieldConfig.value.values[0]) ||
          ''
        }
      />
      {removable && (
        <InputAdornment position="start">
          <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)} size="large">
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      )}
    </div>
  </FormControl>
);

IntegerRecipeFieldRender.propTypes = {
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  validity: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default IntegerRecipeFieldRender;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';

import MenuButton from './MenuButton';

import { MAINSTEPS } from './MainContent';

const useStyles = makeStyles()(theme => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    ...theme.typography.body1,
    fontSize: '0.9rem',
    outline: 'none',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'left',
    transition: 'background 0.5s ease, background 0.5s ease',
    padding: '12px 16px 12px 24px',
    '&.selected': {
      background: theme.palette.grey[300],
      color: theme.palette.text.primary,
      fontWeight: 450,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      color: theme.palette.text.primary,
      background: theme.palette.grey[200],
    },
    '&:hover.selected': {
      background: theme.palette.grey[300],
    },
  },
}));

const MenuSelect = ({
  selectMenuValue,
  onSelectChange,
  actionCoActivated,
  parametersActivated,
  automatedActivated,
  customTemplateActivated,
}) => {
  const { classes } = useStyles();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      {automatedActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={selectMenuValue === MAINSTEPS.AUTOCAMPAIGNS}
          title={utils.getLang('smartmessaging.mainMenu.campaigns')}
          onSelect={() => {
            onSelectChange(MAINSTEPS.AUTOCAMPAIGNS);
          }}
        />
      )}

      {actionCoActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={selectMenuValue === MAINSTEPS.PUNCTUAL}
          title={utils.getLang('smartmessaging.mainMenu.massAction')}
          onSelect={() => {
            onSelectChange(MAINSTEPS.PUNCTUAL);
          }}
        />
      )}
      {customTemplateActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={selectMenuValue === MAINSTEPS.CUSTOM_TEMPLATE}
          title={utils.getLang('smartmessaging.mainMenu.customTemplates')}
          onSelect={() => {
            onSelectChange(MAINSTEPS.CUSTOM_TEMPLATE);
          }}
        />
      )}
      {parametersActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={selectMenuValue === MAINSTEPS.PARAMS}
          title={utils.getLang('smartmessaging.mainMenu.config')}
          onSelect={() => {
            onSelectChange(MAINSTEPS.PARAMS);
          }}
        />
      )}
      <MenuButton
        className={classes.menuItem}
        selected={selectMenuValue === MAINSTEPS.GLOBAL_HISTORY}
        title={utils.getLang('smartmessaging.mainMenu.sentMessages')}
        onSelect={() => {
          onSelectChange(MAINSTEPS.GLOBAL_HISTORY);
        }}
      />
    </div>
  );
};

MenuSelect.propTypes = {
  selectMenuValue: PropTypes.number.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  actionCoActivated: PropTypes.bool.isRequired,
  parametersActivated: PropTypes.bool.isRequired,
  automatedActivated: PropTypes.bool.isRequired,
  customTemplateActivated: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onSelectChange: option => dispatch({ type: 'SWITCH_MAIN_CONTENT', value: option }),
});

const mapStateToProps = state => ({
  selectMenuValue: state.selectMenuValue,
  actionCoActivated: state.app.config['smartm-punctual:allow'],
  parametersActivated: state.app.config['smartm-param:allow'],
  automatedActivated:
    state.app.config['smartm-automation:allow'] || state.app.config['smartm-smartjourney:allow'],
  customTemplateActivated: state.app.config['smartm-template:allow'],
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSelect);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Fade } from '@mui/material';
import CampaignSchedulerItem from './CampaignSchedulerItem';
import utils from 'src/utils/utils';
import { activateEditedCampaign, getUnremovedPeriods } from 'src/reducers/campaignEditor';
import CustCard from '../campaignContent/ActionCard';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  activateButton: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.9,
      '@media (hover: none)': {
        opacity: 0.9,
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  disableBtn: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
      opacity: 0.9,
      '@media (hover: none)': {
        opacity: 0.9,
        backgroundColor: theme.palette.warning.light,
      },
    },
  },
}));

const CampaignScheduler = ({
  periods,
  addNewPeriod,
  campaignValidity,
  doActivateEditedCampaign,
  editedCampaign,
  unRemovePeriods,
  goToEditorStep,
  requestModelByRMTypeId,
  recipesById,
}) => {
  const { classes, cx } = useStyles();
  return (
    <Fade in timeout={500}>
      <div style={{ textAlign: 'center', margin: '24px 15% 8px' }}>
        <CustCard title={utils.getLang('smartmessaging.campaigns.schedule.title')}>
          <div style={{ paddingTop: '24px' }}>
            {unRemovePeriods.map(
              period =>
                !period.removed && (
                  <CampaignSchedulerItem
                    key={periods.indexOf(period)}
                    index={periods.indexOf(period)}
                    period={period}
                    editedCampaign={editedCampaign}
                    requestModel={
                      requestModelByRMTypeId[
                        recipesById[editedCampaign.recipeId].requestModelTypeId
                      ]
                    }
                  />
                )
            )}
            {editedCampaign.isWritable && (
              <Button variant="text" color="primary" onClick={addNewPeriod}>
                {utils.getLang('smartmessaging.campaignSchedule.addPeriod')}
              </Button>
            )}
            <div style={{ textAlign: 'center', marginTop: '24px' }}>
              <Button
                style={{ margin: '2px' }}
                variant="text"
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  goToEditorStep(2);
                }}
              >
                {utils.getLang('smartmessaging.buttonLabel.previous')}
              </Button>
              {editedCampaign.isWritable && (
                <Button
                  style={{ margin: '2px' }}
                  className={cx({
                    [classes.disableBtn]: !!editedCampaign.enabled,
                    [classes.activateButton]: !editedCampaign.enabled,
                  })}
                  variant="contained"
                  color="primary"
                  disabled={
                    !editedCampaign.enabled &&
                    (!unRemovePeriods || !unRemovePeriods.length || !campaignValidity.isValid)
                  }
                  onClick={() => {
                    doActivateEditedCampaign();
                  }}
                >
                  {!editedCampaign.enabled &&
                    utils.getLang('smartmessaging.button.activateCampaign')}
                  {editedCampaign.enabled && utils.getLang('smartmessaging.button.disableCampaign')}
                </Button>
              )}
            </div>
          </div>
        </CustCard>
      </div>
    </Fade>
  );
};

const mapStateToProps = state => ({
  periods: state.campaignEditor.schedule.editedCmpPeriods,
  unRemovePeriods: getUnremovedPeriods(state),
});

const actionCreators = {
  addNewPeriod: () => ({ type: 'ADD_NEW_PERIOD' }),
  doActivateEditedCampaign: activateEditedCampaign,
};

CampaignScheduler.propTypes = {
  periods: PropTypes.array,
  addNewPeriod: PropTypes.func.isRequired,
  campaignValidity: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object.isRequired,
  doActivateEditedCampaign: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  unRemovePeriods: PropTypes.array,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
};

CampaignScheduler.defaultProps = {
  periods: [],
  unRemovePeriods: [],
};

export default connect(mapStateToProps, actionCreators)(CampaignScheduler);

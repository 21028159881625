import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
}));

const getTooltips = messages => {
  let k = 0;
  return messages.map(tt => {
    k += 1;
    return <li key={k}>{tt}</li>;
  });
};

const ValidityAdornment = ({ validity }) => {
  const { classes } = useStyles();
  return (
    <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
      {validity.isValid && <DoneIcon color="primary" />}
      {!validity.isValid && (
        <Tooltip
          classes={classes}
          title={<ul>{getTooltips(validity.messages)}</ul>}
          placement="bottom"
        >
          <WarningIcon color="error" />
        </Tooltip>
      )}
    </InputAdornment>
  );
};

ValidityAdornment.propTypes = {
  validity: PropTypes.object,
};

ValidityAdornment.defaultProps = {
  validity: null,
};

export default ValidityAdornment;

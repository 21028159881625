/* eslint-disable no-unused-vars */
import { useLayoutEffect } from 'react';
import usePrevious from './usePrevious';

type DidUpdateFunction<T, S> = (prevProps: T, prevState?: S) => void;

const useDidUpdate = <T = any, S = any>(fn: DidUpdateFunction<T, S>, props: T, state?: S) => {
  const prevProps = usePrevious(props);
  const prevState = usePrevious(state);

  useLayoutEffect(() => {
    if (prevProps) {
      fn(prevProps, prevState);
    }
  });
};

export default useDidUpdate;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestLogin } from 'src/reducers/auth';
import LoginScreen from './LoginScreen';
import NotFound from '../common/NotFound';

class Logger extends Component {
  componentDidMount() {
    const { doRequestLogin, match, location, authFailed } = this.props;
    if (!authFailed) {
      doRequestLogin(match, location);
    }
  }

  render() {
    const { authFailed } = this.props;
    return (
      (!authFailed && (
        <div>
          <LoginScreen />
        </div>
      )) || <NotFound text="Echec de l'authentification" />
    );
  }
}

Logger.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  authFailed: PropTypes.bool.isRequired,
  doRequestLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  authFailed: state.auth.authFailed,
});

const actionCreators = {
  doRequestLogin: requestLogin,
};

export default connect(mapStateToProps, actionCreators)(Logger);

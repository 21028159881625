import { createReducer } from '@reduxjs/toolkit';
import { listInitialState, listActions } from './customTemplateList';
import { editorInitialState, editorActions } from './customTemplateEditor';

export { editTemplate, saveTemplate, loadEditorData, saveContent } from './customTemplateEditor';
export { loadCustomTemplates } from './customTemplateList';

export default createReducer(
  { ...listInitialState, ...editorInitialState },
  { ...listActions, ...editorActions }
);

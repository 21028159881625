import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const DecimalFormat = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    onChange={e => {
      // TODO ---> leading 0 issue--->format and remove all 0
      onChange(e);
    }}
    decimalScale={2}
  />
);

DecimalFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DecimalFormat;

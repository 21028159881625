import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Toolbar, Typography, AppBar } from '@mui/material';

import BackBtn from './HistoryBackBtn';

const useStyles = makeStyles()(() => ({
  appBar: {
    position: 'relative',
  },
}));

function HistoryAppBar({ title, onBack, campaignName, barButtons }) {
  const { classes } = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar style={{ paddingLeft: '8px' }}>
        {onBack && <BackBtn onClick={onBack} />}
        <div style={{ flex: 1 }}>
          <Typography variant="h5" color="inherit">
            {title}
          </Typography>
          <Typography variant="body2" color="inherit">
            {campaignName}
          </Typography>
        </div>
        {barButtons}
      </Toolbar>
    </AppBar>
  );
}

HistoryAppBar.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string,
  campaignName: PropTypes.string.isRequired,
  barButtons: PropTypes.any,
};

HistoryAppBar.defaultProps = {
  onBack: null,
  title: 'Historique',
  barButtons: null,
};

export default HistoryAppBar;

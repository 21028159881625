import utils from 'src/utils/utils';

const timeRegexp = /^\d{2}?:?\d{2}$/;
const isValidTime = t => timeRegexp.test(t);

const compareTime = (a, b) => {
  const aDate = new Date(`01/01/1970 ${a}`);
  const bDate = new Date(`01/01/1970 ${b}`);

  if (aDate.getTime() < bDate.getTime()) {
    return -1;
  }
  if (aDate.getTime() > bDate.getTime()) {
    return 1;
  }
  if (aDate.getTime() === bDate.getTime()) {
    return 0;
  }
  return null;
};

export default {
  timeParamKeys: ['openingTime', 'closingTime'],
  textParamKeys: [
    'smsSender',
    'emailSender',
    'facebookUrl',
    'googlePlusUrl',
    'instagramUrl',
    'twitterUrl',
    'youtubeUrl',
    'resamaniaUrl',
    'bookingUrl',
    'salelUrl',
    'unsubscribeUrl',
    'passwordforgottenUrl',
    'clubUrl',
    'appstoreUrl',
    'googleplayUrl',
    'contactCenterMail',
    'contactCenterPhone',
  ],
  fieldValidationTypesByKey: {
    smsSender: 'SMSSENDER',
    emailSender: 'NONE',
    facebookUrl: 'URL',
    googlePlusUrl: 'URL',
    instagramUrl: 'URL',
    twitterUrl: 'URL',
    youtubeUrl: 'URL',
    resamaniaUrl: 'URL',
    bookingUrl: 'URL',
    salelUrl: 'URL',
    unsubscribeUrl: 'URL',
    passwordforgottenUrl: 'URL',
    clubUrl: 'URL',
    appstoreUrl: 'URL',
    googleplayUrl: 'URL',
    openingTime: 'OPENINGTIME',
    closingTime: 'CLOSINGTIME',
    contactCenterMail: 'NONE',
    contactCenterPhone: 'NONE',
  },
  validationsByType: {
    MAIL(key) {
      return { key, isValid: true, messages: [] };
    },
    URL(key, value) {
      let isValid = true;
      const messages = [];
      const urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
      if (!!value && !urlRegex.test(value)) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.config.tooltip.invalidUrl'));
      }
      return { key, isValid, messages };
    },
    SMSSENDER(key, value) {
      const messages = [];
      let isValid = true;
      if (value) {
        isValid = value.length <= 11;
        if (!isValid)
          messages.push(utils.getLang('smartmessaging.config.tooltip.smsSenderFieldTooLong'));
        if (!/^[a-zA-Z0-9 ]*$/.test(value)) {
          isValid = isValid && false;
          messages.push(
            utils.getLang('smartmessaging.config.tooltip.smsSenderFieldForbiddenChars')
          );
        }
      }
      return { key, isValid, messages };
    },
    NONE(key) {
      return { key, isValid: true, messages: [] };
    },
    OPENINGTIME(key, value, all) {
      const messages = [];
      let isValid = true;

      if (!!value && !isValidTime(value)) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.config.tooltip.invalidTime'));
      }

      if (!value && !!all.closingTime) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.config.tooltip.openingTimeRequired'));
      }

      if (isValidTime(value) && isValidTime(all.closingTime)) {
        if (compareTime(value, all.closingTime) !== -1) {
          isValid = false;
          messages.push(utils.getLang('smartmessaging.config.tooltip.openingAfterClosing'));
        }
      }
      return { key, isValid, messages };
    },
    CLOSINGTIME(key, value, all) {
      const messages = [];
      let isValid = true;

      if (!!value && !isValidTime(value)) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.config.tooltip.invalidTime'));
      }

      if (!value && !!all.openingTime) {
        isValid = false;
        messages.push(utils.getLang('smartmessaging.config.tooltip.closingTimeRequired'));
      }
      if (isValidTime(value) && isValidTime(all.openingTime)) {
        if (compareTime(all.openingTime, value) !== -1) {
          isValid = false;
          messages.push(utils.getLang('smartmessaging.config.tooltip.openingAfterClosing'));
        }
      }
      return { key, isValid, messages };
    },
  },
};

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Button } from '@mui/material';

import withSteps from '../common/hocs/withSteps';

import CampaignContent from '../campaign/CampaignContent';
import SmartjourneyContent from '../smartjourney/SmartjourneyContent';
import utils from 'src/utils/utils';

export const AUTO_STEPS = { REGULAR: 0, SMARTJOURNEY: 1 };

const Tabs = ({ active, items }) => (
  <ul className="nav nav-tabs">
    {items.map(item => (
      <li
        key={item.key}
        className={active === item.key ? 'active' : ''}
        onClick={() => {
          item.action();
        }}
      >
        <a>{item.label}</a>
      </li>
    ))}
  </ul>
);
Tabs.propTypes = { active: PropTypes.number.isRequired, items: PropTypes.array.isRequired };

function Content({ currentStep, goToStep, ...otherProps }) {
  switch (currentStep) {
    case AUTO_STEPS.REGULAR:
      return <CampaignContent {...otherProps} />;
    case AUTO_STEPS.SMARTJOURNEY:
      return <SmartjourneyContent {...otherProps} />;
    default:
      return <CampaignContent {...otherProps} />;
  }
}

Content.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
};

const BackBtn = ({ onBack }) => (
  <Button
    variant="text"
    style={{
      textTransform: 'none',
    }}
    onClick={e => {
      e.preventDefault();
      onBack();
    }}
  >
    <Typography variant="body2" color="textSecondary">
      {utils.getLang('smartmessaging.buttonLabel.backToList')}
    </Typography>
  </Button>
);

BackBtn.propTypes = { onBack: PropTypes.func.isRequired };

function WithStep({
  doClearData,
  goToStep,
  currentStep,
  automatedActivated,
  smjActivated,
  ...otherProps
}) {
  const [backFn, setBackFn] = useState(() => () => {});
  const [enabledBackBtn, enableBackBtn] = useState(false);

  function getBackFn() {
    return backFn;
  }
  return (
    <Fragment>
      <div>
        <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
          <Typography
            variant="h5"
            color="textSecondary"
            style={{
              margin: 'auto 16px',
              flex: 1,
              textAlign: 'left',
            }}
          >
            {utils.getLang('smartmessaging.campaigns.mainTitle')}
          </Typography>
          {enabledBackBtn && (
            <BackBtn
              onBack={() => {
                getBackFn()();
              }}
            />
          )}
        </div>
        {automatedActivated && smjActivated && (
          <Tabs
            items={[
              {
                key: 0,
                label: utils.getLang('smartmessaging.campaigns.single.tabLabel'),
                action: () => {
                  if (currentStep !== AUTO_STEPS.REGULAR) goToStep(AUTO_STEPS.REGULAR);
                },
              },
              {
                key: 1,
                label: utils.getLang('smartmessaging.campaigns.grouped.tabLabel'),
                action: () => {
                  if (currentStep !== AUTO_STEPS.SMARTJOURNEY) goToStep(AUTO_STEPS.SMARTJOURNEY);
                },
              },
            ]}
            active={currentStep}
          />
        )}
      </div>
      <Divider />
      <Content
        currentStep={currentStep}
        goToStep={goToStep}
        doClearData={doClearData}
        enableBackBtn={enableBackBtn}
        setBackFn={setBackFn}
        {...otherProps}
      />
    </Fragment>
  );
}

WithStep.propTypes = {
  doClearData: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  automatedActivated: PropTypes.bool.isRequired,
  smjActivated: PropTypes.bool.isRequired,
};

export default withSteps(WithStep);

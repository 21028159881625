const getRmIdByRmTypeId = (state, rmTypeId) =>
  state.requestModelList.requestModelIdByRequestModelTypeId[rmTypeId];

const getRmTypeIdByRmId = (state, rmId) =>
  state.requestModelList.requestModelTypeIdByRequestModelId[rmId];

const getRmByRmTypeId = (state, rmTypeId) =>
  state.requestModelList.requestModelListById[
    state.requestModelList.requestModelIdByRequestModelTypeId[rmTypeId]
  ];

export default { getRmIdByRmTypeId, getRmTypeIdByRmId, getRmByRmTypeId };

import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell } from '@mui/material';
import hUtils from './utils';

const SummaryItem = ({ item, columns, specificRender, rowSpanCols, rowSpan }) => {
  const theme = useTheme();
  return (
    <TableRow>
      {columns.map(column => (
        <TableCell
          key={uuid()}
          style={{ padding: '4px', color: theme.palette.text.secondary }}
          rowSpan={rowSpanCols.indexOf(column) === -1 ? 1 : rowSpan}
        >
          {specificRender[column]
            ? specificRender[column](column, item)
            : hUtils.renderCell(column, item)}
        </TableCell>
      ))}
    </TableRow>
  );
};

SummaryItem.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array,
  specificRender: PropTypes.object,
  rowSpan: PropTypes.number.isRequired,
};
SummaryItem.defaultProps = {
  rowSpanCols: [],
  specificRender: {},
};

export default SummaryItem;

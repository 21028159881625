import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  cfg: false,
  stacks: 0,
};

const ActionTypes = { SET_LOADER: 'SET_LOADER', RESET_LOADERSTACK: 'RESET_LOADERSTACK' };

const setLoader = (state, { value }) => ({
  cfg: value,
  stacks: (value && state.stacks + 1) || (state.stacks && state.stacks - 1),
});

const resetLoaderStack = (state, { value }) => ({
  cfg: value,
  stacks: 0,
});

export const loaderActions = {
  setLoader(visible) {
    return { type: ActionTypes.SET_LOADER, value: visible };
  },
  resetLoaderStack() {
    return { type: ActionTypes.RESET_LOADERSTACK };
  },
};

export const loaderDispatcher = visible => dispatch => {
  // SET A RIEN
  dispatch(loaderActions.setLoader(visible));
};

export default createReducer(initialState, {
  [ActionTypes.SET_LOADER]: setLoader,
  [ActionTypes.RESET_LOADERSTACK]: resetLoaderStack,
});

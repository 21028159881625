import apiClient, { API_BASE_URL, doAuthentifiedReq } from '../apiClient';

const massActionCsvExport = async recipeId => {
  const response = await doAuthentifiedReq({
    method: 'post',
    url: `${API_BASE_URL}/smartmessaging/actionCoCsvExport/${recipeId}`,
  });
  return response.data;
};

const getMassActionRequestModel = async () => {
  const rmResponse = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/requestmodel/actionco`,
  });
  return rmResponse.data;
};

const getMassActions = async () => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/campaign/actionco/enhanced`,
  });
  return response.data;
};

const getAvailableResultfields = async () => {
  const rmResponse = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/listActionCoColumnList`,
  });
  return rmResponse.data;
};

const getMassActionExport = async recipeId => {
  const rmResponse = await doAuthentifiedReq({
    method: 'post',
    url: `${API_BASE_URL}/smartmessaging/jsonExport/${recipeId}`,
  });
  return rmResponse.data;
};

const saveMassActionSetup = async setup => {
  const savedSetup = await apiClient.insertOrUpdateSetup(setup);
  return savedSetup.id;
};

const saveMassActionRecipe = async recipe => {
  const savedRecipe = await apiClient.insertOrUpdateRecipe(recipe);
  return savedRecipe.id;
};

const saveMassAction = async massAction => {
  const savedCampaign = await apiClient.insertOrUpdateCampaign(massAction);
  return savedCampaign;
};

const getContentDefaultFields = async () => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/listActionCoHiddenColumnList`,
  });
  return response.data;
};

const getContentForbiddenFields = async () => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartmessaging/listActionCoForbiddenFields`,
  });
  return response.data;
};

const sendContent = async (campaignId, optinType, actionType, targets) => {
  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/smartmessaging/playCampaign/${campaignId}?optinType=${optinType}&actionType=${actionType}&targetWithOptin=${targets}`,
  });
  return response.data;
};

const actionCoTargetNumber = async (recipeId, optinType, actionTypeId) => {
  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/smartmessaging/actionCoTargetNumber/${recipeId}/${actionTypeId}?optinType=${optinType}`,
  });
  return response.data;
};

export default {
  getAvailableResultfields,
  getMassActions,
  getMassActionExport,
  getMassActionRequestModel,
  saveMassAction,
  saveMassActionRecipe,
  saveMassActionSetup,
  sendContent,
  actionCoTargetNumber,
  getContentDefaultFields,
  getContentForbiddenFields,
  massActionCsvExport,
};

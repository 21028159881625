import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { MenuItem, Typography } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'visible',
    height: 'auto',
    justifyContent: 'flex-end',
    width: 'fit-content',
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
}));

const AvailableField = ({ addCriteria, label }) => {
  const { classes } = useStyles();
  return (
    <MenuItem
      onClick={() => {
        addCriteria();
      }}
      classes={{
        root: classes.root,
      }}
    >
      <Typography
        variant="body2"
        color="inherit"
        align="right"
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        {label}
      </Typography>
    </MenuItem>
  );
};

AvailableField.propTypes = {
  addCriteria: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default AvailableField;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  hRoot: { padding: 0 },
  sticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    wordBreak: 'break-all',
  },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
}));

const CustomCard = ({ action, title, children, avatar, sticky }) => {
  const { classes } = useStyles();
  return (
    <Card elevation={0} style={{ flex: 1, textAlign: 'center' }}>
      <CardHeader
        className={classes.cardHeader}
        classes={{
          title: classes.title,
          action: classes.action,
          root: `${classes.hRoot} ${sticky ? classes.sticky : ''}`,
        }}
        action={action}
        avatar={avatar}
        title={title}
      />
      <CardContent style={{ overflow: 'auto', padding: '8px' }}>{children}</CardContent>
    </Card>
  );
};

CustomCard.propTypes = {
  action: PropTypes.any,
  avatar: PropTypes.any,
  children: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  sticky: PropTypes.bool,
};
CustomCard.defaultProps = {
  action: null,
  avatar: null,
  sticky: false,
};

export default CustomCard;

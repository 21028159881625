import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { InputAdornment, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';

const useStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
}));

const ValidityAdornment = ({ valid, message }) => {
  const { classes } = useStyles();
  return (
    <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
      {valid && <DoneIcon color="primary" />}
      {!valid && (
        <Tooltip classes={classes} title={message} placement="bottom">
          <WarningIcon color="error" />
        </Tooltip>
      )}
    </InputAdornment>
  );
};

ValidityAdornment.propTypes = {
  valid: PropTypes.bool.isRequired,
  message: PropTypes.any,
};

ValidityAdornment.defaultProps = {
  message: '',
};

export default ValidityAdornment;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Card } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    border: theme.border.primary,
  },
  title: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: theme.palette.text.secondary,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const HelperCardRender = ({ title, content }) => {
  const { classes } = useStyles();
  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
        {title && (
          <Typography className={classes.title} variant="body2" color="primary">
            {title}
          </Typography>
        )}
        {content && (
          <Typography className={classes.description} variant="caption" color="textPrimary">
            {content}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

HelperCardRender.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

const HelperCard = HelperCardRender;

export default HelperCard;

import React from 'react';

import { Button, Fade } from '@mui/material';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import FilterList from './FilterList';
import CampaignTypeItem from '../CampaignTypeItem';
import utils from 'src/utils/utils';
import { checkRecipeCfgValidity } from 'src/reducers/campaignEditor';

const CampaignTarget = ({
  editedCampaign,
  selectedType,
  requestModelByRMTypeId,
  recipesById,
  goToEditorStep,
  doSaveCampaign,
  recipeCfgValidity,
}) => (
  <Fade in timeout={500}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        padding: '16px 24px',
      }}
    >
      <CampaignTypeItem
        disabledSelect
        typeItem={
          editedCampaign.recipeId
            ? requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
            : selectedType
        }
      />
      <FilterList recipeCfgValidity={recipeCfgValidity} />
      <div style={{ textAlign: 'right', paddingTop: '12px' }}>
        <Button
          style={{ margin: '2px' }}
          variant="text"
          color="primary"
          onClick={e => {
            e.preventDefault();
            goToEditorStep(0);
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </Button>
        <Button
          style={{ margin: '2px' }}
          variant="contained"
          color="primary"
          onClick={e => {
            e.preventDefault();
            const saveAndNext = async () => {
              if (editedCampaign.isWritable) await doSaveCampaign(true);
              goToEditorStep(2);
            };
            saveAndNext();
          }}
          disabled={!recipeCfgValidity.isValid}
        >
          {utils.getLang('smartmessaging.buttonLabel.next')}
        </Button>
      </div>
    </div>
  </Fade>
);

const mapStateToProps = state => ({
  recipeCfgValidity: checkRecipeCfgValidity(state),
});

CampaignTarget.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  recipeCfgValidity: PropTypes.object.isRequired,
};

CampaignTarget.defaultProps = {
  selectedType: PropTypes.object,
};

export default connect(mapStateToProps, null)(CampaignTarget);

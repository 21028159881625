import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Fade, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import {
  getDistinctActiveFieldKeys,
  mapActiveFieldsByKey,
  loadEmptyRecipeConfig,
} from 'src/reducers/campaignEditor';
import withDataResolver from 'src/components/common/withDataResolver';

import AvailableFilter from './AvailableFilter';
import ActiveFilterCtn from './ActiveFilterCtn';

import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  label: theme.typography.body1,
  cardHeader: { backgroundColor: theme.palette.grey[200], margin: '2px 2px 0 2px' },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column' },
  cardAction: { marginTop: 0 },
  flex: { flex: 1 },
  cardRoot: { padding: '10px 16px' },
  gridBorder: { border: `1px solid ${theme.palette.grey[200]}` },
}));

const FilterListRender = ({
  inactiveFields,
  addActiveField,
  distinctActiveFieldKeysList,
  activeFieldsByKey,
  recipeCfgValidity,
  editedCampaign,
}) => {
  const { classes } = useStyles();
  return (
    <Fade in timeout={500}>
      <Grid
        container
        direction="row"
        className={classes.gridBorder}
        wrap="nowrap"
        style={{
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <Grid
          item
          container
          direction="column"
          style={{
            width: 'fit-content',
            maxWidth: '360px',
          }}
        >
          <div
            style={{
              overflow: 'auto',
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <Card
              elevation={0}
              style={{
                borderRadius: 0,
                textAlign: 'left',
                flex: 1,
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  action: classes.cardAction,
                  root: classes.cardRoot,
                  title: classes.label,
                }}
                title={utils.getLang('smartmessaging.massAction.availableFilters')}
              />
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingTop: 0,
                  flex: 1,
                  marginRight: '2px',
                  overflow: 'auto',
                }}
              >
                {inactiveFields.map(inactiveField => (
                  <AvailableFilter
                    key={inactiveField.key}
                    label={utils.getLang(
                      `smartmessaging.availableRecipeField.${inactiveField.key}`
                    )}
                    filter={inactiveField}
                    addCriteria={() => {
                      if (editedCampaign.isWritable) addActiveField(inactiveField);
                    }}
                  />
                ))}
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid
          className={classes.gridBorder}
          direction="column"
          container
          item
          style={{
            flex: 3,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              overflow: 'auto',
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <Card
              elevation={0}
              style={{
                minWidth: '600px',
                flex: 1,
                textAlign: 'left',
                borderRadius: 0,
                overflow: 'hidden',
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  action: classes.cardAction,
                  root: classes.cardRoot,
                  title: classes.label,
                }}
                title={utils.getLang('smartmessaging.massAction.selectedFilters')}
              />
              <CardContent
                style={{
                  overflow: 'auto',
                  flex: 1,
                  padding: '8px',
                }}
              >
                {distinctActiveFieldKeysList.map(activeKey => (
                  <ActiveFilterCtn
                    mandatory={activeFieldsByKey[activeKey][0].model.mandatory}
                    fields={activeFieldsByKey[activeKey]}
                    key={activeKey}
                    criteriaKey={activeKey}
                    invalidities={recipeCfgValidity.invalidFields}
                    isWritable={editedCampaign.isWritable}
                  />
                ))}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Fade>
  );
};

FilterListRender.propTypes = {
  inactiveFields: PropTypes.array.isRequired,
  addActiveField: PropTypes.func.isRequired,
  distinctActiveFieldKeysList: PropTypes.array.isRequired,
  activeFieldsByKey: PropTypes.object.isRequired,
  recipeCfgValidity: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  editedCampaign: state.campaignEditor.editedCampaign,
  selectedType: state.campaignEditor.selectedType,
  inactiveFields: state.campaignEditor.recipeFieldsConfig.inactiveFields,
  distinctActiveFieldKeysList: getDistinctActiveFieldKeys(state), // TODO: remove and use Object.keys(activeFieldsByKey)
  activeFieldsByKey: mapActiveFieldsByKey(state),
  anchorEl: state.campaignEditorView.recipeFieldMenuAnchorEl,
  loadedRecipe: state.campaignEditor.recipeFieldsConfig.loaded,
});
const actinCreators = {
  hideOrShowMenu: anchorEl => ({ type: 'HIDE_OR_SHOW_FIELDS_MENU', value: anchorEl }),
  addActiveField: fieldModel => ({ type: 'ADD_ACTIVE_FIELD', value: fieldModel }),
  doLoadRecipeConfig: loadEmptyRecipeConfig,
};

const resolvers = {
  resolve: props => async () => {
    const { editedCampaign, doLoadRecipeConfig, selectedType, loadedRecipe } = props;
    if (!loadedRecipe)
      await doLoadRecipeConfig(
        editedCampaign.requestModelTypeId || selectedType.requestModelTypeId
      );
  },
  onResolved: () => async () => {},
};

export default connect(
  mapStateToProps,
  actinCreators
)(
  withDataResolver(resolvers, {
    loaderWrapper: {
      class: 'ghostWrapperClass',
      style: { position: 'relative', height: '96px' },
    },
  })(FilterListRender)
);

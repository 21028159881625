import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormLabel,
  Typography,
  CardContent,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';

import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  optin: {
    marginTop: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typoBody2: theme.typography.body2,
}));

const CampaignTypeItem = ({ typeItem, selectCampaignType, disabledSelect }) => {
  const { classes, cx } = useStyles();
  return (
    <Card
      onClick={event => {
        event.preventDefault();
        if (!disabledSelect) selectCampaignType(typeItem);
      }}
      elevation={1}
      className={classes.card}
    >
      <CardContent
        className={cx(classes.content, !disabledSelect && 'selectable')}
        classes={{ root: classes.contentRoot }}
      >
        <Typography className={classes.title} color="primary" variant="body2">
          {utils.getLang(`smartmessaging.requestmodel.label.${typeItem.name}`)}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.description} color="textPrimary" variant="body2">
            {utils.getLang(`smartmessaging.requestmodel.description.${typeItem.name}`)}
          </Typography>
          <Typography className={classes.optin} color="textPrimary" variant="body2">
            {`${utils.getLang('smartmessaging.requestmodel.defaultOptin')} : ${utils.getLang(
              `smartmessaging.diffusionType.${typeItem.optinType}`
            )}`}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

CampaignTypeItem.propTypes = {
  typeItem: PropTypes.object.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  disabledSelect: PropTypes.bool.isRequired,
};

const useCategoryAccordioItemStyles = makeStyles()(theme => ({
  expandedSummary: { backgroundColor: theme.palette.background.default },
  summary: { color: theme.palette.text.secondary, ...theme.typography.body2 },
}));

const CategoryAccordionItem = ({
  categoryItem,
  expandedItem = '',
  handleExpandItem,
  selectCampaignType,
  selectable,
}) => {
  const { classes } = useCategoryAccordioItemStyles();
  return (
    <Accordion
      elevation={0}
      expanded={expandedItem === categoryItem.name}
      onChange={handleExpandItem(categoryItem.name)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.summary} />}
        classes={{ expanded: classes.expandedSummary }}
      >
        <Typography className={classes.summary}>
          {utils.getLang(`smartmessaging.campaignCategory.name.${categoryItem.name}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        <div>
          {categoryItem.requestModels.map(requestModel => (
            <CampaignTypeItem
              disabledSelect={!selectable}
              key={requestModel.id}
              typeItem={requestModel}
              selectCampaignType={selectCampaignType}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

CategoryAccordionItem.propTypes = {
  categoryItem: PropTypes.object.isRequired,
  expandedItem: PropTypes.string.isRequired,
  handleExpandItem: PropTypes.func.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
};

const CategoriesAccordion = ({
  sortedCategoryList,
  requestModelsByCategoryId,
  selectCampaignType,
  selectable,
}) => {
  const [expandedItem, setExpandedItem] = useState('');
  return (
    <div style={{ overflow: 'auto' }}>
      {sortedCategoryList.map(
        category =>
          !!requestModelsByCategoryId[category.id] && (
            <CategoryAccordionItem
              key={category.id}
              categoryItem={requestModelsByCategoryId[category.id]}
              handleExpandItem={categoryName => (event, expanded) => {
                if (expanded) {
                  setExpandedItem(categoryName);
                } else {
                  setExpandedItem('');
                }
              }}
              expandedItem={expandedItem}
              selectCampaignType={selectCampaignType}
              selectable={selectable}
            />
          )
      )}
    </div>
  );
};

CategoriesAccordion.propTypes = {
  sortedCategoryList: PropTypes.array,
  requestModelsByCategoryId: PropTypes.object,
  selectCampaignType: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
};

CategoriesAccordion.defaultProps = {
  sortedCategoryList: [],
  requestModelsByCategoryId: {},
};

const CampaignTypeRender = ({
  editedCampaign,
  requestModelByRMTypeId,
  recipesById,
  selectedType,
  requestModelsByCategoryId,
  sortedCategoryList,
  selectCampaignType,
}) => (
  <Fragment>
    <FormLabel required style={{ fontSize: '0.975rem', fontWeight: 400 }}>
      {utils.getLang('smartmessaging.campaignInfos.label.campaignType')}
    </FormLabel>
    <div style={{ margin: '8px', overflow: 'auto' }}>
      {(editedCampaign.recipeId || selectedType) && (
        <CampaignTypeItem
          disabledSelect
          selectCampaignType={selectCampaignType}
          typeItem={
            editedCampaign.recipeId
              ? requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
              : selectedType
          }
        />
      )}

      {!editedCampaign.recipeId && !selectedType && (
        <CategoriesAccordion
          {...{
            selectable: editedCampaign.isWritable,
            requestModelsByCategoryId,
            sortedCategoryList,
            selectCampaignType,
          }}
        />
      )}
    </div>
  </Fragment>
);

CampaignTypeRender.propTypes = {
  editedCampaign: PropTypes.object,
  recipesById: PropTypes.object,
  selectedType: PropTypes.object,
  requestModelsByCategoryId: PropTypes.object.isRequired,
  sortedCategoryList: PropTypes.array.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

CampaignTypeRender.defaultProps = {
  recipesById: {},
  editedCampaign: null,
  selectedType: null,
};

export default CampaignTypeRender;

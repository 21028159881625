import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: { height: '100vh', position: 'relative' },
  '@keyframes beat': {
    '33%': {
      transform: 'scale(0.5)',
      opacity: 0.2,
    },
    '66%': {
      transform: 'scale(0.75)',
      opacity: 0.5,
    },
    '100%': { transform: ' scale(1)', opacity: 1 },
  },
  dot: {
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    width: `25px`,
    height: `25px`,
    margin: `15px 10px`,
    borderRadius: '100%',
    animation: `beat 0.7s 0s infinite linear`,
    animationFillMode: 'both',
  },
  timer1: {
    animation: `beat 1.4s 0s infinite linear`,
  },
  timer2: {
    animation: `beat 1.4s 0.42s infinite linear`,
  },
  timer3: {
    animation: `beat 1.4s 0.84s infinite linear`,
  },
}));

const LoginScreen = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div
        style={{
          margin: 0,
          position: 'absolute',
          top: '45%',
          left: '50%',
          msTransform: 'translate(-50%, -50%)',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            style={{
              position: 'relative',
              top: '76px',
              textAlign: 'center',
            }}
          >
            <i>Connexion en cours</i>
          </Typography>
          <div className={`${classes.dot} ${classes.timer1}`} />
          <div className={`${classes.dot} ${classes.timer2}`} />
          <div className={`${classes.dot} ${classes.timer3}`} />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;

import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';

import LabelIcon from '@mui/icons-material/Label';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  input: { fontSize: '1rem', paddingLeft: '8px' },
  invalid: { background: theme.palette.warning.light },
}));

function NameInput({ value, ...props }) {
  const { classes } = useStyles();
  return (
    <TextField
      {...props}
      value={value}
      InputProps={{
        classes: { input: `${classes.input} ${!value && classes.invalid}` },
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <LabelIcon style={{ fill: '#b6b6b6' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}

NameInput.propTypes = { value: PropTypes.string };
NameInput.defaultProps = { value: '' };

export default NameInput;

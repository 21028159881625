import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import EmailObjectLabel from './EmailObjectLabel';
import EmailContentLabel from './EmailContentLabel';

import CtnLang from './CtnLang';
import CKEditor, { defaultCkeHtml } from 'src/components/common/ReactCKEditor';
import utils from 'src/utils/utils';

const MassActionMailEditorRender = ({
  fields,
  content,
  object,
  nodeRef,
  rendered,
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  isWritable,
}) => (
  <div
    style={{
      height: '70%',
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflow: 'visible',
    }}
    ref={nodeRef}
  >
    {rendered && (
      <div>
        <div style={{ width: '100%', display: 'flex' }}>
          <EmailObjectLabel title={utils.getLang('smartmessaging.contentEditor.mail.object')} />
          <div style={{ marginRight: 0, marginLeft: 'auto' }}>
            <CtnLang />
          </div>
        </div>
        <CKEditor
          name="objectEditor"
          config={{
            readOnly: !isWritable,
            fields: fields.fields.objectFields,
            removeButtons:
              'Underline,Subscript,Superscript,' +
              'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
              'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
              'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
              'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
              'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
              'BidiLtr,BidiRtl,CreatePlaceholder,' +
              'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
            extraPlugins: 'strinsert',
            removePlugins: 'colorbutton,elementspath',
            autoParagraph: false,
            enterMode: 2,
            height: 10 * (nodeRef.current.clientHeight / 100),
          }}
          events={{
            loaded: event => {
              onObjectEditorLoaded(event.editor);
            },
            change: onChange,
          }}
          content={object}
        />
      </div>
    )}
    {rendered && (
      <div>
        <EmailContentLabel title={utils.getLang('smartmessaging.contentEditor.mail.content')} />
        <CKEditor
          name="htmlEditor"
          config={{
            readOnly: !isWritable,
            fields: fields.fields.contentFields,
            fullPage: true,
            allowedContent: true,
            removeButtons:
              'Underline,Subscript,Superscript,Source,Anchor,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
            disallowedContent: 'img{border*,margin*,float*,width,height}; table{width,height}',
            extraAllowedContent: 'img[width,height]; table[width,height]',
            extraPlugins: 'strinsert,uploadimage',
            imageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
            filebrowserImageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
            removePlugins: 'elementspath',
            autoParagraph: false,
            enterMode: 2,
            height: 70 * (nodeRef.current.clientHeight / 100),
          }}
          events={{
            loaded: event => {
              onEditorLoaded(event.editor);
            },
            change: onChange,
            instanceReady: event => {
              if (content) event.editor.setData(content);
              else event.editor.setData(defaultCkeHtml);
            },
            fileUploadRequest: event => {
              const { xhr, file, fileName } = event.data.fileLoader;
              const smStore = JSON.parse(sessionStorage.getItem('sm-store'));
              xhr.setRequestHeader('Authorization', `Bearer ${smStore.security.access_token}`);
              xhr.setRequestHeader(
                'X-User-Club-Id',
                `/${smStore.client}/clubs/${sessionStorage.getItem('club')}`
              );

              const networknode = sessionStorage.getItem('networknode');
              if (networknode)
                xhr.setRequestHeader(
                  'X-User-Network-Node-Id',
                  `/${smStore.client}/network_nodes/${networknode}`
                );
              const formData = new FormData();
              formData.append('file', file, fileName);
              xhr.send(formData);
              event.stop();
            },
            fileUploadResponse: event => {
              event.stop();
              const { data } = event;
              const { responseText } = event.data.fileLoader.xhr;
              data.url = `${process.env.REACT_APP_API_BASE_URL}/media/${responseText}`;
            },
          }}
        />
      </div>
    )}
  </div>
);

MassActionMailEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  nodeRef: PropTypes.object.isRequired,
  rendered: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  onEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

MassActionMailEditorRender.defaultProps = {
  object: '',
};

/** Used to render ckeditors after containers are rendered for ckeditors height
 *  cause it's value is not state bound (to avoid rerenders causing ckeditors layout issues)
 */
class MassActionMailEditorWithRef extends Component {
  constructor(props) {
    super(props);
    this.myNodeRef = React.createRef();
  }

  componentDidMount() {
    CKEditor.onCKEditorDialogDefintion();
    this.props.setRendered(true);
  }

  render() {
    return <MassActionMailEditorRender {...this.props} nodeRef={this.myNodeRef} />;
  }
}

MassActionMailEditorWithRef.propTypes = {
  setRendered: PropTypes.func.isRequired,
  rendered: PropTypes.bool.isRequired,
  fields: PropTypes.object,
};

MassActionMailEditorWithRef.defaultProps = {
  fields: { fields: {} },
};

export default withState('rendered', 'setRendered', () => false)(MassActionMailEditorWithRef);

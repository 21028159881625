const CampaignCategory = {
  FIDELISATION: { id: 1, name: 'FIDELISATION' },
  RELANCE: { id: 2, name: 'RELANCE' },
  COMMERCIAL: { id: 3, name: 'COMMERCIAL' },
  ACTION: { id: 4, name: 'ACTION' },
  PLANNING: { id: 5, name: 'PLANNING' },
  VENTE: { id: 6, name: 'VENTE' },
  PASSAGES: { id: 7, name: 'PASSAGES' },
  RESERVATION: { id: 8, name: 'RESERVATION' },
  AUTRE: { id: 99, name: 'AUTRE' },
};

export default CampaignCategory;
export const getCampaignCategoryById = id =>
  Object.values(CampaignCategory).filter(campaignCategory => campaignCategory.id === id)[0];
export const getSortedCategoryList = () =>
  Object.values(CampaignCategory).sort((cat1, cat2) => {
    if (cat2.id === 99 || cat1.name < cat2.name) {
      return -1;
    } else if (cat1.id === 99 || cat1.name > cat2.name) {
      return 1;
    }
    return 0;
  });

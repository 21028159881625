import { API_BASE_URL, doAuthentifiedReq } from '../apiClient';

const saveTemplate = async template => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/template`,
    data: { ...template },
  });

  return response.data;
};

const getTemplate = async id => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/template/${id}`,
  });

  return response.data;
};

const getTemplateByRequestModelType = async (rmTypeId, actionTypeId) => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/template/all/filtered?requestModelTypeId=${rmTypeId}&actionTypeId=${actionTypeId}`,
  });

  return response.data;
};

const getCustomTemplates = async mode => {
  let params = '';
  if (mode && mode.length) {
    params = '?';
    mode.forEach(m => {
      if (m) params += `mode=${m}&`;
    });
    params = params.slice(0, -1);
  }
  const rez = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/template/all${params}`,
  });

  return rez.data;
};

const getGenericTemplates = async actionTypeId => {
  const rez = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/template/all`,
  });
  return rez.data.filter(tpl => !!tpl.generic && tpl.actionTypeId === actionTypeId);
};

const isWritable = async tplId => {
  const rez = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/template/canEditTemplate/${tplId}`,
  });
  return rez.data;
};

export default {
  getCustomTemplates,
  getTemplateByRequestModelType,
  getGenericTemplates,
  getTemplate,
  saveTemplate,
  isWritable,
};

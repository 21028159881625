import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from 'tss-react/mui';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '50%',
    maxWidth: '800px',
  },
  textField: {
    flex: 1,
    fontSize: '0.975rem',
    marginLeft: `${theme.spacing(1)}!important`,
    marginRight: `${theme.spacing(1)}!important`,
  },
  clearIcon: { fill: theme.palette.text.secondary },
}));

const DateFilterInputs = ({
  addStartDate,
  addEndDate,
  selectedFilters,
  removeStartDate,
  removeEndDate,
}) => {
  const { classes } = useStyles();
  function getStartDate() {
    const rez =
      selectedFilters && selectedFilters.length
        ? selectedFilters.find(e => e.key === 'startDate') || null
        : null;
    return rez ? rez.value : rez;
  }
  function getEndDate() {
    const rez =
      selectedFilters && selectedFilters.length
        ? selectedFilters.find(e => e.key === 'endDate') || null
        : null;
    return rez ? rez.value : rez;
  }
  return (
    <FormControl className={classes.container}>
      <DatePicker
        closeOnSelect
        format="dd/MM/yyyy"
        label={utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.from')}
        value={getStartDate()}
        onChange={date => {
          if (date) addStartDate(date);
          else removeStartDate();
        }}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
          textField: {
            className: classes.textField,
          },
        }}
      />

      <DatePicker
        closeOnSelect
        format="dd/MM/yyyy"
        label={utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.to')}
        value={getEndDate()}
        onChange={date => {
          if (date) addEndDate(date);
          else removeEndDate();
        }}
        slotProps={{
          actionBar: {
            actions: ['clear'],
          },
          textField: {
            className: classes.textField,
          },
        }}
      />
    </FormControl>
  );
};

DateFilterInputs.propTypes = {
  addStartDate: PropTypes.func.isRequired,
  addEndDate: PropTypes.func.isRequired,
  removeStartDate: PropTypes.func.isRequired,
  removeEndDate: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

export default DateFilterInputs;

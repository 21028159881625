import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography, Paper, Chip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/material/styles';

const Item = props => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      tabIndex={-1}
      label={props.children}
      style={{
        margin: `2px 1px`,
      }}
      onDelete={props.onClick}
      deleteIcon={<ClearIcon {...props} style={{ color: theme.palette.primary.contrastText }} />}
    />
  );
};

Item.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};

const FilterPanel = ({ selectedFilters, removeFilter, open }) => (
  <Collapse in={open}>
    <Paper style={{ paddingTop: '8px' }} elevation={0}>
      {selectedFilters.map(s => (
        <Item key={s.id} onClick={() => removeFilter(s, true)}>
          <Typography>
            {s.renderLabel ? s.renderLabel(s.key) : s.key} :
            {s.renderValue ? s.renderValue(s.value) : s.value}
          </Typography>
        </Item>
      ))}
    </Paper>
  </Collapse>
);

FilterPanel.propTypes = {
  selectedFilters: PropTypes.array.isRequired,
  removeFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

FilterPanel.defaultProps = {
  removeFilter: () => {},
};

export default FilterPanel;

const Cats = { CUSTOMER: 1, COACH: 2, SPONSOR: 3, INVITATION: 4 };

export const ActionTypeCatById = Object.entries(Cats).reduce(
  (map, [k, v]) => ({ ...map, [v]: k }),
  {}
);

const ActionType = {
  SMS: { id: 1, name: 'SMS', cat: Cats.CUSTOMER },
  EMAIL: { id: 2, name: 'Email', cat: Cats.CUSTOMER },
  PUSH_ALERT_MOBILE: { id: 3, name: 'Alerte mobile', cat: Cats.CUSTOMER },
  INTERNAL_ALERT: { id: 4, name: 'Alerte interne', cat: Cats.CUSTOMER },
  EMAIL_COACH: { id: 5, name: 'Email Coach', cat: Cats.COACH },
  MEMBER_NOTIFICATION: { id: 6, name: 'Notification membre', cat: Cats.CUSTOMER },
  EMAIL_SPONSORSHIP: { id: 7, name: 'Email Filleul', cat: Cats.SPONSOR },
  EMAIL_INVITATION: { id: 8, name: 'Email Invité', cat: Cats.INVITATION },
};

export const ActionTypeById = Object.entries(ActionType).reduce(
  (map, [k, v]) => ({ ...map, [v.id]: k }),
  {}
);

export const ActionTypeByKey = Object.entries(ActionType).reduce(
  (map, [k, v]) => ({ ...map, [k]: v.id }),
  {}
);

export const groupActionTypesByCat = types => {
  const grouped = types.reduce((map, current) => {
    const nMap = { ...map };
    const { cat } = current;
    if (!nMap[cat]) nMap[cat] = [];
    nMap[cat].push(current);
    return nMap;
  }, {});
  return Object.entries(grouped)
    .sort(([ak], [bk]) => ak - bk)
    .map(([, v]) => v);
};

export default ActionType;
export const getActionTypeById = id =>
  Object.values(ActionType).filter(actionType => actionType.id === id)[0];

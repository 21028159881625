import React from 'react';
import PropTypes from 'prop-types';

const LiTabs = ({ value, tabs, onChange }) => (
  <ul className="nav nav-tabs" style={{ marginLeft: '8px' }}>
    {// list of tabs
    tabs.map(item => (
      <li
        key={item.value}
        className={value === item.value ? 'active' : ''}
        onClick={() => {
          onChange(item.value);
        }}
      >
        <a>{item.label}</a>
      </li>
    ))}
  </ul>
);

LiTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LiTabs;

import { API_BASE_URL, doAuthentifiedReq } from '../apiClient';

const STATS_URL = `${API_BASE_URL}/stats`;

const exportHistory = async campaignId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/export/historyList/${campaignId}`,
  });
  return response.data;
};

const exportExecutionResult = async (executionId, atId) => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/export/targetInformationList/${executionId}?actionTypeId=${atId}`,
  });
  return response.data;
};

const getHistoryByCmp = async cmpId => {
  const rez = await doAuthentifiedReq({
    method: 'get',
    url: `${STATS_URL}/globalStatsByCampaign/v2/${cmpId}`,
  });

  return rez.data;
};

export default {
  getHistoryByCmp,
  exportExecutionResult,
  exportHistory,
};

import React from 'react';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';

import ContentLangButton from 'src/components/common/ContentLangBtn';

const mapStateToProps = state => ({
  availableLanguages: state.app.availableLanguages,
  currentLang: state.massAction.currentCampaignAction
    ? state.massAction.currentCampaignAction.language
    : null,
});
const Connected = connect(mapStateToProps, {
  onContentLangChange: value => ({ type: 'ON_MASSACTION_CONTENT_LANG_CHANGE', value }),
})(props => (
  <ContentLangButton {...props} title={utils.getLang('smartmessaging.tooltip.contentLangButton')} />
));

export default Connected;

import React from 'react';
import DataResolver from './DataResolver';

const withDataResolver = (resolvers, styles) => Component => props => (
  <DataResolver
    resolve={resolvers.resolve(props)}
    onResolved={resolvers.onResolved(props)}
    loaderWrapperClass={styles && styles.loaderWrapper && styles.loaderWrapper.class}
    loaderWrapperStyle={styles && styles.loaderWrapper && styles.loaderWrapper.style}
    loader={resolvers.loader}
    loaderText={resolvers.loaderText || ''}
    getLoaderText={resolvers.getLoaderText || null}
  >
    {newProps => <Component {...{ ...props, ...newProps }} />}
  </DataResolver>
);

export default withDataResolver;

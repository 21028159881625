import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import utils from 'src/utils/utils';
import CustCard from './ActionCard';
import { MESSAGE_MODEL } from './CampaignMessage';

const useStyles = makeStyles()(theme => ({
  addBtn: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.contrastText,
  },
}));

const ActionCreator = ({
  loadFieldsAndContent,
  rmTypeId,
  rmId,
  actionTypeId,
  goToCmpMsgStep,
  lang,
}) => {
  const { classes } = useStyles();
  return (
    <CustCard title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
        <Typography
          color="primary"
          style={{
            wordWrap: 'normal',
            padding: '8px',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>

        <IconButton
          onClick={e => {
            e.preventDefault();
            goToCmpMsgStep(MESSAGE_MODEL, { rmTypeId, rmId, actionTypeId, loadFieldsAndContent });
          }}
          className={classes.addBtn}
          size="medium"
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </CustCard>
  );
};

ActionCreator.propTypes = {
  loadFieldsAndContent: PropTypes.func,
  actionTypeId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

ActionCreator.defaultProps = {
  loadFieldsAndContent: () => {},
};

export default ActionCreator;

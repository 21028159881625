import React from 'react';
import { IconButton, InputAdornment, FormControl, FormLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

import OperatorSelect from 'src/components/common/OperatorSelect';
import utils from 'src/utils/utils';
import ValidityAdornment from './ValidityAdornment';

const NoInputMassActionField = ({
  classes,
  fieldConfig,
  removeLine,
  onOperatorChange,
  removable,
  validity,
  isWritable,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.massAction.filterLabel.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect
          fieldConfig={fieldConfig}
          onChange={onOperatorChange}
          disabled={!isWritable}
        />
      </div>
      <div
        style={{
          flex: 1,
          opacity: 0.2,
          margin: '4px',
          backgroundImage:
            'repeating-linear-gradient(135deg, #ccc, #ccc 30px, #dbdbdb 30px, #dbdbdb 60px)',
        }}
      />
      {isWritable && removable && (
        <InputAdornment style={{ height: 'unset' }} position="start">
          <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)} size="large">
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      )}
    </div>
  </FormControl>
);

NoInputMassActionField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  removeLine: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default NoInputMassActionField;

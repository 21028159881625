import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import PropTypes from 'prop-types';
import isValid from './validate';
import utils from 'src/utils/utils';

const getTooltips = validity => {
  let tooltips = [];
  let ttIdx = 0;
  if (!validity.isValid) {
    if (validity.messages && validity.messages.length)
      tooltips = validity.messages.map(msg => {
        ttIdx += 1;
        return { key: ttIdx, value: msg };
      });
    else
      tooltips = [
        {
          key: 0,
          value: utils.getLang('smartmessaging.config.tooltip.invalidField'),
        },
      ];
  }
  return tooltips;
};

const useStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
}));

const ValidityAdornment = ({ validity }) => {
  const { classes } = useStyles();
  return (
    <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
      {validity.isValid && <DoneIcon color="primary" />}
      {!validity.isValid && (
        <Tooltip
          classes={classes}
          title={
            <ul>
              {getTooltips(validity).map(tt => (
                <li key={tt.key}>{tt.value}</li>
              ))}
            </ul>
          }
          placement="bottom"
        >
          <WarningIcon color="error" />
        </Tooltip>
      )}
    </InputAdornment>
  );
};

ValidityAdornment.propTypes = {
  validity: PropTypes.object.isRequired,
};

const inputUseStyles = makeStyles()(theme => ({
  label: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&.invalidField': { color: theme.palette.error.main },
  },
}));

const TimeInput = ({ initTime, disabled, onTimeChange, placeholder, name, label, validity }) => {
  const [time, setTime] = useState(initTime || '');
  const [lastVal, setLastVal] = useState(initTime || '');

  const onChangeHandler = val => {
    if (!(val === time) && !(val.length > 5)) {
      let validVal = val;
      if (isValid(validVal)) {
        if (validVal.length === 2 && lastVal.length !== 3 && validVal.indexOf(':') === -1) {
          validVal += ':';
        }

        if (validVal.length === 2 && lastVal.length === 3) {
          validVal = validVal.slice(0, 1);
        }

        setLastVal(validVal);
        setTime(validVal);

        onTimeChange(validVal);
      }
    }
  };

  const { classes } = inputUseStyles();

  return (
    <TextField
      label={label}
      name={name || undefined}
      disabled={disabled}
      placeholder={placeholder}
      value={time}
      onChange={e => onChangeHandler(e.target.value)}
      InputLabelProps={{
        shrink: true,
        className: `${classes.label} ${!validity.isValid && 'invalidField'}`,
      }}
      InputProps={{
        className: classes.label,
        endAdornment: <ValidityAdornment validity={validity} />,
      }}
    />
  );
};

TimeInput.propTypes = {
  initTime: PropTypes.string,
  disabled: PropTypes.bool,
  onTimeChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  validity: PropTypes.object.isRequired,
};

TimeInput.defaultProps = {
  placeholder: ' ',
  initTime: ' ',
  disabled: false,
  name: null,
};

export default TimeInput;

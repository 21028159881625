import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormLabel, MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import utils from 'src/utils/utils';
import smjUtils from './utils';
import SMJModelItem from './SMJModelItem';
import ScheduleField from './ScheduleField';
import CustomCard from 'src/components/common/Card';
import SMJConfigFields from './SMJConfigFieldList';
import HelperCard from 'src/components/common/HelperCard';
import SMJSelectedCmpList from './SMJSelectedCmpList';

const useStyles = makeStyles()(theme => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    outline: 'none',
    cursor: 'pointer',
    padding: '12px 8px',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      opacity: 0.9,
    },
  },
  textField: {
    display: 'flex',
    margin: '8px',
  },
  textFieldInput: {
    color: theme.palette.text.secondary,
    ...theme.typography.body2,
  },
}));
// eslint-disable-next-line react/prop-types
const Ctn = ({ children, flex }) => (
  <div
    style={{
      display: 'flex',
      flex: flex || 1,
      flexDirection: 'column',
      padding: '0',
      overflow: 'auto',
      position: 'relative',
      margin: '8px',
    }}
  >
    {children}
  </div>
);

const SMJConfig = ({
  onCreate,
  onBack,
  requestModelByRMTypeId,
  newGroupSetup,
  onLanguageChange,
}) => {
  function getDescriptors() {
    return smjUtils.getDescriptors(
      newGroupSetup.groupModel.campaigns.filter(
        cmp => newGroupSetup.campaignSelection.indexOf(cmp.key) !== -1
      )
    );
  }

  function initFieldValues() {
    const descriptors = getDescriptors();
    return Object.keys(descriptors).reduce(
      (m, k) => ({
        ...m,
        [k]: {
          value: null,
          operatorValue: null,
          validity: smjUtils.getFieldValidity(descriptors[k], { value: null, operatorValue: null }),
        },
      }),
      {}
    );
  }

  const [fieldsValues, setFieldsValues] = useState(() => initFieldValues());

  const [period, setPeriod] = useState({});

  function periodIsValid(p) {
    return !!(p.start && p.time);
  }

  function fieldsAreVAlid(f) {
    return (
      Object.values(f)
        .map(v => v.validity)
        .map(vld => !!vld.isValid)
        .indexOf(false) === -1
    );
  }

  function languageIsValid() {
    return !!newGroupSetup.language;
  }

  const [validity, setValidity] = useState({
    fields: fieldsAreVAlid(fieldsValues),
    period: periodIsValid(period),
    language: languageIsValid(newGroupSetup.language),
  });

  function handleLanguageChange(l) {
    setValidity({ ...validity, language: languageIsValid(l) });
    onLanguageChange(l);
  }

  function onFieldChange(desc, value, isMulti) {
    const newFieldValues = { ...fieldsValues };
    newFieldValues[desc.key] = {
      ...newFieldValues[desc.key],
      value: isMulti ? [...value] : value,
    };
    newFieldValues[desc.key].validity = smjUtils.getFieldValidity(desc, newFieldValues[desc.key]);

    const allFieldsAreValid =
      Object.values(newFieldValues)
        .map(v => v.validity)
        .map(vld => !!vld.isValid)
        .indexOf(false) === -1;
    setFieldsValues(newFieldValues);

    setValidity({ ...validity, fields: allFieldsAreValid });
  }

  const onOperatorChange = useCallback(
    (id, operatorValue) => {
      // id is the key of the field
      if (fieldsValues[id]) {
        setFieldsValues({ ...fieldsValues, [id]: { ...fieldsValues[id], operatorValue } });
      } else {
        setFieldsValues({ ...fieldsValues, [id]: { operatorValue } });
      }
    },
    [fieldsValues]
  );

  const onPeriodChange = useCallback(
    (id, value) => {
      // id is 'start' 'end' or 'time'
      const newPeriod = { ...period, [id]: value };
      setValidity({ ...validity, period: periodIsValid(newPeriod) });
      setPeriod(newPeriod);
    },
    [period, validity]
  );

  function isValid() {
    return !!(validity.fields && validity.period);
  }

  const onValidate = useCallback(() => {
    onCreate(fieldsValues, period);
  }, [onCreate, fieldsValues, period]);

  const { classes } = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        padding: '8px 8px 8px 8px',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <HelperCard
        title={utils.getLang('smartmessaging.smartjourney.configuration.helper.title')}
        content={utils.getLang('smartmessaging.smartjourney.configuration.helper')}
      />
      <div
        style={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Ctn flex={2}>
          <CustomCard
            contentStyle={{ display: 'flex', flexDirection: 'column' }}
            title={
              <FormLabel required style={{ fontWeight: 400 }}>
                {utils.getLang('smartmessaging.smartjourney.configFields.label')}
              </FormLabel>
            }
          >
            <SMJConfigFields
              descriptors={getDescriptors()}
              onFieldChange={(desc, value, isMulti) => onFieldChange(desc, value, isMulti)}
              onOperatorChange={onOperatorChange}
              values={fieldsValues}
            />
          </CustomCard>
          <CustomCard
            title={
              <FormLabel required style={{ fontWeight: 400 }}>
                {utils.getLang('smartmessaging.smartjourney.scheduleField.label')}
              </FormLabel>
            }
          >
            <ScheduleField
              period={period}
              onChange={onPeriodChange}
              isValid={periodIsValid(period)}
            />
          </CustomCard>
          <CustomCard
            title={
              <FormLabel required style={{ fontWeight: 400 }}>
                {utils.getLang('smartmessaging.smartjourney.contentLanguage.label')}
              </FormLabel>
            }
          >
            <TextField
              className={classes.textField}
              onChange={e => {
                handleLanguageChange(e.target.value);
              }}
              select
              style={{ flex: 1 }}
              InputProps={{
                className: classes.textFieldInput,
                id: 'cmp-optin-ipt',
              }}
              SelectProps={{
                MenuProps: {
                  MenuListProps: { style: { padding: 0, margin: 0 } },
                  PopoverClasses: {
                    paper: classes.popover,
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  marginThreshold: 0,
                },
              }}
              value={newGroupSetup.language}
            >
              {['FR', 'EN', 'ES'].map(l => (
                <MenuItem key={l} value={l} className={classes.menuItem}>
                  {utils.getLang(`smartmessaging.language.${l}`)}
                </MenuItem>
              ))}
            </TextField>
          </CustomCard>
        </Ctn>
        <Ctn flex={3}>
          <CustomCard
            title={utils.getLang('smartmessaging.smartjourney.creator.selectedSmjModel')}
            rootStyle={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            contentStyle={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}
          >
            <SMJModelItem disabledSelect smjModel={newGroupSetup.groupModel} />
          </CustomCard>
          <CustomCard
            rootStyle={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}
            contentStyle={{ overflow: 'auto', flex: 1 }}
            title={utils.getLang('smartmessaging.smartjourney.creator.selectedCampaigns')}
          >
            <SMJSelectedCmpList
              requestModelByRMTypeId={requestModelByRMTypeId}
              selection={newGroupSetup.groupModel.campaigns
                .filter(cmp => newGroupSetup.campaignSelection.indexOf(cmp.key) !== -1)
                .reduce((m, cmp) => {
                  if (m[cmp.type]) {
                    m[cmp.type].push(cmp);
                    return m;
                  }
                  return { ...m, [cmp.type]: [cmp] };
                }, {})}
            />
          </CustomCard>
        </Ctn>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button style={{ margin: '2px' }} variant="text" color="primary" onClick={() => onBack()}>
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </Button>
        <Button
          style={{ margin: '2px' }}
          variant="contained"
          color="primary"
          disabled={!isValid()}
          onClick={onValidate}
        >
          {utils.getLang('smartmessaging.campaigns.grouped.createGroup')}
        </Button>
      </div>
    </div>
  );
};

SMJConfig.propTypes = {
  requestModelByRMTypeId: PropTypes.object.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  newGroupSetup: PropTypes.object.isRequired,
};

export default SMJConfig;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import MailEditor from './MailEditor';
import NotificationEditor from './NotifyEditor';
import SmsEditor from './SmsEditor';
import SmsValidity from './SmsValidity';
import { saveActionContent, checkContentValidity } from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import ActionType from 'src/enums/actionType';
import { MESSAGE_TYPE } from './CampaignMessage';

const MessageEditor = ({
  actionTypeId,
  fields,
  clearCurrentAction,
  contentValidity,
  doSaveActionContent,
  goToCmpMsgStep,
  editedCampaign,
}) => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      textAlign: 'left',
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '8px',
      }}
    >
      {actionTypeId === ActionType.SMS.id && (
        <div style={{ textAlign: 'center' }}>
          <div style={{ width: '320px', display: 'inline-block' }}>
            <SmsValidity />
            <SmsEditor fields={fields} isWritable={editedCampaign.isWritable} />
          </div>
        </div>
      )}
      {[
        ActionType.EMAIL.id,
        ActionType.EMAIL_COACH.id,
        ActionType.EMAIL_SPONSORSHIP.id,
        ActionType.EMAIL_INVITATION.id,
      ].indexOf(actionTypeId) !== -1 && (
        <MailEditor fields={fields} isWritable={editedCampaign.isWritable} />
      )}
      {actionTypeId === ActionType.MEMBER_NOTIFICATION.id && (
        <NotificationEditor fields={fields} isWritable={editedCampaign.isWritable} />
      )}
    </div>
    <div style={{ textAlign: 'right', padding: '4px 16px' }}>
      <Button
        style={{ margin: '4px' }}
        variant="text"
        color="primary"
        onClick={() => {
          clearCurrentAction();
          goToCmpMsgStep(MESSAGE_TYPE);
        }}
      >
        {utils.getLang('smartmessaging.button.contentEditor.cancel')}
      </Button>
      {editedCampaign.isWritable && (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '4px' }}
          disabled={!contentValidity.isValid}
          onClick={() => {
            doSaveActionContent(() => {
              goToCmpMsgStep(MESSAGE_TYPE);
              clearCurrentAction();
            });
          }}
        >
          {utils.getLang('smartmessaging.button.contentEditor.save')}
        </Button>
      )}
    </div>
  </div>
);

MessageEditor.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  clearCurrentAction: PropTypes.func.isRequired,
  contentValidity: PropTypes.object.isRequired,
  doSaveActionContent: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  contentValidity: checkContentValidity(state),
  isWritable: state.campaignEditor.editedCampaign.isWritable,
  currentAppLn: state.app.currentLanguage,
});

const actionCreators = {
  clearCurrentAction: () => ({ type: 'CLEAR_CURRENT_ACTION' }),
  doSaveActionContent: saveActionContent,
};

MessageEditor.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, actionCreators)(MessageEditor);

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  title: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CampaignTypeItem = ({ typeItem, selectCampaignType, disabledSelect }) => {
  const { classes, cx } = useStyles();
  return (
    <Card
      onClick={event => {
        event.preventDefault();
        if (!disabledSelect) selectCampaignType(typeItem);
      }}
      elevation={1}
      className={classes.card}
    >
      <CardContent
        className={cx(classes.content, { selectable: !disabledSelect })}
        classes={{ root: classes.contentRoot }}
      >
        <Typography className={classes.title} color="primary" variant="body2">
          {utils.getLang(`smartmessaging.requestmodel.label.${typeItem.name}`)}
        </Typography>
        <Typography className={classes.description} color="textSecondary" variant="caption">
          {utils.getLang(`smartmessaging.requestmodel.description.${typeItem.name}`)}
        </Typography>
      </CardContent>
    </Card>
  );
};

CampaignTypeItem.propTypes = {
  typeItem: PropTypes.object.isRequired,
  selectCampaignType: PropTypes.func,
  disabledSelect: PropTypes.bool,
};

CampaignTypeItem.defaultProps = {
  disabledSelect: false,
  selectCampaignType: () => {},
};

export default CampaignTypeItem;

 
import { API_BASE_URL, doAuthentifiedReq } from '../apiClient';

const getSMJList = async () => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartjourney/all`,
  });
  return response.data;
};

 
const getSMJModelList = async () => {
  const models = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartjourneymodel/all`,
  });
  const cmpsByModel = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartjourneymodel/all/complete`,
  });

  const result = Object.keys(models.data).reduce(
    (r, k) => ({ ...r, [k]: { key: models.data[k], campaigns: cmpsByModel.data[k] } }),
    {}
  );
  return result;
};

const createSMJ = async ({ smj, cmpList, language }) => {
  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/smartjourney/createSMJ`,
    data: { smartjourney: smj, listeCreateCampaign: cmpList, language },
  });
  return response.data;
};

const loadSMJCampaigns = async smjId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/campaign/smartjourney/${smjId}`,
  });

  return response.data;
};

const loadSMJModelCampaigns = async smjModelId => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartjourneymodel/${smjModelId}`,
  });
  return response.data;
};

const getCampaignGroup = async id => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartjourney/${id}`,
  });
  return response.data;
};

const canWriteOnGroup = async id => {
  const response = await doAuthentifiedReq({
    method: 'get',
    url: `${API_BASE_URL}/smartjourney/canWriteOnGroup/${id}`,
  });
  return response.data;
};

const deleteSmj = async smjId => {
  const response = await doAuthentifiedReq({
    method: 'delete',
    url: `${API_BASE_URL}/smartjourney/${smjId}`,
  });
  return response.data;
};

const insertOrUpdateSMJ = async smj => {
  const response = await doAuthentifiedReq({
    method: 'put',
    url: `${API_BASE_URL}/smartjourney`,
    data: smj,
  });
  return response.data;
};

const getSmjInfosById = async ids => {
  const response = await doAuthentifiedReq({
    method: 'POST',
    url: `${API_BASE_URL}/smartjourney/infos`,
    data: ids || [1],
  });
  return response.data;
};

export default {
  getSMJList,
  getSMJModelList,
  loadSMJModelCampaigns,
  loadSMJCampaigns,
  createSMJ,
  getCampaignGroup,
  canWriteOnGroup,
  deleteSmj,
  insertOrUpdateSMJ,
  getSmjInfosById,
};

import React from 'react';
import { IconButton, InputAdornment, FormControl, FormLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

import IntegerField from 'src/components/common/IntegerField';
import OperatorSelect from 'src/components/common/OperatorSelect';
import ValidityAdornment from './ValidityAdornment';

import utils from 'src/utils/utils';

const IntegerMassActionField = ({
  classes,
  fieldConfig,
  onChange,
  removeLine,
  onOperatorChange,
  removable,
  validity,
  isWritable,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.massAction.filterLabel.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect
          fieldConfig={fieldConfig}
          onChange={onOperatorChange}
          disabled={!isWritable}
        />
      </div>
      <IntegerField
        disabled={!isWritable}
        style={{
          fontSize: '0.875rem',
          flex: '1',
        }}
        onChange={newValue => {
          onChange(fieldConfig, newValue);
        }}
        value={
          (fieldConfig.value &&
            fieldConfig.value.values &&
            fieldConfig.value.values.length &&
            fieldConfig.value.values[0]) ||
          ''
        }
      />
      {isWritable && removable && (
        <InputAdornment style={{ height: 'unset' }} position="start">
          <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)} size="large">
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      )}
    </div>
  </FormControl>
);

IntegerMassActionField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default IntegerMassActionField;

import getApiCaller from 'src/utils/apiClientCaller';
import customTemplateApi from 'src/utils/api/customTemplateApi';

import customTemplateUtils from './utils';

export const loadCustomTemplates = () => async (dispatch, getState) => {
  const callApi = getApiCaller(dispatch, getState);

  const { config } = getState().app;
  const customTemplateList = await callApi(customTemplateApi.getCustomTemplates, [
    [
      (config['smartm-private:filter'] && 'privatefilter') || false,
      (config['smartm-planned:filter'] && 'plannedfilter') || false,
    ],
  ]);

  dispatch({
    type: 'RECEIVE_CUSTOM_TEMPLATES',
    value: customTemplateUtils.mapCustomTemplates(customTemplateList),
  });
};

const receiveCustomTemplates = (state, { value }) => ({
  ...state,
  customTemplateMap: value,
});

export const listInitialState = { customTemplateMap: {} };
export const listActions = {
  RECEIVE_CUSTOM_TEMPLATES: receiveCustomTemplates,
};

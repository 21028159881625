import utils from 'src/utils/utils';

const columnNames = [
  // 'id',
  'name',
  'creationDate',
  'smartJourneyModelId',
  'nodename',
  'creationUserName',
  // 'createdBy',
];

const columnsSorters = {
  id: {
    asc: (a, b) => (a < b ? -1 : 1),
    desc: (a, b) => (a < b ? 1 : -1),
  },
  name: {
    asc(a, b) {
      const sortValueA = a.name ? a.name.toLowerCase() : false;
      const sortValueB = b.name ? b.name.toLowerCase() : false;
      if (!sortValueA) return 1;
      if (!sortValueB) return -1;
      return sortValueA < sortValueB ? -1 : 1;
    },
    desc(a, b) {
      const sortValueA = a.name ? a.name.toLowerCase() : false;
      const sortValueB = b.name ? b.name.toLowerCase() : false;
      if (!sortValueA) return -11;
      if (!sortValueB) return 1;
      return sortValueA > sortValueB ? -1 : 1;
    },
  },
  creationDate: {
    asc: (a, b) => (a.creationDate < b.creationDate ? -1 : 1),
    desc: (a, b) => (a.creationDate < b.creationDate ? 1 : -1),
  },
  smartJourneyModelId: {
    asc: (a, b) => (a.smartJourneyModelId < b.smartJourneyModelId ? -1 : 1),
    desc: (a, b) => (a.smartJourneyModelId < b.smartJourneyModelId ? 1 : -1),
  },
  nodename: {
    asc(a, b) {
      const sortValueA = a.name ? a.name.toLowerCase() : false;
      const sortValueB = b.name ? b.name.toLowerCase() : false;
      if (!sortValueA) return 1;
      if (!sortValueB) return -1;
      return sortValueA < sortValueB ? -1 : 1;
    },
  },
  creationusername: {
    asc(a, b) {
      const sortValueA = a.name ? a.name.toLowerCase() : false;
      const sortValueB = b.name ? b.name.toLowerCase() : false;
      if (!sortValueA) return 1;
      if (!sortValueB) return -1;
      return sortValueA < sortValueB ? -1 : 1;
    },
  },
};

const columnRenderers = {
  creationDate: time => new Date(time).toLocaleDateString(),
  smartJourneyModelId: (smartJourneyModelId, { smjModelById }) => {
    const key = smartJourneyModelId ? smjModelById[smartJourneyModelId] : 'NO_GROUP_MODEL';
    return utils.getLang(`smartmessaging.campaigns.grouped.groupModel.name.${key}`);
  },
};

const renderCell = (colName, smj, extraParams) => {
  if (columnRenderers[colName]) return columnRenderers[colName](smj[colName], extraParams);
  return smj[colName];
};

export default { columnNames, columnsSorters, renderCell };

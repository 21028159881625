import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import utils from 'src/utils/utils';

import OperatorType from 'src/enums/operatorType';
import IntegerMassActionField from './massActionFields/IntegerMassActionField';
import IntegerBoundMassActionField from './massActionFields/IntegerBoundMassActionField';
import DateBoundMassActionField from './massActionFields/DateBoundMassActionField';
import TextMassActionField from './massActionFields/TextMassActionField';
import SelectMassActionField from './massActionFields/SelectMassActionField';
import DateMassActionField from './massActionFields/DateMassActionField';
import NoInputMassActionField from './massActionFields/NoInputMassActionField';
import GroupField from './massActionFields/GroupField';
import ParameterType from 'src/enums/parameterType';

const styles = () => ({
  fieldContainer: {
    display: 'flex',
    marginTop: '8px',
    marginBottom: '8px',
    marginLeft: '40px',
    marginRight: '20px',
  },
});

const MassActionCriteriaField = ({
  classes,
  model,
  value,
  uniqueId,
  groupName,
  removeCriteria,
  changeCriteriaValue,
  changeOperatorValue,
  removable,
  validity,
  isWritable,
  groupDisplayNames,
  parent,
}) => {
  if (
    [OperatorType.NULL, OperatorType.NOT_NULL, OperatorType.TRUE, OperatorType.FALSE].indexOf(
      value.operatorId
    ) !== -1 ||
    ParameterType[model.type] === ParameterType.BOOLEAN
  ) {
    return (
      <NoInputMassActionField
        classes={classes}
        isWritable={isWritable}
        fieldConfig={{ value, model }}
        onOperatorChange={(fc, operatorValue) => {
          changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent });
        }}
        removable={removable}
        removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
        validity={validity}
      />
    );
  }
  switch (ParameterType[model.type]) {
    case ParameterType.INTEGER:
    case ParameterType.NUMBER:
      switch (value.operatorId) {
        case OperatorType.BETWEEN:
        case OperatorType.NOT_BETWEEN:
          return (
            <IntegerBoundMassActionField
              isWritable={isWritable}
              validity={validity}
              classes={classes}
              fieldConfig={{ value, model }}
              onChange={(fc, newValue) =>
                changeCriteriaValue({ newValue, uniqueId, model, groupName, parent })
              }
              onOperatorChange={(fc, operatorValue) => {
                if (
                  [
                    OperatorType.BETWEEN,
                    OperatorType.NOT_BETWEEN,
                    OperatorType.IN,
                    OperatorType.NOT_IN,
                  ].indexOf(operatorValue) === -1 &&
                  value.values &&
                  value.values.length
                ) {
                  changeCriteriaValue({
                    newValue: [value.values[0]],
                    uniqueId,
                    model,
                    groupName,
                    parent,
                  });
                }
                changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent });
              }}
              removable={removable}
              removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
            />
          );
        default:
          return (
            <IntegerMassActionField
              isWritable={isWritable}
              validity={validity}
              classes={classes}
              fieldConfig={{ value, model }}
              onChange={(fc, newValue) =>
                changeCriteriaValue({ newValue, uniqueId, model, groupName, parent })
              }
              onOperatorChange={(fc, operatorValue) => {
                changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent });
              }}
              removable={removable}
              removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
            />
          );
      }
    case ParameterType.TEXT:
      return (
        <TextMassActionField
          isWritable={isWritable}
          validity={validity}
          removable={removable}
          classes={classes}
          fieldConfig={{ value, model }}
          onChange={(fc, newValue) =>
            changeCriteriaValue({ newValue, uniqueId, model, groupName, parent })
          }
          onOperatorChange={(fc, operatorValue) => {
            changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent });
          }}
          removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
        />
      );
    case ParameterType.ENUM:
    case ParameterType.ENUM_TEXT:
      return (
        <SelectMassActionField
          isWritable={isWritable}
          validity={validity}
          removable={removable}
          classes={classes}
          data={utils.getDataListFromEnum(model.enumName)}
          fieldConfig={{ value, model }}
          onChange={(fc, newValue) => {
            changeCriteriaValue({ newValue, uniqueId, model, groupName, parent });
          }}
          onOperatorChange={(fc, operatorValue) =>
            changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent })
          }
          removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
        />
      );
    case ParameterType.IFOREIGN_KEY:
    case ParameterType.TFOREIGN_KEY:
    case ParameterType.N_FOREIGN_KEY:
      return (
        <SelectMassActionField
          isWritable={isWritable}
          validity={validity}
          removable={removable}
          classes={classes}
          fieldConfig={{ value, model }}
          onChange={(fc, newValue) => {
            changeCriteriaValue({ newValue, uniqueId, model, groupName, parent });
          }}
          onOperatorChange={(fc, operatorValue) =>
            changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent })
          }
          removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
        />
      );
    case ParameterType.CLUBS:
      return (
        <SelectMassActionField
          selectAll={options => options.reduce((r, opts) => [...r, ...opts.options], [])}
          getValue={vals => vals}
          mapValue={(values, options) => {
            const rez = [];
            const ungroupeOptions = options.reduce((mp, grp) => [...mp, ...grp.options], []);
            values
              .map(fkValue =>
                fkValue.values.map(idValue =>
                  ungroupeOptions.find(
                    o => o.key === fkValue.key && JSON.parse(o.value).id === idValue
                  )
                )
              )
              .forEach(list => rez.push(...list));

            return rez;
          }}
          isWritable={isWritable}
          validity={validity}
          removable={removable}
          classes={classes}
          fieldConfig={{ value, model }}
          onChange={(fc, newValue) => {
            changeCriteriaValue({ newValue, uniqueId, model, groupName, parent });
          }}
          onOperatorChange={(fc, operatorValue) =>
            changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent })
          }
          removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
        />
      );
    case ParameterType.DATE:
      switch (value.operatorId) {
        case OperatorType.BETWEEN:
        case OperatorType.NOT_BETWEEN:
          return (
            <DateBoundMassActionField
              isWritable={isWritable}
              validity={validity}
              classes={classes}
              fieldConfig={{ value, model }}
              onChange={(fc, newValue) =>
                changeCriteriaValue({ newValue, uniqueId, model, groupName, parent })
              }
              onOperatorChange={(fc, operatorValue) => {
                if (
                  [
                    OperatorType.BETWEEN,
                    OperatorType.NOT_BETWEEN,
                    OperatorType.IN,
                    OperatorType.NOT_IN,
                  ].indexOf(operatorValue) === -1 &&
                  value.values &&
                  value.values.length
                )
                  changeCriteriaValue({
                    newValue: [value.values[0]],
                    uniqueId,
                    model,
                    groupName,
                    parent,
                  });
                changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent });
              }}
              removable={removable}
              removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
            />
          );
        default:
          return (
            <DateMassActionField
              isWritable={isWritable}
              validity={validity}
              removable={removable}
              classes={classes}
              fieldConfig={{ value, model }}
              onChange={(fc, newValue) =>
                changeCriteriaValue({ newValue, uniqueId, model, groupName, parent })
              }
              onOperatorChange={(fc, operatorValue) =>
                changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent })
              }
              removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
            />
          );
      }
    case ParameterType.GROUP:
      return (
        <GroupField
          {...{
            model,
            value,
            uniqueId,
            groupName,
            validity,
            removable,
            isWritable,
            groupDisplayName: groupDisplayNames[uniqueId],
          }}
        />
      );
    default: {
      return (
        <IntegerMassActionField
          isWritable={isWritable}
          validity={validity}
          removable={removable}
          classes={classes}
          fieldConfig={{ value, model }}
          onChange={(fc, newValue) =>
            changeCriteriaValue({ newValue, uniqueId, model, groupName, parent })
          }
          onOperatorChange={(fc, operatorValue) => {
            changeOperatorValue({ operatorValue, uniqueId, model, groupName, parent });
          }}
          removeLine={() => removeCriteria({ uniqueId, model, groupName, parent })}
        />
      );
    }
  }
};

MassActionCriteriaField.propTypes = {
  classes: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  removeCriteria: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  changeCriteriaValue: PropTypes.func.isRequired,
  uniqueId: PropTypes.string.isRequired,
  changeOperatorValue: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
  parent: PropTypes.object,
  groupDisplayNames: PropTypes.object,
};

MassActionCriteriaField.defaultProps = {
  parent: null,
  groupDisplayNames: null,
};

const mapStateToProps = () => ({});

const actionCreators = {
  removeCriteria: ({ uniqueId, model, groupName, parent }) => ({
    type: 'REMOVE_CRITERIA_FROM_SELECTION',
    value: { groupName, model, uniqueId, parent },
  }),

  changeCriteriaValue: ({ newValue, uniqueId, model, groupName, parent }) => ({
    type: 'ON_CRITERIA_VALUE_CHANGE',
    value: { newValue, uniqueId, model, groupName, parent },
  }),

  changeOperatorValue: ({ operatorValue, uniqueId, model, groupName, parent }) => ({
    type: 'ON_CRITERIA_OPERATOR_VALUE_CHANGE',
    value: { operatorValue, uniqueId, model, groupName, parent },
  }),
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(MassActionCriteriaField, styles));

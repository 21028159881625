import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Fade, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  getDistinctActiveFieldKeys,
  mapActiveFieldsByKey,
  loadEmptyRecipeConfig,
} from 'src/reducers/campaignEditor';
import withDataResolver from 'src/components/common/withDataResolver';

import AvailableFilter from './AvailableFilter';
import ActiveFilterCtn from './ActiveFilterCtn';

import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  disabledBtn: {
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.7,
  },
  title: { fontSize: '0.9rem', color: theme.palette.text.secondary },
  cardHeader: { backgroundColor: theme.palette.grey[100], margin: '2px 2px 0 2px' },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column' },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: '10px 15px' },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'left',
  },
  flex: { flex: 1 },

  root: { padding: '10px 15px' },
  addCatBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

const FilterListRender = ({
  inactiveFields,
  addActiveField,
  distinctActiveFieldKeysList,
  activeFieldsByKey,
  recipeCfgValidity,
  editedCampaign,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <Fade in timeout={500}>
      <Grid
        container
        direction="row"
        style={{
          flexWrap: 'nowrap',
          overflow: 'hidden',
          flex: 1,
          border: `1px solid ${theme.palette.grey[100]}`,
        }}
      >
        <Grid
          item
          style={{
            flexDirection: 'column',
            display: 'flex',
            width: 'fit-content',
            maxWidth: '360px',
          }}
        >
          <div
            style={{
              overflow: 'auto',
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <Card
              elevation={0}
              style={{
                borderRadius: 0,
                textAlign: 'left',
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
              }}
            >
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  title: classes.title,
                  action: classes.action,
                  root: classes.cardHeaderTitleRoot,
                }}
                title={utils.getLang('smartmessaging.massAction.availableFilters')}
              />
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingTop: 0,
                  flex: 1,
                  marginRight: '2px',
                  overflow: 'auto',
                }}
              >
                {inactiveFields.map(inactiveField => (
                  <AvailableFilter
                    key={inactiveField.key}
                    label={utils.getLang(
                      `smartmessaging.availableRecipeField.${inactiveField.key}`
                    )}
                    filter={inactiveField}
                    addCriteria={() => {
                      if (editedCampaign.isWritable) addActiveField(inactiveField);
                    }}
                  />
                ))}
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid
          item
          style={{
            flex: 3,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: `1px solid ${theme.palette.grey[100]}`,
          }}
        >
          <div
            style={{
              overflow: 'auto',
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <Card
              elevation={0}
              style={{
                minWidth: '600px',
                flex: 1,
                textAlign: 'left',
                borderRadius: 0,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  title: classes.title,
                  action: classes.action,
                  root: classes.cardHeaderTitleRoot,
                }}
                title={utils.getLang('smartmessaging.massAction.selectedFilters')}
              />
              <CardContent
                style={{
                  overflow: 'auto',
                  flex: 1,
                  padding: '8px',
                }}
              >
                {distinctActiveFieldKeysList.map(activeKey => (
                  <ActiveFilterCtn
                    mandatory={activeFieldsByKey[activeKey][0].model.mandatory}
                    fields={activeFieldsByKey[activeKey]}
                    key={activeKey}
                    criteriaKey={activeKey}
                    invalidities={recipeCfgValidity.invalidFields}
                    isWritable={editedCampaign.isWritable}
                  />
                ))}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Fade>
  );
};

FilterListRender.propTypes = {
  inactiveFields: PropTypes.array.isRequired,
  addActiveField: PropTypes.func.isRequired,
  distinctActiveFieldKeysList: PropTypes.array.isRequired,
  activeFieldsByKey: PropTypes.object.isRequired,
  recipeCfgValidity: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

const FilterList = connect(
  state => ({
    editedCampaign: state.campaignEditor.editedCampaign,
    selectedType: state.campaignEditor.selectedType,
    inactiveFields: state.campaignEditor.recipeFieldsConfig.inactiveFields,
    distinctActiveFieldKeysList: getDistinctActiveFieldKeys(state), // todo: remove and use Object.keys(activeFieldsByKey)
    activeFieldsByKey: mapActiveFieldsByKey(state),
    anchorEl: state.campaignEditorView.recipeFieldMenuAnchorEl,
    loadedRecipe: state.campaignEditor.recipeFieldsConfig.loaded,
  }),
  {
    hideOrShowMenu: anchorEl => ({ type: 'HIDE_OR_SHOW_FIELDS_MENU', value: anchorEl }),
    addActiveField: fieldModel => ({ type: 'ADD_ACTIVE_FIELD', value: fieldModel }),
    doLoadRecipeConfig: loadEmptyRecipeConfig,
  }
)(
  withDataResolver(
    {
      resolve: props => async () => {
        const { editedCampaign, doLoadRecipeConfig, selectedType, loadedRecipe } = props;
        if (!loadedRecipe)
          await doLoadRecipeConfig(
            editedCampaign.requestModelTypeId || selectedType.requestModelTypeId
          );
      },
      onResolved: () => async () => {},
    },
    {
      loaderWrapper: {
        class: 'ghostWrapperClass',
        style: { position: 'relative', height: '96px' },
      },
    }
  )(FilterListRender)
);

export default FilterList;

import { useRef, useLayoutEffect } from 'react';

type WillUnmountFunction = () => void;

const useWillUnmount = (fn: WillUnmountFunction) => {
  const teardownFn = useRef(fn);
  teardownFn.current = fn;

  useLayoutEffect(
    () => () => {
      teardownFn.current();
    },
    []
  );
};

export default useWillUnmount;

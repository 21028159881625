import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import IntegerRecipeField from './IntegerRecipeField';
import NumberRecipeField from './NumberRecipeField';
import RecipeSelectField from './RecipeSelectField';
import IntegerBoundRecipeField from './IntegerBoundRecipeField';
import NumberBoundRecipeField from './NumberBoundRecipeField';
import OperatorType from 'src/enums/operatorType';
import NoInputRecipeField from './NoInputRecipeField';
import ActiveFilterCtn from './ActiveFilterCtn';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(() => ({
  fieldContainer: {
    display: 'flex',
    margin: '8px 16px',
  },
}));

const getValidity = (invalidities, fieldConfig) => {
  const rez = invalidities.find(
    inv => !inv.parentIndex && inv.index === fieldConfig.indexInList
  ) || {
    isValid: true,
  };
  return rez;
};
const Filter = ({
  fieldConfig,
  onRecipeFieldChange,
  onRecipeFieldOperatorChange,
  removeRecipeFieldLine,
  removeRecipeGroupFieldLine,
  removableLine,
  invalidities,
  isWritable,
}) => {
  const { classes } = useStyles();
  const operatorId =
    (fieldConfig.value && fieldConfig.value.operatorId) ||
    (fieldConfig.model.allowedOperators &&
      fieldConfig.model.allowedOperators.length === 1 &&
      OperatorType[fieldConfig.model.allowedOperators[0]]) ||
    0;
  if (
    (fieldConfig.value &&
      [OperatorType.NULL, OperatorType.NOT_NULL, OperatorType.TRUE, OperatorType.FALSE].indexOf(
        fieldConfig.value.operatorId
      ) !== -1) ||
    fieldConfig.model.type === 'BOOLEAN'
  ) {
    return (
      <NoInputRecipeField
        disabled={!isWritable}
        validity={getValidity(invalidities, fieldConfig)}
        classes={classes}
        fieldConfig={fieldConfig}
        onOperatorChange={onRecipeFieldOperatorChange}
        removable={removableLine && isWritable}
        removeLine={(fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine}
      />
    );
  }
  switch (fieldConfig.model.type) {
    case 'INTEGER': {
      switch (operatorId) {
        case OperatorType.BETWEEN:
        case OperatorType.NOT_BETWEEN:
          return (
            <IntegerBoundRecipeField
              disabled={!isWritable}
              validity={getValidity(invalidities, fieldConfig)}
              classes={classes}
              fieldConfig={fieldConfig}
              onChange={onRecipeFieldChange}
              onOperatorChange={onRecipeFieldOperatorChange}
              removeLine={
                (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
              }
              removable={removableLine}
            />
          );
        default:
          return (
            <IntegerRecipeField
              disabled={!isWritable}
              validity={getValidity(invalidities, fieldConfig)}
              classes={classes}
              fieldConfig={fieldConfig}
              onChange={onRecipeFieldChange}
              onOperatorChange={onRecipeFieldOperatorChange}
              removeLine={
                (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
              }
              removable={removableLine}
            />
          );
      }
    }
    case 'NUMBER': {
      switch (operatorId) {
        case OperatorType.BETWEEN:
        case OperatorType.NOT_BETWEEN:
          return (
            <NumberBoundRecipeField
              disabled={!isWritable}
              validity={getValidity(invalidities, fieldConfig)}
              classes={classes}
              fieldConfig={fieldConfig}
              onChange={onRecipeFieldChange}
              onOperatorChange={onRecipeFieldOperatorChange}
              removeLine={
                (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
              }
              removable={removableLine}
            />
          );
        default:
          return (
            <NumberRecipeField
              disabled={!isWritable}
              validity={getValidity(invalidities, fieldConfig)}
              classes={classes}
              fieldConfig={fieldConfig}
              onChange={onRecipeFieldChange}
              onOperatorChange={onRecipeFieldOperatorChange}
              removeLine={
                (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
              }
              removable={removableLine}
            />
          );
      }
    }

    case 'ENUM':
    case 'ENUM_TEXT': {
      return (
        <RecipeSelectField
          disabled={!isWritable}
          validity={getValidity(invalidities, fieldConfig)}
          classes={classes}
          data={utils.getDataListFromEnum(fieldConfig.model.enumName)}
          fieldConfig={fieldConfig}
          onChange={onRecipeFieldChange}
          onOperatorChange={onRecipeFieldOperatorChange}
          removeLine={
            (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
          }
          removable={removableLine}
        />
      );
    }
    case 'IFOREIGN_KEY':
    case 'TFOREIGN_KEY':
    case 'N_FOREIGN_KEY': {
      return (
        <RecipeSelectField
          mapValue={(value, options) => value.map(v => options.find(o => o.value === v))}
          disabled={!isWritable}
          validity={getValidity(invalidities, fieldConfig)}
          classes={classes}
          fieldConfig={fieldConfig}
          onChange={(fc, v) => {
            onRecipeFieldChange(fc, v);
          }}
          onOperatorChange={onRecipeFieldOperatorChange}
          removeLine={
            (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
          }
          removable={removableLine}
        />
      );
    }
    case 'CLUBS': {
      return (
        <RecipeSelectField
          selectAll={options => options.reduce((r, opts) => [...r, ...opts.options], [])}
          disabled={!isWritable}
          mapValue={(values, options) => {
            const rez = [];
            const ungroupeOptions = options.reduce((mp, grp) => [...mp, ...grp.options], []);
            values
              .map(fkValue =>
                fkValue.values.map(idValue =>
                  ungroupeOptions.find(
                    o => o.key === fkValue.key && JSON.parse(o.value).id === idValue
                  )
                )
              )
              .forEach(list => rez.push(...list));

            return rez;
          }}
          validity={getValidity(invalidities, fieldConfig)}
          classes={classes}
          fieldConfig={fieldConfig}
          getValue={vals => vals}
          onChange={(fc, v) => {
            onRecipeFieldChange(fc, v);
          }}
          onOperatorChange={onRecipeFieldOperatorChange}
          removeLine={
            (fieldConfig.parentKey && removeRecipeGroupFieldLine) || removeRecipeFieldLine
          }
          removable={removableLine}
        />
      );
    }

    case 'GROUP':
      return (
        <div style={{ margin: '4px 16px' }}>
          {fieldConfig.model.groupConfig.keyList.map(key => (
            <ActiveFilterCtn
              invalidities={invalidities
                .filter(invf => invf.parentIndex === fieldConfig.indexInList)
                .map(minvf => {
                  const { parentIndex, ...parentClear } = minvf;
                  return parentClear;
                })}
              mandatory={fieldConfig.model.groupConfig.activeFieldsByKey[key][0].model.mandatory}
              fields={fieldConfig.model.groupConfig.activeFieldsByKey[key]}
              key={key}
              criteriaKey={key}
              isWritable={isWritable}
            />
          ))}
        </div>
      );
    //
    case 'TEXT':
    case 'DATE':
    case 'BOOLEAN':
    default: {
      return fieldConfig.model.key;
    }
  }
};

Filter.propTypes = {
  fieldConfig: PropTypes.object.isRequired,
  onRecipeFieldChange: PropTypes.func.isRequired,
  onRecipeFieldOperatorChange: PropTypes.func.isRequired,
  removeRecipeGroupFieldLine: PropTypes.func.isRequired,
  removableLine: PropTypes.bool.isRequired,
  isWritable: PropTypes.bool.isRequired,
  invalidities: PropTypes.array.isRequired,
  removeRecipeFieldLine: PropTypes.func.isRequired,
};

const actionCreators = {
  removeRecipeFieldLine: fieldConfig => ({ type: 'REMOVE_RECIPE_FIELD_LINE', value: fieldConfig }),
  removeRecipeGroupFieldLine: fieldConfig => ({
    type: 'REMOVE_RECIPE_GROUP_FIELD_LINE',
    value: fieldConfig,
  }),
  onRecipeFieldChange: (fieldConfig, value) => ({
    type: 'ON_RECIPE_FIELD_CHANGE',
    value: { fieldConfig, value },
  }),
  onRecipeFieldOperatorChange: (fieldConfig, value) => ({
    type: 'ON_RECIPE_FIELD_OP_CHANGE',
    value: { fieldConfig, value },
  }),
};

export default connect(null, actionCreators)(Filter);

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, ListItem, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { makeStyles } from 'tss-react/mui';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  menuItemName: {
    color: theme.palette.text.main,
    background: theme.palette.action.hover,
    ...theme.typography.body2,
  },
  menuItem: {
    outline: 'none',
    textOverflow: 'ellipsis',
  },
}));

function SMJItemMenu({ smj, options, actions }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Fragment>
      <IconButton
        style={{ padding: '4px' }}
        aria-owns={anchorEl ? `item-menu-${smj.id}` : null}
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`item-menu-${smj.id}`}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
      >
        <ListItem className={classes.menuItemName} divider>
          {smj.name}
        </ListItem>
        {options.map(option => (
          <MenuItem
            dense
            className={classes.menuItem}
            key={option}
            onClick={() => {
              handleClose();
              actions[option]();
            }}
          >
            {utils.getLang(`smartmessaging.smartjourneyList.item.${option}`)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

SMJItemMenu.propTypes = {
  smj: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};

export default SMJItemMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentRequestModelId } from 'src/reducers/campaignEditor';
import CKEditor from 'src/components/common/ReactCKEditor';
import CtnLang from './CtnLang';

const SmsEditor = ({ currentAction, fields, onSmsContentChange, isWritable }) => (
  <div
    style={{
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
      overflow: 'visible',
    }}
  >
    <div style={{ marginRight: 0, marginLeft: 'auto' }}>
      <CtnLang />
    </div>
    <div>
      <CKEditor
        name="objectEditor"
        config={{
          readOnly: currentAction.messageModel
            ? !isWritable || !currentAction.messageModel.generic
            : !isWritable ||
              (currentAction.customTemplate && !currentAction.customTemplate.generic),
          fields: fields.fields,
          removeButtons:
            'Underline,Subscript,Superscript,' +
            'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
            'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
            'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
            'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
            'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
            'BidiLtr,BidiRtl,CreatePlaceholder,' +
            'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
          extraPlugins: 'strinsert',
          removePlugins: 'colorbutton,elementspath',
          autoParagraph: false,
          enterMode: 2,
          allowedContent: true,
        }}
        events={{
          change: event => {
            const data = event.editor.getData();
            onSmsContentChange(data);
          },
          instanceReady: event => {
            if (currentAction.content) event.editor.setData(currentAction.content);
          },
        }}
      />
    </div>
  </div>
);

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
  currentRequestModelId: getCurrentRequestModelId(state),
});

const actionCreators = {
  onSmsContentChange: data => ({ type: 'ON_SMS_CONTENT_CHANGE', value: data }),
};

SmsEditor.propTypes = {
  currentAction: PropTypes.object,
  fields: PropTypes.any.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onSmsContentChange: PropTypes.func.isRequired,
};

SmsEditor.defaultProps = {
  currentAction: null,
};

export default connect(mapStateToProps, actionCreators)(SmsEditor);

import CivilityType from './civilityType';
import StatusType from './statusType';
import PeriodType from './periodType';
import Channel from './channel';
import MandateRejectionType from './mandateRejectionType';
import ContactChannel from './contactChannel';

export default {
  CivilityType,
  StatusType,
  PeriodType,
  Channel,
  MandateRejectionType,
  ContactChannel,
};

import React, { Fragment } from 'react';
import { Typography, Divider } from '@mui/material';

import CustomTemplateList from './CustomTemplateList';
import CustomTemplateEditor from './customTemplateEditor/CustomTemplateEditor';

import utils from 'src/utils/utils';

const CustomTemplateContent = () => (
  <Fragment>
    <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
      <Typography variant="h5" color="textSecondary" style={{ margin: 'auto 16px' }}>
        {utils.getLang('smartmessaging.customTemplates.mainTitle')}
      </Typography>
    </div>
    <Divider />
    <CustomTemplateList />
    <CustomTemplateEditor />
  </Fragment>
);

export default CustomTemplateContent;

import React from 'react';
import { connect } from 'react-redux';
import { showAlertDispatcher, closeAlertDispatcher } from 'src/reducers/alert';
import { loaderActions } from 'src/reducers/loader';

import { notify } from 'src/reducers/notify';

const WithAlert = ({ showAlert, closeAlert, setLoader, doNotify, children }) =>
  children({ showAlert, setLoader, closeAlert, doNotify });

const actionCreators = {
  showAlert: showAlertDispatcher,
  closeAlert: closeAlertDispatcher,
  setLoader: visible => loaderActions.setLoader(visible),
  doNotify: notify,
};
const Connected = connect(() => ({}), actionCreators)(WithAlert);

export default Cmp => props => (
  <Connected>
    {({ showAlert, closeAlert, setLoader, doNotify }) => (
      <Cmp
        {...props}
        showAlert={showAlert}
        closeAlert={closeAlert}
        setLoader={setLoader}
        notify={doNotify}
      />
    )}
  </Connected>
);

/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import Select from 'react-select';
import { emphasize } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { Paper, Typography, Chip, TextField, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  input: {
    display: 'flex',
    flex: 1,
    padding: 0,
    height: 'fit-content',
    fontSize: '0.9rem',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'auto',
    maxHeight: '200px',
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: '0.9rem',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: '0.9rem',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    marginTop: theme.spacing(1),
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  err: { background: theme.palette.background.paper },
  selectAll: {
    border: 'none',
    backgroundColor: 'inherit',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline-block',
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    },
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="secondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {utils.getLang('smartmessaging.emptySelectOptions')}
    </Typography>
  );
}

const inputComponent = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;
  const pInputProps = (TextFieldProps && TextFieldProps.InputProps) || {};
  const { InputProps, ...otherTextFieldProps } = TextFieldProps || {};
  return (
    <TextField
      fullWidth
      InputProps={{
        error: !!props.selectProps.error,
        inputComponent,
        inputProps: {
          className: cx(classes.input, props.selectProps.error && classes.err),
          ref: innerRef,
          children,
          ...innerProps,
        },
        ...pInputProps,
      }}
      {...otherTextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      {...props.innerProps}
      ref={props.innerRef}
      selected={props.isFocused}
      disabled={props.data.disabled}
      component="div"
      style={{
        ...(() => props.data.style || {})(),
      }}
      classes={{ selected: props.selectProps.classes.menuItem }}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="secondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      color="primary"
      className={cx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} color="secondary" />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {!!props.selectProps.isMulti && (
        <div style={{ textAlign: 'right' }}>
          <button
            onClick={() => {
              if (props.selectProps.selectAll)
                props.selectProps.onChange(props.selectProps.selectAll(props.selectProps.options));
              else props.selectProps.onChange(props.selectProps.options);
            }}
            className={props.selectProps.classes.selectAll}
          >
            {utils.getLang('smartmessaging.selectInput.selectAll')}
          </button>
        </div>
      )}
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const MuiReactSelect = ({
  options,
  value,
  onChange,
  error,
  isMulti = true,
  mapValue,
  getValue,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <Select
      closeMenuOnSelect={false}
      classes={classes}
      options={options}
      components={components}
      value={mapValue ? mapValue(value, options) : value.map(v => options.find(o => o.value === v))}
      onChange={vals => {
        if (isMulti) onChange(getValue ? getValue(vals) : vals.map(v => v.value));
        else onChange(getValue ? getValue(vals) : vals && vals.value);
      }}
      placeholder={false}
      isMulti={isMulti}
      isClearable
      error={!!error}
      {...rest}
    />
  );
};

MuiReactSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  mapValue: PropTypes.func,
  getValue: PropTypes.func,
};

MuiReactSelect.defaultProps = {
  mapValue: null,
  getValue: null,
};

export default MuiReactSelect;

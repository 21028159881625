import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SMJConfigField from './SMJConfigField';

function SMJConfigFieldList({ descriptors, onFieldChange, onOperatorChange, values }) {
  return (
    <Fragment>
      {Object.values(descriptors).map(d => (
        <SMJConfigField
          descriptor={d}
          value={values[d.key]}
          key={d.key}
          onChange={(value, isMulti) => {
            onFieldChange(d, value, isMulti);
          }}
          validity={values[d.key] && values[d.key].validity}
          onOperatorChange={value => {
            onOperatorChange(d.key, value);
          }}
        />
      ))}
    </Fragment>
  );
}

SMJConfigFieldList.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  descriptors: PropTypes.object.isRequired,
};

export default SMJConfigFieldList;

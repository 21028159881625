import { createReducer } from '@reduxjs/toolkit';
import apiClient from '../utils/apiClient';
import getApiCaller from '../utils/apiClientCaller';

import { loadRequestModels } from './requestModelList';

import { loadRecipes } from './recipeList';

import { enqueueSnackbar } from './notify';

import utils from '../utils/utils';
import { loaderActions } from './loader';

const initialState = {
  campaignListById: {},
  campaignIdList: [], // TODO remove
};

const loadCampaigns = () => async (dispatch, getState) => {
  const { config } = getState().app;
  const cmpList = await getApiCaller(dispatch, getState)(apiClient.loadCampaigns, [
    [
      (config['smartm-private:filter'] && 'privatefilter') || false,
      (config['smartm-planned:filter'] && 'plannedfilter') || false,
    ],
  ]);

  dispatch({
    type: 'RECEIVE_CAMPAIGNS',
    value: cmpList.map(c => ({ ...c, duplicates: JSON.parse(c.duplicates) })),
  });
};

const onDataLoaded = (state, { value }) => ({
  ...state,
  dataLoaded: value,
});

const receiveCampaigns = (state, { value }) => ({
  ...state,
  campaignListById: value.reduce(
    (campaignMapById, campaign) => ({
      ...campaignMapById,
      [campaign.id]: campaign,
    }),
    {}
  ),
});

const removeCampaign = (state, action) => {
  const campaignListById = { ...state.campaignListById };
  delete campaignListById[action.value];
  return {
    ...state,
    campaignListById: {
      ...campaignListById,
    },
  };
};

// TODO : check use and rename(==> updateCampaignView)
const updateCampaignView = (state, action) => ({
  ...state,
  campaignListById: {
    ...state.campaignListById,
    [action.value.id]: action.value,
  },
});

export const activateCampaign = (campaign, callback) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(dispatch, getState);

  const isWritable = await callApi(apiClient.campaignIsWritable, [campaign.id]);
  const asyncFn = async () => {
    setLoader(true);
    let upToDateCampaign;
    const activationResponse = await callApi(apiClient.activateCampaign, [
      campaign.id,
      !campaign.enabled,
    ]);

    if (activationResponse.isSuccess) {
      upToDateCampaign = await callApi(apiClient.getCampaign, [campaign.id]);
      upToDateCampaign.requestModelTypeId = campaign.requestModelTypeId;
      dispatch(
        enqueueSnackbar({
          message: `${upToDateCampaign.name}: ${
            upToDateCampaign.enabled
              ? utils.getLang('smartmessaging.notifications.enabledCampaign')
              : utils.getLang('smartmessaging.notifications.disabledCampaign')
          }`,

          options: {
            variant: 'info',
          },
        })
      );
      if (callback)
        callback(upToDateCampaign, () =>
          dispatch({
            type: 'UPDATE_CAMPAIGN',
            value: { ...upToDateCampaign, duplicates: JSON.parse(upToDateCampaign.duplicates) },
          })
        );
      else
        dispatch({
          type: 'UPDATE_CAMPAIGN',
          value: { ...upToDateCampaign, duplicates: JSON.parse(upToDateCampaign.duplicates) },
        });
    } else {
      dispatch({
        type: 'SHOW_ALERT',
        value: {
          type: 'warning',
          title: utils.getLang('smartmessaging.errorMessages.campaign.notActivable.title'),
          msg: utils.getLang(
            (activationResponse.error &&
              `smartmessaging.errorMessages.campaign.notActivable.${activationResponse.error}`) ||
              'smartmessaging.errorMessages.campaign.notActivable.message'
          ),
        },
      });
    }
  };
  if (isWritable)
    await asyncFn().finally(() => {
      setLoader(false);
    });
  else
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        type: 'warning',
        title: utils.getLang('smartmessaging.campaigns.missingRightAlertTitle'),
        msg: utils.getLang('smartmessaging.campaigns.changeNotAllowed'),
      },
    });
};

export const deleteCampaign = (campaign, callbacks) => async (dispatch, getState) => {
  const callApi = getApiCaller(dispatch, getState);
  const isWritable = await callApi(apiClient.campaignIsWritable, [campaign.id]);
  if (!isWritable)
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        type: 'warning',
        title: utils.getLang('smartmessaging.campaigns.missingRightAlertTitle'),
        msg: utils.getLang('smartmessaging.campaigns.deleteNotAllowed'),
      },
    });
  else
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        type: 'warning',
        title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign.title'),
        msg: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign'),
        async onConfirm() {
          const setLoader = visible => {
            dispatch(loaderActions.setLoader(visible));
          };
          setLoader(true);
          if (campaign.id) {
            if (callbacks && callbacks.beforeDelete) {
              await callbacks.beforeDelete();
            }
            await getApiCaller(dispatch, getState)(apiClient.deleteCampaign, [
              campaign.id,
            ]).finally(() => setLoader(false));
            if (callbacks && callbacks.afterDelete) {
              callbacks.afterDelete(() => {
                dispatch({ type: 'REMOVE_CAMPAIGN', value: campaign.id });
              });
            } else dispatch({ type: 'REMOVE_CAMPAIGN', value: campaign.id });
            dispatch(
              enqueueSnackbar({
                message: `${campaign.name}: ${utils.getLang(
                  'smartmessaging.notifications.deletedCampaign'
                )}`,
                options: {
                  variant: 'warning',
                },
              })
            );
          }
          dispatch({ type: 'CLEAR_EDITOR_DATA' });
          dispatch({ type: 'CLOSE_ALERT' });
        },
        onDismiss() {
          dispatch({ type: 'CLOSE_ALERT' });
        },
      },
    });
};

export const getCsv = campaign => async (dispatch, getState) => {
  const callApi = getApiCaller(dispatch, getState);
  const exportFileId = await callApi(apiClient.csvExport, [campaign.recipeId]);

  if (exportFileId && exportFileId !== -1) {
    utils.downloadPrivateStoredFile(exportFileId, callApi);
  } else {
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        msg: utils.getLang('smartmessaging.noData'),
      },
    });
  }
};

export const getCampaignById = (state, campaignId) =>
  state.campaignList.campaignListById[campaignId];

export const loadInitialDatas = () => async (dispatch, getState) => {
  await loadRequestModels()(dispatch, getState);
  await loadRecipes()(dispatch, getState);
  await loadCampaigns()(dispatch, getState);
};

export default createReducer(initialState, {
  RECEIVE_CAMPAIGNS: receiveCampaigns,
  UPDATE_CAMPAIGN: updateCampaignView,
  REMOVE_CAMPAIGN: removeCampaign,
  ON_DATA_LOADED: onDataLoaded,
});

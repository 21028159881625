import React from 'react';

import HelperCard from 'src/components/common/HelperCard';
import utils from 'src/utils/utils';

const SMJCmpSelectorHelperRender = () => (
  <HelperCard
    title={utils.getLang('smartmessaging.smartjourney.campaignSelector.helperTitle')}
    content={utils.getLang(`smartmessaging.smartjourney.campaignSelector.helper`)}
  />
);

export default SMJCmpSelectorHelperRender;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import utils from 'src/utils/utils';
import SMJModelItem from './SMJModelItem';

const useStyles = makeStyles()(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
    overflow: 'auto',
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    border: theme.border.primary,
    display: 'flex',
  },
  title: {
    color: theme.palette.text.primary,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: theme.palette.text.primary,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const InfosCard = ({ title, content }) => {
  const { classes } = useStyles();
  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
        {title && <div className={classes.title}>{title}</div>}
        {content && <div className={classes.description}>{content}</div>}
      </CardContent>
    </Card>
  );
};

InfosCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any.isRequired,
};

InfosCard.defaultProps = {
  title: null,
};

const PremadeGroupDesc = ({ groupModel, setupPremadeGroup, name, requestModelByRMTypeId }) => (
  <InfosCard
    title={utils.getLang('smartmessaging.campaigns.grouped.createMode.withModel.description.title')}
    content={
      <div>
        <SMJModelItem disabledSelect smjModel={groupModel} />
        <div>
          {utils.getLang(`smartmessaging.campaigns.grouped.gropdescription.availableCampaigns`)}
        </div>
        <ul>
          {groupModel.campaigns.map(c => (
            <li key={c.key}>
              <span style={{ fontWeight: 500 }}>
                {utils.getLang(
                  `smartmessaging.requestmodel.label.${requestModelByRMTypeId[c.type].name}`
                )}
              </span>
              <br />
              {utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${c.key}`)}
            </li>
          ))}
        </ul>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!name}
            onClick={() => {
              setupPremadeGroup();
            }}
          >
            {utils.getLang('smartmessaging.campaigns.grouped.setupPremadeGroup')}
          </Button>
        </div>
      </div>
    }
  />
);

PremadeGroupDesc.propTypes = {
  groupModel: PropTypes.object.isRequired,
  setupPremadeGroup: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

const Content = ({
  mode,
  groupModel,
  name,
  createEmptyGroup,
  setupPremadeGroup,
  requestModelByRMTypeId,
}) => {
  switch (mode) {
    case 'empty':
      return (
        <InfosCard
          classes={null}
          content={
            <div>
              <div style={{ marginBottom: '8px' }}>
                {utils.getLang('smartmessaging.campaigns.grouped.createMode.empty.description')}
              </div>
              <div style={{ textAlign: 'right' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!name}
                  onClick={() => {
                    createEmptyGroup();
                  }}
                >
                  {utils.getLang('smartmessaging.campaigns.grouped.createGroup')}
                </Button>
              </div>
            </div>
          }
        />
      );
    case 'premade':
      return groupModel ? (
        <PremadeGroupDesc
          groupModel={groupModel}
          setupPremadeGroup={setupPremadeGroup}
          name={name}
          requestModelByRMTypeId={requestModelByRMTypeId}
        />
      ) : null;
    default:
      return (
        <InfosCard
          content={utils.getLang('smartmessaging.campaigngroup.creator.selectCreateOption')}
        />
      );
  }
};

Content.propTypes = {
  groupModel: PropTypes.object,
  setupPremadeGroup: PropTypes.func,
  name: PropTypes.string,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  mode: PropTypes.string,
  createEmptyGroup: PropTypes.func.isRequired,
};

Content.defaultProps = {
  mode: null,
  groupModel: null,
  setupPremadeGroup: null,
  name: 'null',
};

const CreatorInfos = ({
  createEmptyGroup,
  setupPremadeGroup,
  newGroupSetup,
  requestModelByRMTypeId,
}) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      padding: '8px 8px 8px 8px',
      flexDirection: 'column',
      overflow: 'hidden',
    }}
  >
    <Content
      groupModel={newGroupSetup.groupModel}
      name={newGroupSetup.name}
      mode={newGroupSetup.mode}
      createEmptyGroup={createEmptyGroup}
      setupPremadeGroup={setupPremadeGroup}
      requestModelByRMTypeId={requestModelByRMTypeId}
    />
  </div>
);

CreatorInfos.propTypes = {
  newGroupSetup: PropTypes.object.isRequired,
  setupPremadeGroup: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  createEmptyGroup: PropTypes.func.isRequired,
};

export default CreatorInfos;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import FilterType from 'src/enums/parameterType';
import OperatorType from 'src/enums/operatorType';

const getUsedFilterLabel = (filter, value) => {
  switch (filter.typeId) {
    case FilterType.INTEGER:
    case FilterType.NUMBER:
    case FilterType.TEXT:
      return value;
    case FilterType.DATE:
      return new Date(value).toLocaleDateString();
    case FilterType.GROUP:
    case FilterType.LINKED_GROUP:
    case FilterType.ENUM:
    case FilterType.ENUM_TEXT:
      return `TYPE${filter.typeId}`;
    default:
      return value;
  }
};

const getSeparator = operatorId => {
  switch (operatorId) {
    case OperatorType.BETWEEN:
    case OperatorType.NOT_BETWEEN:
      return ' et ';
    default:
      return ', ';
  }
};

const DefaultValueRender = ({ filter }) => (
  <Typography
    color="textSecondary"
    component="span"
    style={{
      display: 'inline',
      fontSize: '0.815rem',
    }}
  >
    {filter.values.map(v => getUsedFilterLabel(filter, v)).join(getSeparator(filter.operatorId))}
  </Typography>
);

DefaultValueRender.propTypes = { filter: PropTypes.object.isRequired };

export default DefaultValueRender;

import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';

import AccountCircle from '@mui/icons-material/AccountCircle';
import { Drawer, Divider, Theme } from '@mui/material';
import AppInfos from '../common/AppInfos';

import LangButton from '../common/LangButton';
import MenuSelect from './MenuSelect';
import { loadLanguage } from 'src/reducers/app';
import utils from 'src/utils/utils';

const useStyles = makeStyles()((theme: Theme) => ({
  drawerPaper: {
    position: 'relative',
    background: theme.palette.grey[50],
    display: 'flex',
  },

  logo: {
    height: '50px',
    margin: theme.spacing(1),
  },
}));

interface UserData {
  familyName: string;
  givenName: string;
}

// TODO import Language from types
interface Language {
  country: string;
  id: number;
  locale: string;
  name: string;
  shortName: string;
}

interface ChangeLanguage {
  // eslint-disable-next-line no-unused-vars
  (_locale: string, _callback: () => void): void;
}

interface MainMenuProps {
  userData: UserData;
  availableLanguages: Language[];
  currentLanguage: Language;
  changeLanguage: ChangeLanguage;
  rerender: () => void;
}

const MainMenu = ({
  userData,
  availableLanguages,
  currentLanguage,
  changeLanguage,
  rerender,
}: MainMenuProps) => {
  const theme: Theme = useTheme();
  const { classes } = useStyles();
  return (
    <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
      <div>
        <img src="/resources/img/logo.png" className={classes.logo} alt="logo" />
      </div>
      <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
        <MenuSelect />
        <Divider />
      </div>
      <AppInfos />
      <div
        style={{
          display: 'flex',
          maxWidth: '100%',
          color: 'primary.main',
          padding: '4px 0px 4px 8px',
          justifyContent: 'center',
          // TODO add border to theme TS
          outline: '1px solid #bdbdbd',
          overflow: 'hidden',
          wordBreak: 'break-word',
        }}
      >
        <div
          style={{
            color: theme.palette.primary.main,
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <AccountCircle />
        </div>
        <div
          style={{
            padding: '0 4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textOverflow: 'ellipsis',
          }}
        >
          {`${userData.familyName} ${userData.givenName}`}
        </div>
        <div
          style={{
            flex: 1,
            textAlign: 'right',
          }}
        >
          <LangButton
            title={utils.getLang('smartmessaging.tooltip.mainLangButton')}
            langs={availableLanguages}
            currentLang={currentLanguage}
            selectLang={(lang: string) => {
              changeLanguage(lang, rerender);
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

interface StateToProps {
  userData: UserData;
  currentLanguage: Language;
  availableLanguages: Language[];
}

const mapStateToProps = (state): StateToProps => ({
  userData: state.auth.userData,
  currentLanguage: state.app.currentLanguage,
  availableLanguages: state.app.availableLanguages,
});

interface Actions {
  changeLanguage: ChangeLanguage;
}

const actionCreators: Actions = {
  changeLanguage: loadLanguage,
};

export default connect(mapStateToProps, actionCreators)(MainMenu);

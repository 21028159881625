import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

import utils from 'src/utils/utils';

const useStyles = makeStyles()((theme, _params, classes) => ({
  active: { color: theme.palette.primary.main },
  root: {
    color: theme.palette.text.primary,
    [`&.${classes.active}`]: {
      color: theme.palette.primary.main,
      '& svg path': { color: theme.palette.primary.main },
    },
  },
  cell: {
    padding: '4px',
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    color: theme.palette.text.secondary,
  },
}));

const HistoryListHead = ({ columns, sortConfig, sort, sortable }) => {
  const { classes } = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell key={column} className={classes.cell}>
            {sortable.indexOf(column) !== -1 ? (
              <TableSortLabel
                classes={{ root: classes.root, active: classes.active }}
                active={sortConfig.orderBy === column}
                direction={sortConfig.order}
                onClick={() => {
                  sort(column);
                }}
              >
                {utils.getLang(`smartmessaging.history.column.${column}`)}
              </TableSortLabel>
            ) : (
              utils.getLang(`smartmessaging.history.column.${column}`)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

HistoryListHead.propTypes = {
  sort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  sortable: PropTypes.array.isRequired,
};

export default HistoryListHead;

import React from 'react';
import PropTypes from 'prop-types';
import HistoryPage from 'src/components/common/history/HistoryPage';
import withDataResolver from 'src/components/common/withDataResolver';
import apiClient from 'src/utils/apiClient';
import UsedFilter from './UsedFilter';

const mapFiltersByKey = criteriaList =>
  criteriaList.reduce(
    (currentResult, criteria) => ({ ...currentResult, [criteria.key]: criteria }),
    {}
  );

const History = ({ data, ...props }) => (
  <HistoryPage
    {...props}
    filtersByKey={data}
    excludedProperties={['launchedBy', 'status', 'targetWithOptin', 'targetTotal', 'isTest']}
    rowSpanCols={['clubName', 'date', 'targetNumber', 'optinType']}
    sortable={['clubName', 'date', 'targetNumber', 'optinType', 'sentMessageNumber']}
    components={{ UsedFilter }}
  />
);

const resolvers = {
  resolve: props => async callApi => {
    const filters = await callApi(apiClient.loadRecipeParameters, [props.rmId]);
    return mapFiltersByKey(filters);
  },
  onResolved: () => async () => {},
  loader: false,
};

History.propTypes = { data: PropTypes.object.isRequired };

export default withDataResolver(resolvers)(History);

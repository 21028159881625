import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import Utils from 'src/utils/utils';
import CKEditor from 'src/components/common/ReactCKEditor';
import NotifyContentLabel from 'src/components/common/contentEditor/NotifyContentLabel';
import { checkContentValidity } from 'src/reducers/campaignEditor';
import CtnLang from './CtnLang';

const ConnectedNotifyLabel = connect(
  state => ({
    contentValidity: checkContentValidity(state),
  }),
  () => ({})
)(NotifyContentLabel);

const NotifyEditorRender = ({
  currentAction,
  fields,
  onNotifyObjectChange,
  onNotifyContentChange,
  onNotifyEditorLoaded,
  nodeRef,
  rendered,
  isWritable,
}) => (
  <div style={{ height: '85%' }} ref={nodeRef}>
    {rendered && (
      <div style={{ width: '100%', display: 'flex' }}>
        <Typography color="textSecondary" style={{ margin: '5px 10px' }} variant="subtitle2" noWrap>
          {Utils.getLang('smartmessaging.contentEditor.notify.object')}
        </Typography>
        <div style={{ marginRight: 0, marginLeft: 'auto' }}>
          <CtnLang />
        </div>
      </div>
    )}
    {rendered && (
      <CKEditor
        name="objectEditor"
        config={{
          readOnly:
            !!currentAction.content.object && currentAction.messageModel
              ? !isWritable || !currentAction.messageModel.generic
              : !isWritable ||
                (currentAction.customTemplate && !currentAction.customTemplate.generic),
          fields: fields.fields.objectFields,
          removeButtons:
            'Underline,Subscript,Superscript,' +
            'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
            'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
            'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
            'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
            'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
            'BidiLtr,BidiRtl,CreatePlaceholder,' +
            'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
          extraPlugins: 'strinsert',
          removePlugins: 'colorbutton,elementspath',
          autoParagraph: false,
          enterMode: 2,
          height: 10 * (nodeRef.current.clientHeight / 100),
        }}
        events={{
          change: event => {
            onNotifyObjectChange(event.editor.getData());
          },
        }}
        content={currentAction.content.object}
      />
    )}
    {rendered && <ConnectedNotifyLabel />}
    {rendered && (
      <CKEditor
        name="htmlEditor"
        config={{
          readOnly: currentAction.messageModel
            ? !isWritable || !currentAction.messageModel.generic
            : !isWritable ||
              (currentAction.customTemplate && !currentAction.customTemplate.generic),
          fields: fields.fields.contentFields,
          removeButtons:
            'Underline,Subscript,Superscript,Anchor,Image,Flash,Table,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
          extraPlugins: 'strinsert,uploadimage',
          removePlugins: 'elementspath',
          autoParagraph: false,
          enterMode: 3,
          height: 70 * (nodeRef.current.clientHeight / 100),
        }}
        events={{
          change: event => {
            onNotifyContentChange(event.editor.getData());
          },
          loaded: ({ editor }) => {
            onNotifyEditorLoaded(editor);
          },
        }}
        content={currentAction.content.content || ''}
      />
    )}
  </div>
);

NotifyEditorRender.propTypes = {
  currentAction: PropTypes.object,
  fields: PropTypes.any.isRequired,
  onNotifyObjectChange: PropTypes.func.isRequired,
  onNotifyContentChange: PropTypes.func.isRequired,
  onNotifyEditorLoaded: PropTypes.func.isRequired,
  nodeRef: PropTypes.object.isRequired,
  rendered: PropTypes.bool.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

NotifyEditorRender.defaultProps = {
  currentAction: null,
};
/** Used to render ckeditors after containers are rendered for ckeditors height
 *  cause it's value is not state bound (to avoid rerenders causing ckeditors layout issues)
 */
class NotifyEditorWithRef extends Component {
  constructor(props) {
    super(props);
    this.myNodeRef = React.createRef();
  }

  componentDidMount() {
    CKEditor.onCKEditorDialogDefintion();
    this.props.setRendered(true);
  }

  render() {
    return <NotifyEditorRender {...this.props} nodeRef={this.myNodeRef} />;
  }
}

NotifyEditorWithRef.propTypes = {
  setRendered: PropTypes.func.isRequired,
  rendered: PropTypes.bool.isRequired,
  currentAction: PropTypes.object,
  fields: PropTypes.object,
};

NotifyEditorWithRef.defaultProps = {
  currentAction: null,
  fields: { fields: {} },
};

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
});

const actionCreators = {
  onNotifyObjectChange: data => ({ type: 'ON_NOTIFY_OBJECT_CHANGE', value: data }),
  onNotifyContentChange: data => ({ type: 'ON_NOTIFY_CONTENT_CHANGE', value: data }),
  onNotifyEditorLoaded: editor => ({ type: 'ON_NOTIFY_EDITOR_LOADED', value: editor }),
};

export default withState(
  'rendered',
  'setRendered',
  () => false
)(connect(mapStateToProps, actionCreators)(NotifyEditorWithRef));

import React from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import OperatorType from 'src/enums/operatorType';
import Utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  operatorSelectInput: { color: theme.palette.text.secondary, fontSize: '0.975rem' },
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: theme.palette.text.secondary,
    outline: 'none',
    cursor: 'pointer',
    fontSize: '0.975rem',
    padding: '8px',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
      color: theme.palette.primary.contrastText,
    },
  },
}));

const OperatorSelectRender = ({ value, allowedOperators, onChange, disabled }) => {
  const { classes } = useStyles();
  return (
    <Select
      MenuProps={{
        MenuListProps: { style: { padding: 0, margin: 0 } },
        PopoverClasses: {
          paper: classes.popover,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        marginThreshold: 0,
      }}
      input={
        <Input
          inputProps={{
            'aria-label': 'Description',
            className: classes.operatorSelectInput,
          }}
        />
      }
      disabled={disabled}
      onChange={event => {
        event.preventDefault();
        onChange(event.target.value);
      }}
      value={value || 0}
    >
      {allowedOperators.map(op => (
        <div key={op} value={OperatorType[op]} className={classes.menuItem}>
          {Utils.getLang(`smartmessaging.recipeSetupOperator.${op}`)}
        </div>
      ))}
    </Select>
  );
};

OperatorSelectRender.propTypes = {
  allowedOperators: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number,
};
OperatorSelectRender.defaultProps = {
  value: null,
  disabled: false,
};

export default OperatorSelectRender;

export default {
  32: 32,
  33: 33,
  34: 34,
  35: 35,
  36: 36,
  37: 37,
  58: 58,
  59: 59,
  60: 60,
  61: 61,
  62: 62,
  63: 63,
  64: 64,
  109: 109,
  110: 110,
  111: 111,
  112: 112,
  113: 113,
  114: 114,
  115: 115,
  116: 116,
  160: 160,
  161: 161,
  2000: 2000,
  2001: 2001,
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from 'tss-react/mui';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import QRCode from 'qrcode.react';
import getApiCaller from 'src/utils/apiClientCaller';
import apiClient from 'src/utils/apiClient';
import NotFound from './common/NotFound';
import Loader from './common/Loader';

function getUnixTimestamp() {
  return Math.floor(Date.now() / 1000);
}
interface Identificator {
  id: number;
  number: number;
}
interface InvitationQRCodeProps {
  identificator: Identificator;
}

const InvitationQRCode = ({ identificator }: InvitationQRCodeProps) => {
  const { classes } = makeStyles()({
    root: {
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto',
      display: 'flex',
      height: '100vh',
    },
  })();

  const [timeStamp, setTimeStamp] = useState(getUnixTimestamp());
  const identificatorId = identificator.id;
  const identificatorNumber = identificator.number;
  const version = 'QR1';

  const codeToHash = `${version}+${timeStamp}+${identificatorId}`;

  const signature = identificatorNumber
    ? HmacSHA256(codeToHash, identificatorNumber.toString())
        .toString()
        .slice(0, 6)
    : '';

  const codeJSON = JSON.stringify({
    id: identificatorId,
    sg: signature,
    t: timeStamp,
    v: version,
  });

  const codeWidth = (window.innerWidth * 40) / 100;

  useEffect(() => {
    const i = setInterval(() => setTimeStamp(getUnixTimestamp()), 30000);
    return () => clearInterval(i);
  }, []);

  return (
    <div className={classes.root}>
      <QRCode size={codeWidth} value={codeJSON} />
    </div>
  );
};

interface IdentificatorLoaderProps {
  doGetApiCaller: Function;
  match: { params: { clientToken: string; identificatorId: number; s: string } };
}

const IdentificatorLoader = ({
  doGetApiCaller,
  match: {
    params: { clientToken, identificatorId, s },
  },
}: IdentificatorLoaderProps) => {
  const callApi = doGetApiCaller();
  const { data: identificator, status, error } = useQuery<Identificator, Error>({
    queryKey: ['identificators', clientToken, identificatorId, s],
    queryFn: (): Promise<Identificator> =>
      callApi(apiClient.getIdentificator, [clientToken, identificatorId, s], e =>
        Promise.reject(e)
      ),
  });

  switch (status) {
    case 'success':
      return <InvitationQRCode identificator={identificator} />;
    case 'error':
      return <NotFound text={error.message} />;
    default:
      return <Loader wrapperClass="ghostWrapperClass" />;
  }
};

const actionCreators = {
  doGetApiCaller: () => getApiCaller,
};

export default connect(null, actionCreators)(IdentificatorLoader);

import { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

class Notifier extends Component {
  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (!notExists)
        notExists = !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }
  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return;
      // Display snackbar using notistack
      enqueueSnackbar(notification.message, notification.options);
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key);
    });
  }
  displayed = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  render() {
    return null;
  }
}

const defaultProps = {
  notifications: [],
};

const propTypes = {
  notifications: PropTypes.array,
  removeSnackbar: PropTypes.func.isRequired,
};

Notifier.propTypes = propTypes;
Notifier.defaultProps = defaultProps;

const mapStateToProps = state => ({
  notifications: state.notify.notifications,
});

const actionCreators = {
  removeSnackbar: key => ({ type: 'REMOVE_SNACKBAR', key }),
};

export default connect(mapStateToProps, actionCreators)(Notifier);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Card, CardContent, CardHeader, FormControl, FormLabel } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  title: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[100],
    padding: '0 4px',
    borderRadius: '0 0 4px 0',
  },
  cardContent: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: 0, display: 'inline-block' },
}));

const GroupCriteriaBloc = ({ title, showTitle, children }) => {
  const { classes } = useStyles();
  return (
    <Card style={{ overflow: 'visible', border: '1px solid #f1f1f1' }} elevation={0}>
      <FormControl style={{ display: 'block' }}>
        {showTitle && (
          <CardHeader
            className={classes.cardHeader}
            classes={{
              title: classes.title,
              action: classes.action,
              root: classes.cardHeaderTitleRoot,
            }}
            title={<FormLabel style={{ fontSize: '0.875rem' }}>{title}</FormLabel>}
          />
        )}
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </FormControl>
    </Card>
  );
};

GroupCriteriaBloc.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  showTitle: PropTypes.bool.isRequired,
};

export default GroupCriteriaBloc;

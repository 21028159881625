import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { TableRow, TableCell, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import hUtils from './utils';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  cell: { padding: '4px', color: theme.palette.text.secondary },
  row: {
    '&:hover': {
      '& .hovershow': { opacity: 0.8 },
      cursor: 'pointer',
    },
  },
  hovershow: { opacity: 0 },
}));

const HistoryItem = ({
  goToDetails,
  item,
  columnNames,
  rowSpanCols,
  rowSpan,
  specificRender,
  addGoToDetailColum,
}) => {
  const { classes } = useStyles();
  return (
    <TableRow
      className={classes.row}
      onDoubleClick={e => {
        e.preventDefault();
        goToDetails();
      }}
    >
      {columnNames.map(column => (
        <TableCell
          key={uuid()}
          className={classes.cell}
          rowSpan={rowSpanCols.indexOf(column) === -1 ? 1 : rowSpan}
        >
          {specificRender[column]
            ? specificRender[column](column, item)
            : hUtils.renderCell(column, item)}
        </TableCell>
      ))}
      {addGoToDetailColum && (
        <TableCell className={classes.row} rowSpan={rowSpan}>
          <Tooltip title={utils.getLang('smartmessaging.history.showDetails')}>
            <IconButton
              style={{ padding: '4px' }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                goToDetails();
              }}
              size="large"
            >
              <ArrowRight />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

HistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  goToDetails: PropTypes.func.isRequired,
  columnNames: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array,
  specificRender: PropTypes.object,
  rowSpan: PropTypes.number,
  addGoToDetailColum: PropTypes.bool,
};

HistoryItem.defaultProps = {
  rowSpanCols: [],
  specificRender: {},
  addGoToDetailColum: false,
  rowSpan: 1,
};

export default HistoryItem;

import React from 'react';
import { withState } from 'recompose';
import { IconButton, InputAdornment, FormControl, FormLabel } from '@mui/material';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

import PropTypes from 'prop-types';
import MuiReactSelect from 'src/components/common/MuiReactSelect';
import OperatorSelect from 'src/components/common/OperatorSelect';
import apiClient from 'src/utils/apiClient';
import { getBeanTypeById } from 'src/enums/beantype';
import withDataResolver from 'src/components/common/withDataResolver';

import utils from 'src/utils/utils';

import ValidityAdornment from './ValidityAdornment';
import parameterKey from 'src/enums/parameterKey';

const RecipeSelectFieldRender = ({
  dataList,
  fieldConfig,
  onChange,
  removeLine,
  removable,
  onOperatorChange,
  classes,
  validity,
  disabled,
  mapValue,
  getValue,
  selectAll,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div
      className={classes.fieldContainer}
      style={{ marginTop: '4px', marginBottom: 0, alignItems: 'center' }}
    >
      <ValidityAdornment validity={validity} required={fieldConfig.model.mandatory} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.recipeFieldName.${fieldConfig.model.key}`)}
      </FormLabel>
      <div
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <OperatorSelect fieldConfig={fieldConfig} onChange={onOperatorChange} disabled={disabled} />
      </div>
      <div
        style={{
          fontSize: '0.875rem',
          flex: 1,
        }}
      >
        <MuiReactSelect
          selectAll={selectAll}
          mapValue={mapValue}
          getValue={getValue}
          isDisabled={disabled}
          onChange={value => {
            onChange(fieldConfig, value);
          }}
          value={(fieldConfig.value && fieldConfig.value.values) || []}
          options={dataList}
        />
      </div>
      {removable && (
        <InputAdornment position="start">
          <IconButton
            aria-label="clear"
            onClick={e => {
              e.preventDefault();
              removeLine(fieldConfig);
            }}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      )}
    </div>
  </FormControl>
);

const resolvers = {
  resolve: props => async callApi => {
    if (props.data)
      return props.data.map(dataItem => ({
        value: dataItem.id,
        label: dataItem.name,
      }));
    let rez;
    let getLabel;
    if (props.fieldConfig.model.type === 'CLUBS') {
      const promises = [];
      props.fieldConfig.model.descriptors.forEach(fkdesc => {
        promises.push(
          callApi(apiClient.getDataList, [fkdesc]).then(list => ({
            label: utils.getLang(
              `smartmessaging.beanGrouptitle.${getBeanTypeById(fkdesc.beantypeId).key}`
            ),
            options: list.map(listItem => {
              // eslint-disable-next-line prefer-destructuring
              getLabel = getBeanTypeById(fkdesc.beantypeId).getLabel;
              return {
                ...listItem,
                key: fkdesc.key,
                beantypeId: fkdesc.beantypeId,
                value: JSON.stringify({
                  beantypeId: fkdesc.beantypeId,
                  key: fkdesc.key,
                  id: listItem.id,
                }),
                label: getLabel(listItem),
              };
            }),
          }))
        );
      });

      rez = await Promise.all(promises);
      return rez;
    }
    if (props.fieldConfig.model.beantypeId) {
      const eBeantype = getBeanTypeById(props.fieldConfig.model.beantypeId);
      // eslint-disable-next-line prefer-destructuring
      getLabel = eBeantype.getLabel;
    } else {
      getLabel = ({ name }) => name;
    }

    let doFilter = i => i;
    let valueKey;
    switch (props.fieldConfig.model.key) {
      case parameterKey.CIVILITY: {
        valueKey = 'key';
        getLabel = item => utils.getCivilityLabel(item, props.currentAppLn.locale);
        const clubCountryISO = sessionStorage.getItem('club.addressCountryISO');
        doFilter = i => {
          if (!i.countryIsos) return true;
          if (i.countryIsos.indexOf(clubCountryISO) !== -1) return true;
          // si la valeur est présentedans les values sélectionnées, on la garde
          if (props.fieldConfig.value && props.fieldConfig.value.values) {
            let keepItem = false;
            props.fieldConfig.value.values.forEach(v => {
              if (!keepItem) keepItem = i.key === v;
            });
            return keepItem;
          }
          return false;
        };
        break;
      }
      default:
        valueKey = 'id';
    }

    rez = await callApi(apiClient.getDataList, [props.fieldConfig.model]);
    return rez.filter(doFilter).map(dataItem => ({
      value: dataItem[valueKey],
      label: getLabel(dataItem),
    }));
  },
  onResolved: props => async resolvedData => {
    props.setDataList(resolvedData);
  },
};

RecipeSelectFieldRender.propTypes = {
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  validity: PropTypes.object.isRequired,
  mapValue: PropTypes.func,
  getValue: PropTypes.func,
  selectAll: PropTypes.func,
};

RecipeSelectFieldRender.defaultProps = {
  mapValue: null,
  getValue: null,
  selectAll: null,
};

const WithData = withDataResolver(resolvers, {
  loaderWrapper: {
    class: 'ghostWrapperClass',
    style: { position: 'relative', height: '96px' },
  },
})(RecipeSelectFieldRender);
const mapStateToProps = state => ({
  currentAppLn: state.app.currentLanguage,
});

export default withState('dataList', 'setDataList', () => [])(
  connect(mapStateToProps, null)(WithData)
);

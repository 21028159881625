import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';

import DownloadIcon from '@mui/icons-material/VerticalAlignBottom';

import utils from 'src/utils/utils';
import { massActionCsvExport } from 'src/reducers/massAction';

const CsvExportButton = ({ doExport }) => (
  <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.csvExport')}>
    <IconButton
      aria-label="clear"
      onClick={e => {
        e.preventDefault();
        doExport();
      }}
      color="secondary"
      size="large"
    >
      <DownloadIcon />
    </IconButton>
  </Tooltip>
);

CsvExportButton.propTypes = {
  doExport: PropTypes.func.isRequired,
};

const actionCreators = { doExport: massActionCsvExport };

export default connect(null, actionCreators)(CsvExportButton);

import { useLayoutEffect } from 'react';

type DidMountFunction = () => void;

const useDidMount = (fn: DidMountFunction) =>
  useLayoutEffect(() => {
    fn();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

export default useDidMount;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  expansionPanelDetail: { display: 'block' },
  expansionSummary: {
    borderBottom: theme.border.primary,
  },
  expandedSummary: {
    backgroundColor: theme.palette.grey[100],
  },

  summary: { color: theme.palette.text.secondary },
  summaryTypo: { color: theme.palette.text.secondary },
}));

const AccordionItem = ({ expanded, title, onChange, children }) => {
  const { classes } = useStyles();
  return (
    <Accordion elevation={0} expanded={expanded} onChange={onChange}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<ExpandMoreIcon className={classes.summary} />}
        classes={{ expanded: classes.expandedSummary }}
      >
        <Typography className={classes.summaryTypo}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionPanelDetail}>{children}</AccordionDetails>
    </Accordion>
  );
};

AccordionItem.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default AccordionItem;

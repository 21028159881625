import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  Card,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Fade,
  Button,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { saveMassAction, checkEmptyFieldSelection } from 'src/reducers/massAction';
import utils from 'src/utils/utils';

import Selector from 'src/components/common/Selector';

import { loaderActions } from 'src/reducers/loader';

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'relative',
    textAlign: 'left',
  },
  primary: { color: theme.palette.primary.contrastText },
  flex: { flex: 1 },
  root: {},
}));

const AppBarRender = ({ actionName, save, isWritable, currentMassActionId }) => {
  const { classes } = useStyles();
  return (
    <AppBar className={classes.appBar} color="primary">
      <Toolbar style={{ paddingLeft: '8px' }}>
        <div style={{ flex: 1 }}>
          <Typography variant="h5" color="inherit" className={classes.flex}>
            {utils.getLang(`smartmessaging.massAction.columnsSelection.title`)}
          </Typography>
          <Typography variant="body2" color="inherit">
            {actionName}
            {!!currentMassActionId && ` (${currentMassActionId})`}
          </Typography>
        </div>
        {isWritable && (
          <div
            style={{
              boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
              marginLeft: '8px',
              marginRight: '8px',
              padding: '0 8px',
            }}
          >
            <Tooltip title={utils.getLang('smartmessaging.massAction.save')}>
              <IconButton
                aria-label="clear"
                className={classes.primary}
                onClick={e => {
                  e.preventDefault();
                  save();
                }}
                size="large"
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

AppBarRender.propTypes = {
  actionName: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  currentMassActionId: PropTypes.number,
};

AppBarRender.defaultProps = { currentMassActionId: null };

function ColumnsSelector({
  availableResultfields = {},
  selection = {},
  setGroupSelection,
  goToStep,
  doSaveMassAction,
  actionName,
  setLoader,
  addResultFields,
  removeResultFields,
  isWritable,
  classes,
  currentMassActionId,
  doCheckEmptyFieldSelection,
}) {
  const getAndDisplayResult = useCallback(() => {
    setLoader(true);
    doSaveMassAction()
      .then(() => {
        setLoader(false);
        goToStep(3);
      })
      .finally(() => setLoader(false));
  }, [doSaveMassAction, setLoader, goToStep]);

  const save = useCallback(() => {
    setLoader(true);
    doSaveMassAction()
      .then(() => {
        setLoader(false);
      })
      .finally(() => setLoader(false));
  }, [doSaveMassAction, setLoader]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <AppBarRender
        goToStep={goToStep}
        getAndDisplayResult={getAndDisplayResult}
        actionName={actionName}
        currentMassActionId={currentMassActionId}
        save={save}
        classes={classes}
        isWritable={isWritable}
      />
      <Fade in timeout={500}>
        <Card
          elevation={0}
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {Object.keys(availableResultfields).map(
            group =>
              (availableResultfields[group].length && (
                <div
                  key={group}
                  style={{
                    overflow: 'auto',
                    margin: '4px 12px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Selector
                    disabled={!isWritable}
                    selectable={availableResultfields[group]}
                    selection={selection[group] || []}
                    select={fields => addResultFields(group, fields)}
                    unselect={fields => removeResultFields(group, fields)}
                    selectAll={() => setGroupSelection(group, availableResultfields[group])}
                    clearAll={() => setGroupSelection(group, [])}
                    title={utils.getLang(
                      `smartmessaging.massAction.columnSelector.columnGroupName.${group}`
                    )}
                  />
                </div>
              )) ||
              null
          )}
        </Card>
      </Fade>
      <div style={{ textAlign: 'right', margin: '8px' }}>
        <Button
          variant="text"
          color="primary"
          style={{ margin: '2px' }}
          onClick={e => {
            e.preventDefault();
            goToStep(1);
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '2px' }}
          onClick={e => {
            e.preventDefault();
            doCheckEmptyFieldSelection(getAndDisplayResult);
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.next')}
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  availableResultfields: state.massAction.availableResultfields,
  selection: state.massAction.selectedResultfields,
  actionName: state.massAction.currentName,
  currentMassActionId: state.massAction.currentMassActionId,
});

const actionCreators = {
  doCheckEmptyFieldSelection: checkEmptyFieldSelection,
  doSaveMassAction: saveMassAction,
  setGroupSelection: (group, fields) => ({
    type: 'SET_GROUP_RESULTFIELD_SELECTION',
    value: { group, fields },
  }),
  addResultFields: (group, fields) => ({
    type: 'ADD_RESULTFIELD_SELECTION',
    value: { group, fields },
  }),
  removeResultFields: (group, fields) => ({
    type: 'REMOVE_RESULTFIELD_SELECTION',
    value: { group, fields },
  }),
  updateActionName: name => ({ type: 'UPDATE_ACTION_NAME', value: name }),
  setLoader: visible => loaderActions.setLoader(visible),
};

ColumnsSelector.propTypes = {
  availableResultfields: PropTypes.object.isRequired,
  selection: PropTypes.object,
  goToStep: PropTypes.func.isRequired,
  setGroupSelection: PropTypes.func.isRequired,
  doSaveMassAction: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  currentMassActionId: PropTypes.number,
  setLoader: PropTypes.func.isRequired,
  addResultFields: PropTypes.func.isRequired,
  removeResultFields: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
  doCheckEmptyFieldSelection: PropTypes.func.isRequired,
};

ColumnsSelector.defaultProps = {
  selection: {},
  currentMassActionId: null,
};

export default connect(mapStateToProps, actionCreators)(ColumnsSelector);

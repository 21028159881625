import React from 'react';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import apiClient from 'src/utils/apiClient';
import Loader from 'src/components/common/Loader';

import withDataResolver from 'src/components/common/withDataResolver';

const resolvers = {
  resolve: props => async callApi => {
    const data = await callApi(smartjourneyApi.loadSMJCampaigns, [props.smartjourney.id]);
    const recipes = await callApi(apiClient.loadRecipes);
    props.receiveRecipes(recipes);

    const result = data.map(cmp => {
      const r = recipes.filter(pr => pr.id === cmp.recipeId)[0];
      const rmTypeId = r.requestModelTypeId;
      return { ...cmp, duplicates: JSON.parse(cmp.duplicates), requestModelTypeId: rmTypeId };
    });
    return result;
  },
  loader: <Loader />,
  onResolved: () => async () => {},
};

const WithData = ({ children, ...rest }) => React.cloneElement(children, { ...rest });

export default withDataResolver(resolvers)(WithData);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { CardContent, Card, Typography, CardMedia } from '@mui/material';

import FileUploaderBtn from 'src/components/common/FileUploaderBtn';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  card: {
    width: '200px',
    display: 'inline-block',
    margin: '10px',
  },
  cardContent: {
    height: '3em',
    wordBreak: 'break-word',
    wordWrap: 'normal',
    color: theme.palette.primary.main,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  media: {
    height: 200,
  },
}));

const MassActionMsgImportTplItem = ({ onFileLoaded }) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {utils.getLang('smartmessaging.contentEditor.uploadTemplate')}
      </CardContent>
      <CardMedia className={classes.media} src="#">
        <FileUploaderBtn
          selectFileLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.chooseFile')}
          sendFileLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.sendFile')}
          cancelLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.cancel')}
          displayCmp={file => (
            <Typography
              style={{
                wordBreak: 'break-word',
                wordWrap: 'normal',
                padding: '8px',
              }}
            >
              {utils.getLang('smartmessaging.contentEditor.customTemplate.fileToUpload')}
              <br />
              {file.name}
            </Typography>
          )}
          flex={false}
          onFileChange={(e, pFile) => {
            if (!e && !pFile) {
              return false;
            }
            const file = pFile || e.target.files[0];
            if (!file) {
              return false;
            }
            return true;
          }}
          onSend={file => {
            const reader = new FileReader();
            reader.onload = () => onFileLoaded(reader.result);
            reader.readAsText(file);
          }}
        />
      </CardMedia>
    </Card>
  );
};

MassActionMsgImportTplItem.propTypes = {
  onFileLoaded: PropTypes.func.isRequired,
};

export default MassActionMsgImportTplItem;

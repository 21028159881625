import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from '@mui/material';
import utils from 'src/utils/utils';

const mapStateToProps = state => ({
  alertCfg: state.alert.cfg,
});

const actionCreators = {
  closeAlert: () => ({ type: 'CLOSE_ALERT' }),
};

const defaultProps = {
  alertCfg: null,
};

const useStyles = makeStyles()(theme => ({
  alertBtn: { margin: '0px' },
  alertCancelBtn: { margin: '0px', color: theme.palette.text.primary },
  title: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    color: theme.palette.text.primary,
  },
  content: { padding: theme.spacing(2) },
  warningTitle: {
    background: theme.palette.warning.light,
  },
  actionsRoot: {
    margin: `${theme.spacing(0.5)} 0px ${theme.spacing(0.5)} 0px`,
    padding: `0px`,
  },
  mainDialog: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)} 0px ${theme.spacing(0.5)}`,
  },
}));

const Alert = ({ alertCfg, closeAlert }) => {
  const { classes } = useStyles();
  return (
    alertCfg && (
      <Dialog open onClose={closeAlert} classes={{ paper: classes.mainDialog }} scroll="paper">
        <DialogTitle
          classes={{ root: classes.title }}
          className={`${alertCfg.type ? ` ${classes[`${alertCfg.type}Title`]}` : ''}`}
        >
          {alertCfg.title || 'Informations'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={`${classes.content}`}
            dangerouslySetInnerHTML={{ __html: alertCfg.msg }}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.actionsRoot }}>
          {alertCfg.onDismiss && (
            <Button
              onClick={e => {
                e.preventDefault();
                alertCfg.onDismiss();
              }}
              classes={{
                root: classes.alertCancelBtn,
              }}
              variant="text"
            >
              {utils.getLang('smartmessaging.alerts.dismiss')}
            </Button>
          )}
          {alertCfg.onConfirm && (
            <Button
              classes={{
                root: classes.alertBtn,
              }}
              onClick={e => {
                e.preventDefault();
                alertCfg.onConfirm();
              }}
              variant="text"
              color="primary"
            >
              {utils.getLang('smartmessaging.alerts.confirm')}
            </Button>
          )}
          {!alertCfg.onDismiss && !alertCfg.onConfirm && (
            <Button
              onClick={e => {
                e.preventDefault();
                closeAlert();
              }}
              variant="text"
              color="primary"
            >
              {utils.getLang('smartmessaging.alerts.done')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  );
};

Alert.propTypes = {
  alertCfg: PropTypes.object,
  closeAlert: PropTypes.func.isRequired,
};
Alert.defaultProps = defaultProps;

export default connect(mapStateToProps, actionCreators)(Alert);

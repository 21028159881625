import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { Table, Typography } from '@mui/material';
import Search from 'src/components/common/filters/Search';
import SMJListTableBody from './SMJListTableBody';

import Headers from './Headers';

import filtrable from 'src/components/common/hocs/filtrable';

import columnConfig from './columnsConfig';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
}));

const SMJItemList = ({ filteredData, editSmj, smjModelById, deleteSmj }) => {
  const [sortConfig, setSortConfig] = useState({
    orderBy: 'creationDate',
    order: 'desc',
  });
  const [quickFilter, setQuickFilter] = useState('');

  const { columnNames, columnsSorters } = columnConfig;

  const filteredColumNames = columnNames.filter(
    n => utils.isNodeMapped() || ['nodename'].indexOf(n) === -1
  );

  const updateQuickFilter = useCallback(filterValue => {
    setQuickFilter(filterValue);
  }, []);

  const doSort = useCallback(
    column => {
      let order = 'asc';
      if (sortConfig.orderBy === column) {
        order = sortConfig.order === 'asc' ? 'desc' : 'asc';
      }
      setSortConfig({ order, orderBy: column });
    },
    [sortConfig.order, sortConfig.orderBy]
  );

  function quickFiltered() {
    if (quickFilter) {
      return filteredData.filter(smj => smj.name.toLowerCase().includes(quickFilter.toLowerCase()));
    }
    return filteredData;
  }

  const { classes } = useStyles();
  const theme = useTheme();

  return filteredData.length ? (
    <div style={{ flex: 1, overflow: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          textAlign: 'left',
        }}
      >
        <div style={{ margin: '8px' }}>
          <Search
            updateQuickFilter={updateQuickFilter}
            filterProps={{
              filteredData,
              selectedFilters: [],
            }}
          />
        </div>
        <div className={`${classes.tableWrapper}`}>
          <Table>
            <Headers columnNames={filteredColumNames} sortConfig={sortConfig} doSort={doSort} />
            <SMJListTableBody
              smjModelById={smjModelById}
              smjList={[...quickFiltered()].sort(
                columnsSorters[sortConfig.orderBy][sortConfig.order]
              )}
              columnNames={filteredColumNames}
              options={['edit', 'delete' /* , 'enable', 'disable' */]}
              actions={{
                delete: deleteSmj,
                edit: editSmj,
              }}
            />
          </Table>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        flex: 1,
        textAlign: 'center',
        paddingTop: '24px',
        backgroundColor: theme.palette.grey[50],
      }}
    >
      <Typography variant="h6" color="textSecondary">
        {utils.getLang('smartmessaging.campaigns.grouped.grouplist.empty')}
      </Typography>
    </div>
  );
};

SMJItemList.propTypes = {
  filteredData: PropTypes.array.isRequired,
  editSmj: PropTypes.func.isRequired,
  smjModelById: PropTypes.object.isRequired,
  deleteSmj: PropTypes.func.isRequired,
};

const filterCfg = props => ({
  filterFn: () => () => true,
  filtrableData: props.smjList,
});

const FiltrableList = filtrable(filterCfg)(SMJItemList);

export default FiltrableList;

import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Button, Fade } from '@mui/material';
import { getCurrentRequestModelId } from 'src/reducers/campaignEditor';
import paramSelectors from 'src/selectors/parameters';

import getApiCaller from 'src/utils/apiClientCaller';
import CampaignActionItem from './CampaignActionItem';
import utils from 'src/utils/utils';
import apiClient from 'src/utils/apiClient';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';
import { groupActionTypesByCat, ActionTypeCatById } from 'src/enums/actionType';
import CustomCard from 'src/components/common/Card';

const CampaignActions = ({
  availableActionTypes,
  campaignActions,
  currentRmId,
  rmTypeId,
  loadFieldsAndContent,
  goToCmpMsgStep,
  goToEditorStep,
  editedCampaign,
}) => (
  <Fade in timeout={500}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          margin: '48px 15% 0',
        }}
      >
        <div
          style={{
            overflow: 'auto',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              overflow: 'visible',
              textAlign: 'center',
            }}
          >
            {groupActionTypesByCat(availableActionTypes).map(groupedTypes => (
              <CustomCard
                title={utils.getLang(
                  `smartmessaging.campaignAction.typeTitle.${
                    ActionTypeCatById[groupedTypes[0].cat]
                  }`
                )}
                key={`${groupedTypes[0].id}group`}
                contentStyle={{
                  overflow: 'auto',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {groupedTypes.map(actionType => (
                  <CampaignActionItem
                    isWritable={editedCampaign.isWritable}
                    key={actionType.name}
                    actionTypeId={actionType.id}
                    rmId={currentRmId}
                    rmTypeId={rmTypeId}
                    goToCmpMsgStep={goToCmpMsgStep}
                    loadFieldsAndContent={async loadContentFn => {
                      await loadFieldsAndContent(currentRmId, actionType.id)(loadContentFn);
                    }}
                  />
                ))}
              </CustomCard>
            ))}
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="text"
            color="primary"
            style={{ margin: '2px' }}
            onClick={e => {
              e.preventDefault();
              goToEditorStep(1);
            }}
          >
            {utils.getLang('smartmessaging.buttonLabel.previous')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '2px' }}
            disabled={!campaignActions || !campaignActions.length}
            onClick={e => {
              e.preventDefault();
              goToEditorStep(3);
            }}
          >
            {utils.getLang('smartmessaging.buttonLabel.next')}
          </Button>
        </div>
      </div>
    </div>
  </Fade>
);

CampaignActions.propTypes = {
  availableActionTypes: PropTypes.array.isRequired,
  campaignActions: PropTypes.array.isRequired,
  currentRmId: PropTypes.number.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  loadFieldsAndContent: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

function Handler(props) {
  const {
    smParams,
    doGetApiCaller,
    wizVilleEntryActivated,
    rmTypeId,
    wizVilleNewAboTypes,
    wizVilleNewAboActivated,
    wizVilleCancelAboTypes,
    wizVilleCancelAboActivated,
  } = props;

  const loadFieldsAndContent = useCallback(
    (rmId, atId) => {
      function includeWizvilleBlock() {
        if (wizVilleNewAboActivated && wizVilleNewAboTypes.indexOf(rmTypeId) !== -1) {
          return true;
        }

        if (wizVilleCancelAboActivated && wizVilleCancelAboTypes.indexOf(rmTypeId) !== -1) {
          return true;
        }
        return false;
      }
      const fn = async loadContentFn => {
        let fields = await doGetApiCaller()(apiClient.getContentFieldsForRequestModel, [
          rmId,
          atId,
        ]);

        if (!wizVilleEntryActivated) {
          const { wizvilleentryLink, ...cleanFields } = fields;
          fields = cleanFields;
        }
        if (!includeWizvilleBlock()) {
          const { wizvilleBlock, ...cleanFields } = fields;
          fields = cleanFields;
        }
        const processedFields = contentEditorUtils.processContentFields(fields, atId, {
          headerFileId: smParams.headerStoredfileId || null,
          footerFileId: smParams.footerStoredfileId || null,
        });
        await loadContentFn(processedFields);
      };
      return fn;
    },
    [
      wizVilleNewAboTypes,
      rmTypeId,
      wizVilleNewAboActivated,
      wizVilleCancelAboTypes,
      wizVilleCancelAboActivated,
      doGetApiCaller,
      wizVilleEntryActivated,
      smParams.headerStoredfileId,
      smParams.footerStoredfileId,
    ]
  );

  return <CampaignActions {...props} loadFieldsAndContent={loadFieldsAndContent} />;
}

Handler.propTypes = {
  doGetApiCaller: PropTypes.func.isRequired,
  smParams: PropTypes.object.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  rmTypeId: PropTypes.number.isRequired,
  wizVilleNewAboTypes: PropTypes.array.isRequired,
  wizVilleNewAboActivated: PropTypes.bool.isRequired,
  wizVilleCancelAboTypes: PropTypes.array.isRequired,
  wizVilleCancelAboActivated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  availableActionTypes: state.campaignEditor.actions.availableActionTypes,
  currentRmId: getCurrentRequestModelId(state),
  campaignActions: state.campaignEditor.actions.campaignActions,
  smParams: paramSelectors.getCurrentParams(state),
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  wizVilleNewAboActivated: state.app.config.wizVilleNewAboActivated,
  wizVilleCancelAboActivated: state.app.config.wizVilleCancelAboActivated,
  rmTypeId: state.campaignEditor.editedCampaign.requestModelTypeId,
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleNewAboTypes: state.app.wizVilleNewAboRequestModels,
  wizVilleCancelAboTypes: state.app.wizVilleCancelAboRequestModels,
});

const actionCreators = {
  doGetApiCaller: () => getApiCaller,
};

export default connect(mapStateToProps, actionCreators)(Handler);

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import History from './History';
import Details from './Details';

import { loaderActions } from 'src/reducers/loader';
import getApiCaller from 'src/utils/apiClientCaller';
import statsApi from 'src/utils/api/stats';
import utils from 'src/utils/utils';

function HistoryPage({ setLoader, doGetApiCaller, showAlert, components, lang, ...otherProps }) {
  const [currentStep, goToHistoryStep] = useState(0);
  const [extraProps, setExtraProps] = useState({});
  const enhancedGoToHistoryStep = useCallback((step, newExtraProps) => {
    setExtraProps(newExtraProps || {});
    goToHistoryStep(step);
  }, []);

  const exportHistory = useCallback(
    async campaignId => {
      const callApi = doGetApiCaller();
      setLoader(true);
      const exportFileId = await callApi(statsApi.exportHistory, [campaignId, lang]).finally(() =>
        setLoader(false)
      );

      if (exportFileId && exportFileId !== -1) {
        await utils.downloadPrivateStoredFile(exportFileId, callApi);
        setLoader(false);
      } else {
        setLoader(false);
        showAlert(utils.getLang('smartmessaging.noData'));
      }
    },
    [doGetApiCaller, lang, setLoader, showAlert]
  );

  const exportExecutionResult = useCallback(
    async (execId, atId) => {
      const callApi = doGetApiCaller();
      setLoader(true);
      const exportFileId = await callApi(statsApi.exportExecutionResult, [
        execId,
        atId,
      ]).finally(() => setLoader(false));

      if (exportFileId && exportFileId !== -1) {
        await utils.downloadPrivateStoredFile(exportFileId, callApi);
        setLoader(false);
      } else {
        setLoader(false);
        showAlert(utils.getLang('smartmessaging.noData'));
      }
    },
    [doGetApiCaller, setLoader, showAlert]
  );
  switch (currentStep) {
    case 0:
      return (
        <History
          currentStep={currentStep}
          goToHistoryStep={enhancedGoToHistoryStep}
          exportExecutionResult={exportExecutionResult}
          exportHistory={exportHistory}
          {...otherProps}
          {...extraProps}
        />
      );
    case 1:
      return (
        <Details
          currentStep={currentStep}
          goToHistoryStep={enhancedGoToHistoryStep}
          exportExecutionResult={exportExecutionResult}
          exportHistory={exportHistory}
          UsedFilter={components && components.UsedFilter}
          {...otherProps}
          {...extraProps}
        />
      );
    default:
      return null;
  }
}

HistoryPage.propTypes = {
  backToMain: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  receiveHistory: PropTypes.func,
  excludedProperties: PropTypes.array,
  rowSpanCols: PropTypes.array,
  sortable: PropTypes.array,
  summaryExcludedProperties: PropTypes.array,
  components: PropTypes.any,
  lang: PropTypes.string.isRequired,
};

HistoryPage.defaultProps = {
  excludedProperties: [],
  receiveHistory: null,
  components: null,
  rowSpanCols: [],
  sortable: [],
  summaryExcludedProperties: [],
};

const actionCreators = {
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: () => getApiCaller,
  showAlert: msg => ({ type: 'SHOW_ALERT', value: { msg } }),
};

export default connect(
  state => ({ lang: state.app.currentLanguage.shortName }),
  actionCreators
)(HistoryPage);

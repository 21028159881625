import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import utils from 'src/utils/utils';

import ValidityAdornment from '../ValidityAdornment';
import { useTheme } from '@mui/material/styles';

const EmailContentLabel = ({ title, contentValidity }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex' }}>
      <Typography
        style={{
          margin: '5px 10px',
          color: !contentValidity.contentIsValid
            ? theme.palette.error.main
            : theme.palette.text.secondary,
        }}
        variant="subtitle2"
        noWrap
      >
        {title}
      </Typography>
      <ValidityAdornment
        valid={contentValidity.contentIsValid}
        message={
          !contentValidity.contentIsValid
            ? contentValidity.invalidities.content.map(msg => (
                <ul>
                  <li>{msg}</li>
                </ul>
              ))
            : ''
        }
      />
      {!contentValidity.hasOptoutLink && (
        <ValidityAdornment
          valid={false}
          message={
            <ul>
              <li>{utils.getLang('smartmessaging.contentEditor.mail.optoutLinkMissing')}</li>
            </ul>
          }
        />
      )}
    </div>
  );
};

EmailContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default EmailContentLabel;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import utils from 'src/utils/utils';

import ValidityAdornment from '../ValidityAdornment';
import { useTheme } from '@mui/material/styles';

const NotifyContentLabel = ({ contentValidity }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex' }}>
      <Typography
        style={{
          margin: '5px 10px',
          color: contentValidity.contentIsEmpty
            ? theme.palette.error.main
            : theme.palette.text.secondary,
        }}
        variant="subtitle2"
        noWrap
      >
        {utils.getLang('smartmessaging.contentEditor.notify.content')}
      </Typography>
      <ValidityAdornment
        valid={!contentValidity.contentIsEmpty}
        message={utils.getLang('smartmessaging.contentEditor.notify.contentIsEmpty')}
      />
    </div>
  );
};

NotifyContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
};

export default NotifyContentLabel;

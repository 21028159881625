import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import utils from '../../../utils/utils';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  cell: {
    padding: '4px',
    color: theme.palette.text.secondary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

function CustomCell({ column, item, rowSpan }) {
  const { classes } = useStyles();
  switch (column) {
    case 'linktype':
      return (
        <TableCell key={column} className={classes.cell} rowSpan={rowSpan}>
          <span>
            {item[column]
              ? utils.getLang(`smartmessaging.resultfield.${item[column]}`)
              : utils.getLang(`smartmessaging.linkType.undefined`)}
          </span>
        </TableCell>
      );
    case 'url':
      return (
        <TableCell key={column} className={classes.cell} rowSpan={rowSpan}>
          <Tooltip placement="top-start" title={item[column]}>
            <span>{item[column]}</span>
          </Tooltip>
        </TableCell>
      );
    default:
      return (
        <TableCell key={column} className={classes.cell} rowSpan={rowSpan}>
          <span>{item[column]}</span>
        </TableCell>
      );
  }
}
CustomCell.propTypes = {
  item: PropTypes.object.isRequired,
  column: PropTypes.string.isRequired,
  rowSpan: PropTypes.number.isRequired,
};

const ClickedLinkHistoryItem = ({ item, rowSpanCols, rowSpan, columns }) => (
  <TableRow>
    {columns.map(column => (
      <CustomCell
        key={column}
        column={column}
        item={item}
        rowSpan={rowSpanCols.indexOf(column) === -1 ? 1 : rowSpan}
      />
    ))}
  </TableRow>
);

ClickedLinkHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  rowSpanCols: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rowSpan: PropTypes.number.isRequired,
};

ClickedLinkHistoryItem.defaultProps = {
  rowSpanCols: [],
};

export default ClickedLinkHistoryItem;

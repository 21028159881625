import React from 'react';
import { withState, withHandlers } from 'recompose';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { Button, InputLabel } from '@mui/material';
import { sendSmConfigImage, logState } from 'src/reducers/parameters';

import FileUploaderBtn from '../common/FileUploaderBtn';
import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import { showAlertDispatcher, closeAlertDispatcher } from 'src/reducers/alert';

const useStyles = makeStyles()(theme => ({
  loadedImgOuter: {
    ...theme.typography.caption,
    width: '200px',
    color: theme.palette.common.black,
    textAlign: 'center',
    padding: '2px',
  },
  loadedImgInner: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '100px',
    width: '200px',
    backgroundPosition: 'center',
  },
}));

const LoadedImg = ({ file, dataUrl }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.loadedImgOuter}>
      <div
        className={classes.loadedImgInner}
        style={{
          backgroundImage: `url(${dataUrl})`,
        }}
      />
      <span>
        {file.name} ({(file.size / 1000).toFixed(1)} Ko)
      </span>
    </div>
  );
};

LoadedImg.propTypes = {
  dataUrl: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
};

const fieldUseStyles = makeStyles()(theme => ({
  savedImgCtn: {
    backgroundSize: 'contain',

    backgroundRepeat: 'no-repeat',
    height: '100px',
    width: '200px',
    backgroundPosition: 'center',
  },
  deleteBtn: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
      opacity: 0.9,
    },
  },
  button: {
    margin: theme.spacing(0.5),
  },
}));

const SmConfigImageField = ({
  label,
  storedFileId,
  dataUrl,
  onFileChange,
  paramKey,
  doSendSmConfigImage,
  onFieldChange,
  doGetApiCaller,
}) => {
  const { classes } = fieldUseStyles();
  return (
    <div
      style={{
        margin: '24px 24px 0',
        textAlign: 'left',
      }}
    >
      <InputLabel shrink style={{ display: 'inline-flex', fontWeight: 500 }}>
        {label}
      </InputLabel>
      {!storedFileId && (
        <FileUploaderBtn
          selectFileLabel={utils.getLang('smartmessaging.config.imagefield.selectFile')}
          sendFileLabel={utils.getLang('smartmessaging.config.imagefield.send')}
          cancelLabel={utils.getLang('smartmessaging.config.imagefield.cancel')}
          displayCmp={file => <LoadedImg file={file} dataUrl={dataUrl} />}
          accept="image/*"
          onFileChange={onFileChange}
          onSend={file => {
            doSendSmConfigImage(paramKey, file);
          }}
        />
      )}
      {storedFileId && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className={classes.savedImgCtn}
            style={{
              backgroundImage: `url(${utils.link(storedFileId)})`,
            }}
          />

          <Button
            className={classes.deleteBtn}
            onClick={e => {
              e.preventDefault();
              onFieldChange(paramKey, null);
            }}
          >
            {utils.getLang('smartmessaging.config.imagefield.delete')}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => {
              utils.downloadStoredFile(storedFileId, doGetApiCaller());
            }}
          >
            {utils.getLang('smartmessaging.config.imagefield.download')}
          </Button>
        </div>
      )}
    </div>
  );
};

SmConfigImageField.propTypes = {
  storedFileId: PropTypes.number,
  dataUrl: PropTypes.string,
  onFileChange: PropTypes.func.isRequired,
  label: PropTypes.any,
  paramKey: PropTypes.string.isRequired,
  doSendSmConfigImage: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
};

SmConfigImageField.defaultProps = {
  storedFileId: null,
  dataUrl: '',
  label: '',
};

const mapStateToProps = () => ({});

const actionCreators = {
  doSendSmConfigImage: sendSmConfigImage,
  onFieldChange: (key, value) => ({ type: 'ON_SM_CFG_FIELD_CHANGE', value: { key, value } }),
  doLogState: logState,
  doShowAlert: showAlertDispatcher,
  doCloseAlert: closeAlertDispatcher,
  doGetApiCaller: () => getApiCaller,
};

const handlers = {
  onFileChange: props => (e, pFile) => {
    const { setDataUrl } = props;
    if (!e && !pFile) {
      setDataUrl('');
      return false;
    }
    const file = pFile || e.target.files[0];
    if (!file) {
      setDataUrl('');
      return false;
    }
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      return false;
    }

    reader.onload = async () => {
      setDataUrl(reader.result);
    };

    reader.readAsDataURL(file);
    return true;
  },
};

export default compose(
  connect(mapStateToProps, actionCreators),
  withState('dataUrl', 'setDataUrl', () => ''),
  withHandlers(handlers)
)(SmConfigImageField);

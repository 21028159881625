import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, ListItemIcon, Typography, Tooltip } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CascadingMenu from './CascadingMenu';

const useStyles = makeStyles()(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    overflow: 'visible',
    height: 'auto',
    justifyContent: 'flex-end',
    width: 'fit-content',
    paddingRight: 0,
  },
  tooltip: {
    backgroundColor: 'transparent',
    border: 'none',
    maxWidth: 'none',
  },
}));

const CascadingMenuItem = ({ caption, menuItems }) => {
  const { classes } = useStyles();
  return (
    <Tooltip
      placement="right-start"
      classes={{ tooltip: classes.tooltip }}
      title={<CascadingMenu menuItems={menuItems} />}
    >
      <MenuItem
        classes={{
          root: classes.root,
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          align="right"
          style={{
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {caption}
        </Typography>
        <ListItemIcon style={{ color: 'inherit', margin: 0, minWidth: 'unset' }}>
          <ArrowRightIcon />
        </ListItemIcon>
      </MenuItem>
    </Tooltip>
  );
};

CascadingMenuItem.propTypes = {
  caption: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default CascadingMenuItem;

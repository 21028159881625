import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { TableBody, TableRow, TableCell, useTheme, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Loader from 'src/components/common/Loader';

import utils from 'src/utils/utils';
import MassActionItemMenu from './MassActionItemMenu';
import withDataResolver from 'src/components/common/withDataResolver';
import { initData, loadAvailableResultfields, loadMassActions } from 'src/reducers/massAction';

import columnConfig from './utils';

function MassActionListTableRows({ massActionList, getOptions, actions }) {
  const theme = useTheme();
  return (
    <TransitionGroup component={TableBody}>
      {massActionList.map(massAction => (
        <CSSTransition timeout={300} classNames="fade" key={massAction.id || uuid()}>
          <TableRow
            key={massAction.id || uuid()}
            hover
            onDoubleClick={e => {
              e.preventDefault();
              actions.edit(massAction);
            }}
          >
            <TableCell>
              <MassActionItemMenu
                request={massAction}
                options={getOptions(massAction)}
                actions={{
                  edit: () => actions.edit(massAction),
                  execute: () => actions.execute(massAction),
                  enable: () => actions.enable(massAction),
                  disable: () => actions.disable(massAction),
                  showHistory: () => actions.showHistory(massAction),
                }}
              />
            </TableCell>
            <TableCell align="left" padding="none" scope="row" style={{ fontWeight: 'bold' }}>
              <div style={{ display: 'flex' }}>
                {!!massAction.duplicates.length && (
                  <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                    <Tooltip
                      title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}
                    >
                      <WarningIcon color="error" />
                    </Tooltip>
                  </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                  {massAction.name}
                </div>
              </div>
            </TableCell>
            {columnConfig.columnNames
              .filter(
                n =>
                  n !== 'name' &&
                  (utils.isNodeMapped() ? true : ['networkNodeName'].indexOf(n) === -1)
              )
              .map(columnName => (
                <TableCell
                  align="left"
                  padding="none"
                  style={{ color: theme.palette.text.secondary }}
                  key={columnName}
                >
                  {columnConfig.renderCell(columnName, massAction)}
                </TableCell>
              ))}
          </TableRow>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}

MassActionListTableRows.propTypes = {
  massActionList: PropTypes.array.isRequired,
  getOptions: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

const WrappedLoader = () => (
  <tbody>
    <tr>
      <td>
        <Loader
          text={utils.getLang('smartmessaging.massAction.mainDataLoading')}
          wrapperClass="ghostWrapperClass"
        />
      </td>
    </tr>
  </tbody>
);

const resolvers = {
  resolve: props => async () => {
    await props.doLoadMassActions();
  },
  loader: <WrappedLoader />,
  onResolved: () => async () => {},
};

const WithData = withDataResolver(resolvers)(MassActionListTableRows);

const actionCreators = {
  doLoadData: initData,
  loadResultfields: loadAvailableResultfields,
  doLoadMassActions: loadMassActions,
};

export default connect(null, actionCreators)(WithData);

import React from 'react';
import PropTypes from 'prop-types';
import { Select, Input, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import OperatorType from 'src/enums/operatorType';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  operatorSelectInput: theme.typography.body2,
  popover: { padding: 0, margin: 0 },
}));

const OperatorSelectRender = ({ fieldConfig, onChange, disabled }) => {
  function getValue() {
    if (
      !!fieldConfig.value &&
      fieldConfig.value.operatorId !== undefined &&
      fieldConfig.value.operatorId !== null
    )
      return fieldConfig.value.operatorId;
    if (fieldConfig.model.allowedOperators.length === 1)
      return OperatorType[fieldConfig.model.allowedOperators[0]];
    return '';
  }

  const { classes } = useStyles();

  return (
    <Select
      MenuProps={{
        MenuListProps: { style: { padding: 0, margin: 0 } },
        PopoverClasses: {
          paper: classes.popover,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },

        marginThreshold: 0,
      }}
      input={
        <Input
          inputProps={{
            'aria-label': 'Description',
            className: classes.operatorSelectInput,
          }}
        />
      }
      disabled={disabled}
      onChange={event => {
        event.preventDefault();
        onChange(fieldConfig, event.target.value);
      }}
      value={getValue()}
    >
      <MenuItem dense value="">
        --
      </MenuItem>
      {fieldConfig.model.allowedOperators.map(op => (
        <MenuItem dense key={op} value={OperatorType[op]}>
          {utils.getLang(`smartmessaging.recipeSetupOperator.${op}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

OperatorSelectRender.propTypes = {
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OperatorSelectRender;

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import PreviousIcon from '@mui/icons-material/ChevronLeft';

const HistoryBackBtn = ({ onClick }) => (
  <IconButton
    color="secondary"
    style={{ margin: '2px' }}
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
    size="large"
  >
    <PreviousIcon color="secondary" />
  </IconButton>
);

HistoryBackBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HistoryBackBtn;

import utils from 'src/utils/utils';

const massActionListFilterConfigs = {
  name: {
    model: {
      key: 'name',
      renderLabel: () => utils.getLang('smartmessaging.campaignList.column.name'),
      renderValue: value => value,
    },
    inputConfig: {
      type: 'TEXT',
      isValid: value => !!value,
    },
  },
  creationDate: {
    model: {
      key: 'creationDate',
      renderLabel: () => utils.getLang('smartmessaging.campaignList.column.createDate'),
      renderValue: value => {
        const start = !!value[0] && new Date(value[0]);
        const end = !!value[1] && new Date(value[1]);
        if (start && end) {
          return `du ${start.toLocaleDateString()} au ${end.toLocaleDateString()}`;
        } else if (start) {
          return `après le ${start.toLocaleDateString()}`;
        } else if (end) {
          return `avant le ${end.toLocaleDateString()}`;
        }
        return '';
      },
    },
    inputConfig: {
      type: 'DATE',
      isValid: value => value.length && value.length > 0 && (value[0] || value[1]),
    },
  },
  creationUserName: {
    model: {
      key: 'creationUserName',
      renderLabel: () => utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
      renderValue: value => value,
    },
    inputConfig: {
      type: 'TEXT',
      isValid: value => !!value,
    },
  },
  networkNodeName: {
    model: {
      key: 'networkNodeName',
      renderLabel: () => utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
      renderValue: value => value,
    },
    inputConfig: {
      type: 'TEXT',
      isValid: value => !!value,
    },
  },
};

export default massActionListFilterConfigs;

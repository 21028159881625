const OperatorType = {
  EQUALS: 1,
  NOT_EQUALS: 2,
  CONTAINS: 3,
  STARTS_WITH: 4,
  ENDS_WITH: 5,
  LOWER_THAN: 6,
  GREATER_THAN: 7,
  LOWER_EQUAL_THAN: 8,
  GREATER_EQUAL_THAN: 9,
  BETWEEN: 10,
  NOT_BETWEEN: 11,
  IN: 12,
  NOT_IN: 13,
  NULL: 14,
  NOT_NULL: 15,
  AND: 16,
  OR: 17,
  TRUE: 18,
  FALSE: 19,
};

const getOperatorTypeById = () =>
  Object.entries(OperatorType).reduce((rez, [k, v]) => ({ ...rez, [v]: k }), {});

export const OperatorTypeById = getOperatorTypeById();

export default OperatorType;

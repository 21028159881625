import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import utils from 'src/utils/utils';

const columnLabel = colName =>
  utils.getLang(`smartmessaging.campaigns.grouped.list.column.${colName}`);

const tableSortLabelStyle = (theme, _params, classes) => ({
  active: { color: theme.palette.primary.main },
  root: {
    color: theme.palette.text.primary,
    [`&.${classes.active}`]: {
      color: theme.palette.primary.main,
      '& svg path': { color: theme.palette.primary.main },
    },
  },
});

const StyledTableSortLabelRender = ({ classes, children, ...otherProps }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...otherProps}>
    {children}
  </TableSortLabel>
);

const StyledTableSortLabel = withStyles(StyledTableSortLabelRender, tableSortLabelStyle);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

function Headers({ columnNames, sortConfig, doSort }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        />
        {columnNames.map(column => (
          <TableCell
            key={column}
            style={{
              position: 'sticky',
              top: 0,
              padding: '4px',
            }}
          >
            <StyledTableSortLabel
              key={column}
              active={sortConfig.orderBy === column}
              direction={sortConfig.order}
              onClick={() => {
                doSort(column);
              }}
            >
              {columnLabel(column)}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Headers.propTypes = {
  columnNames: PropTypes.array.isRequired,
  doSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
};

export default Headers;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import massActionSelectors from 'src/selectors/massAction';
import SelectedCriteriaGroupContainer from './SelectedCriteriaGroupContainer';

const SelectedCriteriaGroupList = ({ selectedCriterias, isWritable }) => (
  <Fragment>
    {Object.keys(selectedCriterias).map(groupName => (
      <SelectedCriteriaGroupContainer
        key={groupName}
        groupName={groupName}
        isWritable={isWritable}
      />
    ))}
  </Fragment>
);

SelectedCriteriaGroupList.propTypes = {
  selectedCriterias: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  selectedCriterias: massActionSelectors.getSelectedCriterias(state.massAction),
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(SelectedCriteriaGroupList);

import React, { useState, Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Menu, MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  lng: {
    ...theme.typography.button,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '12px 10px',
    borderLeft: '1px solid rgb(221, 221, 221)',
    transition: 'all 200ms ease-in-out 0s',
    display: 'inline-block',
    opacity: 0.8,
    '&:hover': { opacity: 1 },
  },
}));

function LangButton({ langs, currentLang, selectLang, title }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  const handleClick = useCallback(e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const { classes } = useStyles();
  return (
    <Fragment>
      <Tooltip title={title}>
        <div onClick={handleClick} className={classes.lng}>
          {currentLang.shortName}
        </div>
      </Tooltip>
      <Menu
        MenuListProps={{ disablePadding: true }}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          handleClose();
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
      >
        {langs.map(l => (
          <MenuItem
            dense
            selected={l.locale === currentLang.locale}
            disabled={l.locale === currentLang.locale}
            key={l.locale}
            onClick={() => {
              handleClose();
              selectLang(l.locale);
            }}
          >
            {utils.getLang(`smartmessaging.language.${l.shortName}`)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

LangButton.propTypes = {
  selectLang: PropTypes.func.isRequired,
  langs: PropTypes.array.isRequired,
  currentLang: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default LangButton;

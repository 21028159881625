import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import SmConfigIcon from './SmConfigIcon';
import utils from 'src/utils/utils';

const getTooltips = validity => {
  let tooltips = [];
  let ttIdx = 0;
  if (!validity.isValid) {
    if (validity.messages && validity.messages.length)
      tooltips = validity.messages.map(msg => {
        ttIdx += 1;
        return { key: ttIdx, value: msg };
      });
    else
      tooltips = [
        {
          key: 0,
          value: utils.getLang('smartmessaging.config.tooltip.invalidField'),
        },
      ];
  }
  return tooltips;
};

const useStylesTooltip = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
}));

const ValidityAdornment = ({ validity }) => {
  const { classes } = useStylesTooltip();
  return (
    <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
      {validity.isValid && <DoneIcon color="primary" />}
      {!validity.isValid && (
        <Tooltip
          classes={classes}
          title={
            <ul>
              {getTooltips(validity).map(tt => (
                <li key={tt.key}>{tt.value}</li>
              ))}
            </ul>
          }
          placement="bottom"
        >
          <WarningIcon color="error" />
        </Tooltip>
      )}
    </InputAdornment>
  );
};

ValidityAdornment.propTypes = {
  validity: PropTypes.object.isRequired,
};

const useStyles = makeStyles()(theme => ({
  input: { color: theme.palette.text.secondary, fontSize: '14px' },
  label: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&.invalidField': { color: theme.palette.error.main },
  },
}));

const SmConfigTextField = ({ paramKey, value, label, onChange, validity }) => {
  const { classes } = useStyles();
  return (
    <TextField
      style={{ maxWidth: '480px', gridArea: paramKey }}
      onChange={event => {
        event.preventDefault();
        onChange(event.target.value);
      }}
      InputLabelProps={{
        shrink: true,
        className: `${classes.label} ${!validity.isValid && 'invalidField'}`,
      }}
      InputProps={{
        className: classes.input,
        startAdornment: (
          <InputAdornment position="start">
            <SmConfigIcon property={paramKey} />
          </InputAdornment>
        ),
        endAdornment: <ValidityAdornment validity={validity} />,
      }}
      value={value}
      label={label}
    />
  );
};

SmConfigTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
  paramKey: PropTypes.string.isRequired,
};

export default SmConfigTextField;

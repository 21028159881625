import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import NotifyObjectLabel from './NotifyObjectLabel';
import NotifyContentLabel from './NotifyContentLabel';

import CKEditor from 'src/components/common/ReactCKEditor';

const MassActionMailEditorRender = ({
  fields,
  content,
  object,
  nodeRef,
  rendered,
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  isWritable,
}) => (
  <div
    style={{
      height: '70%',
      minHeight: '600px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflow: 'visible',
    }}
    ref={nodeRef}
  >
    {rendered && (
      <div>
        <NotifyObjectLabel />
        <CKEditor
          name="objectEditor"
          config={{
            readOnly: !isWritable,
            fields: fields.fields.objectFields,
            removeButtons:
              'Underline,Subscript,Superscript,' +
              'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
              'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
              'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
              'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
              'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
              'BidiLtr,BidiRtl,CreatePlaceholder,' +
              'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
            extraPlugins: 'strinsert',
            removePlugins: 'colorbutton,elementspath',
            autoParagraph: false,
            enterMode: 2,
            height: 10 * (nodeRef.current.clientHeight / 100),
          }}
          events={{
            loaded: event => {
              onObjectEditorLoaded(event.editor);
            },
            change: onChange,
          }}
          content={object}
        />
      </div>
    )}
    {rendered && (
      <div>
        <NotifyContentLabel />
        <CKEditor
          name="htmlEditor"
          config={{
            readOnly: !isWritable,
            fields: fields.fields.contentFields,
            fullPage: true,
            allowedContent: true,
            removeButtons:
              'Underline,Subscript,Superscript,Anchor,Image,Flash,Table,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',

            disallowedContent: 'img{border*,margin*,float*,width,height}; table{width,height}',
            extraAllowedContent: 'img[width,height]; table[width,height]',
            extraPlugins: 'strinsert,uploadimage',
            removePlugins: 'elementspath',
            autoParagraph: false,
            enterMode: 3,
            height: 70 * (nodeRef.current.clientHeight / 100),
          }}
          events={{
            loaded: event => {
              onEditorLoaded(event.editor);
            },
            change: onChange,
            instanceReady: event => {
              if (content) event.editor.setData(content);
            },
          }}
        />
      </div>
    )}
  </div>
);

MassActionMailEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  nodeRef: PropTypes.object.isRequired,
  rendered: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  onEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

MassActionMailEditorRender.defaultProps = {
  object: '',
};

/** Used to render ckeditors after containers are rendered for ckeditors height
 *  cause it's value is not state bound (to avoid rerenders causing ckeditors layout issues)
 */
class MassActionMailEditorWithRef extends Component {
  constructor(props) {
    super(props);
    this.myNodeRef = React.createRef();
  }

  componentDidMount() {
    CKEditor.onCKEditorDialogDefintion();
    this.props.setRendered(true);
  }

  render() {
    return <MassActionMailEditorRender {...this.props} nodeRef={this.myNodeRef} />;
  }
}

MassActionMailEditorWithRef.propTypes = {
  setRendered: PropTypes.func.isRequired,
  rendered: PropTypes.bool.isRequired,
  fields: PropTypes.object,
};

MassActionMailEditorWithRef.defaultProps = {
  fields: { fields: {} },
};

export default withState('rendered', 'setRendered', () => false)(MassActionMailEditorWithRef);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Fade, Toolbar, Typography, AppBar, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Loader from 'src/components/common/Loader';
import withDataResolver from 'src/components/common/withDataResolver';
import withApiCaller from 'src/components/common/hocs/withApiCaller';
import withAlertAndLoader from 'src/components/common/hocs/withAlertAndLoader';

import utils from 'src/utils/utils';
import SmartjourneyItemList from './SmartjourneyItemList';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import smjSelectors from 'src/selectors/smartjourney';

const SMJListDataResolver = withDataResolver({
  resolve: props => async () => {
    await props.doLoadSMJs();
  },
  loader: <Loader />,
  onResolved: () => async () => {},
})(({ children }) => children);

const useStyles = makeStyles()(theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  flex: { flex: 1 },
  uppercase: { textTransform: 'uppercase' },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
}));

const SMJList = ({ goToSMJStep, doLoadSMJs, campaignGroups, smjModelById, deleteSmj }) => {
  const { classes } = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography color="inherit" className={classes.uppercase}>
            {utils.getLang(`smartmessaging.campaigns.grouped.list.title`)}
          </Typography>
          <div
            style={{
              flex: 1,
              marginLeft: '16px',
            }}
          />
          <Tooltip title={utils.getLang('smartmessaging.tooltip.campaigns.grouped.newGroup')}>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                goToSMJStep(1);
              }}
              style={{ color: 'inherit' }}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Fade in timeout={500}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
            textAlign: 'left',
            margin: '8px',
          }}
        >
          <SMJListDataResolver doLoadSMJs={doLoadSMJs}>
            <SmartjourneyItemList
              editSmj={smj => {
                goToSMJStep(2, { smartjourney: smj });
              }}
              deleteSmj={pSmj => {
                deleteSmj(pSmj);
              }}
              enableSmj={() => {}}
              smjList={campaignGroups}
              smjModelById={smjModelById}
            />
          </SMJListDataResolver>
        </div>
      </Fade>
    </div>
  );
};

SMJList.propTypes = {
  goToSMJStep: PropTypes.func.isRequired,
  doLoadSMJs: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
  smjModelById: PropTypes.object.isRequired,
  deleteSmj: PropTypes.func.isRequired,
};

const Handler = ({
  callApi,
  setLoader,
  showAlert,
  closeAlert,
  notify,
  updateSmjList,
  campaignGroups,
  ...otherProps
}) => {
  const deleteSmj = useCallback(
    smj => {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign.title'),
        msg: `[${smj.name}]: ${utils.getLang(
          'smartmessaging.smartjourney.confirmDeleteSmartjourney'
        )}`,
        async onConfirm() {
          closeAlert();
          setLoader(true);
          const response = await callApi(smartjourneyApi.deleteSmj, [smj.id]).finally(() =>
            setLoader(false)
          );
          if (response.isSuccess) {
            updateSmjList(campaignGroups.filter(c => c.id !== smj.id));
            notify({
              message: `[${smj.name}] ${utils.getLang(
                'smartmessaging.notifications.deletedSmartjourney'
              )}`,
              options: {
                variant: 'warning',
              },
            });
          } else {
            showAlert({
              title: `${utils.getLang(`smartmessaging.errorMessages.warning.title`)}`,
              msg: utils.getLang(`smartmessaging.errorMessages.smartjourney.${response.error}`),
              type: 'warning',
            });
          }
        },
        onDismiss() {
          closeAlert();
        },
      });
    },
    [callApi, setLoader, showAlert, closeAlert, notify, updateSmjList, campaignGroups]
  );

  return <SMJList {...otherProps} deleteSmj={deleteSmj} campaignGroups={campaignGroups} />;
};

const mapStateToProps = state => ({
  campaignGroups: smjSelectors.getSMJListWithInfos(state.smartjourney),
});

const actionCreators = {
  updateSmjList: list => ({ type: 'RECEIVE_SMJ_LIST', value: list }),
};

Handler.propTypes = {
  callApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  updateSmjList: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
};

// TODO remove hocs
export default connect(mapStateToProps, actionCreators)(withAlertAndLoader(withApiCaller(Handler)));

import React from 'react';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';

import ContentLangButton from 'src/components/common/ContentLangBtn';

const mapStateToProps = state => ({
  availableLanguages: state.app.availableLanguages,
  currentLang: state.campaignEditor.actions.modifiedCurrentAction.language,
});
const Connected = connect(mapStateToProps, {
  onContentLangChange: value => ({ type: 'ON_CONTENT_LANG_CHANGE', value }),
})(props => (
  <ContentLangButton {...props} title={utils.getLang('smartmessaging.tooltip.contentLangButton')} />
));

export default Connected;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel } from '@mui/material';
import Checkbox from 'src/components/common/Checkbox';
import CustomCard from 'src/components/common/Card';

const SMJCampaignItem = ({ onSelect, title, description, checked, type }) => (
  <div style={{ textAlign: 'left' }}>
    <FormControlLabel
      checked={checked}
      control={
        <Checkbox
          style={{ pointerEvents: 'auto' }}
          onChange={e => {
            onSelect(e.target.checked);
          }}
        />
      }
      label={
        <CustomCard
          title={
            <Typography color="textPrimary" style={{ fontWeight: 200 }}>
              {title}
            </Typography>
          }
        >
          <Typography variant="body2" color="textSecondary" style={{ fontWeight: 200 }}>
            {type}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{ fontWeight: 200 }}>
            {description}
          </Typography>
        </CustomCard>
      }
    />
  </div>
);

SMJCampaignItem.propTypes = {
  onSelect: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

SMJCampaignItem.defaultProps = {
  onSelect: () => {},
  title: null,
  description: null,
  type: null,
};

export default SMJCampaignItem;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import ValidityAdornment from '../ValidityAdornment';
import { useTheme } from '@mui/material/styles';

const EmailObjectLabel = ({ title, contentValidity }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex' }}>
      <Typography
        style={{
          margin: '5px 10px',
          color: !contentValidity.objIsValid
            ? theme.palette.error.main
            : theme.palette.text.secondary,
        }}
        variant="subtitle2"
        noWrap
      >
        {title}
      </Typography>
      <ValidityAdornment
        valid={contentValidity.objIsValid}
        message={
          !contentValidity.objIsValid
            ? contentValidity.invalidities.obj.map(msg => (
                <ul>
                  <li>{msg}</li>
                </ul>
              ))
            : ''
        }
      />
    </div>
  );
};

EmailObjectLabel.propTypes = {
  title: PropTypes.string.isRequired,
  contentValidity: PropTypes.object.isRequired,
};

export default EmailObjectLabel;

import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withSteps from '../../common/hocs/withSteps';
import SMJCampaignList from './campaignlist/CampaignList';
import SMJCampaignEditor from './campaigneditor/CampaignEditor';
import History from '../../campaign/history/History';
import withDataResolver from 'src/components/common/withDataResolver';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import withAlertAndLoader from 'src/components/common/hocs/withAlertAndLoader';
import utils from 'src/utils/utils';

function WESMJCampaignList({ hideBackBtn, showBackBtn, ...others }) {
  useEffect(() => {
    showBackBtn();
    return () => {
      hideBackBtn();
    };
  }, [hideBackBtn, showBackBtn]);
  return <SMJCampaignList {...others} />;
}

WESMJCampaignList.propTypes = {
  hideBackBtn: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
};

function WESMJCampaignEditor({
  hideBackBtn,
  showBackBtn,
  doClearData,
  smartjourney,
  goToSMJEditorStep,
  ...others
}) {
  useEffect(() => {
    showBackBtn(() => {
      doClearData();
      goToSMJEditorStep(0, { smartjourney });
    });
    return () => {
      hideBackBtn();
      doClearData();
    };
  }, [hideBackBtn, showBackBtn, doClearData, smartjourney, goToSMJEditorStep]);
  return (
    <SMJCampaignEditor
      {...others}
      {...{ hideBackBtn, showBackBtn, doClearData, smartjourney, goToSMJEditorStep }}
    />
  );
}

WESMJCampaignEditor.propTypes = {
  hideBackBtn: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
  doClearData: PropTypes.func.isRequired,
  smartjourney: PropTypes.object.isRequired,
  goToSMJEditorStep: PropTypes.func.isRequired,
};

function WEHistory({ hideBackBtn, showBackBtn, goToCmpStep, ...others }) {
  useEffect(() => {
    showBackBtn(() => goToCmpStep(0));
    return () => {
      hideBackBtn();
    };
  }, [hideBackBtn, showBackBtn, goToCmpStep]);
  return <History {...others} {...{ hideBackBtn, showBackBtn, goToCmpStep }} />;
}

WEHistory.propTypes = {
  hideBackBtn: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
  goToCmpStep: PropTypes.func.isRequired,
};

function SmartjourneyEditor({
  currentStep,
  goToStep,
  smartjourney,
  showBackBtn,
  hideBackBtn,
  createCampaign,
  doClearData,
  groupIsWritable,
  callApi,
  setLoader,
  notify,
  ...otherProps
}) {
  const [smj, updateSmj] = useState(smartjourney);

  const saveSmj = useCallback(
    pSmj => {
      const asyncFn = async () => {
        setLoader(true);
        await callApi(smartjourneyApi.insertOrUpdateSMJ, [pSmj]).finally(setLoader(false));
        notify({
          message: utils.getLang('smartmessaging.campaigns.grouped.updatedGroup'),
          options: { variant: 'success' },
        });
      };
      asyncFn();
    },
    [callApi, notify, setLoader]
  );

  const updateSmjName = useCallback(
    (name, callback) => {
      const renamed = { ...smj, name };
      const asyncFn = async () => {
        await saveSmj(renamed);
        updateSmj(renamed);
        if (callback) callback(renamed);
      };
      asyncFn();
    },
    [smj, saveSmj]
  );

  switch (currentStep) {
    case 0:
      return (
        <WESMJCampaignList
          {...otherProps}
          groupIsWritable={groupIsWritable}
          createCampaign={() => {
            createCampaign(smartjourney.id);
            goToStep(1);
          }}
          editorCurrentStep={currentStep}
          goToSMJEditorStep={goToStep}
          smartjourney={smj}
          updateSmjName={updateSmjName}
          showBackBtn={showBackBtn}
          hideBackBtn={hideBackBtn}
        />
      );

    case 1:
      return (
        <WESMJCampaignEditor
          {...otherProps}
          smartjourney={smj}
          goToSMJEditorStep={goToStep}
          showBackBtn={showBackBtn}
          hideBackBtn={hideBackBtn}
          doClearData={doClearData}
        />
      );
    case 2:
      return (
        <WEHistory
          {...otherProps}
          goToCmpStep={goToStep}
          showBackBtn={showBackBtn}
          hideBackBtn={hideBackBtn}
        />
      );
    default:
      return <div>erreur</div>;
  }
}
SmartjourneyEditor.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  smartjourney: PropTypes.object.isRequired,
  showBackBtn: PropTypes.func.isRequired,
  hideBackBtn: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
  doClearData: PropTypes.func.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  callApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
};
const SmartjourneyEditorDataResolver = withDataResolver({
  resolve: props => async callApi => {
    const groupIsWritable = await callApi(smartjourneyApi.canWriteOnGroup, [props.smartjourney.id]);
    return groupIsWritable;
  },
  loader: false,
  onResolved: () => async () => {},
})(({ data, ...props }) => <SmartjourneyEditor {...props} groupIsWritable={data} />);

const Container = props => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      textAlign: 'left',
      height: '100%',
    }}
  >
    <SmartjourneyEditorDataResolver {...props} />
  </div>
);

export default withSteps(withAlertAndLoader(Container));

import React, { useState, useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import {
  Drawer,
  AppBar,
  Typography,
  IconButton,
  Toolbar,
  FormLabel,
  TextField,
  Paper,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MuiReactSelect from 'src/components/common/MuiReactSelect';
import Radio from 'src/components/common/Radio';
import Checkbox from 'src/components/common/Checkbox';

import { loadEditorData, saveTemplate } from 'src/reducers/customTemplates';

import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';
import apiClient from 'src/utils/apiClient';
import getApiCaller from 'src/utils/apiClientCaller';
import utils from 'src/utils/utils';
import { getSortedCategoryList } from 'src/enums/campaignCategory';
import CustomTemplateEditorButtons from './CustomTemplateEditorButtons';
import CustomContentEditorTrigger from './CustomContentEditorTrigger';
import CustomContentEditor from './contentEditor/CustomContentEditor';
import rmSelectors from 'src/selectors/requestModel';

const RadioLabel = ({ label }) => (
  <Typography variant="body2" color="textSecondary">
    {label}
  </Typography>
);

RadioLabel.propTypes = { label: PropTypes.string.isRequired };

const useStyles = makeStyles()(theme => ({
  list: {
    position: 'relative',
    overflow: 'auto',
    margin: '8px',
    marginTop: '24px',
    flex: 1,
  },
  paper: {
    width: '50%',
    minWidth: '800px',
  },
  textFieldInput: {
    color: theme.palette.text.secondary,
    ...theme.typography.body2,
  },
  textField: {
    display: 'flex',
    margin: '8px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    top: '0%',
  },
  typoBody2: theme.typography.body2,
}));

const sortedCategories = getSortedCategoryList();

function CustomTemplateEditor({
  customTemplate,
  closeTemplateEditor,
  updateEditedTemplate,
  requestModelsByCategoryId,
  doLoadEditorData,
  showContentEditor,
  doGetApiCaller,
  rmId,
  getRmIdByRmTypeId,
  doSaveTemplate,
  classes,
}) {
  const [contentData, setContentData] = useState({});
  const [messageModels, setMessageModels] = useState(null);
  const [messageModelsLoaded, setMessageModelsLoaded] = useState(true);

  const [firstRender, setFirstRender] = useState(true);

  const setDefaultMessageModels = useCallback(
    async actionTypeId => {
      let genericModels = null;

      setMessageModelsLoaded(false);
      if (actionTypeId) {
        const apiCaller = doGetApiCaller();
        genericModels = await apiCaller(apiClient.getGenericMessageModels, [actionTypeId]);
        genericModels = contentEditorUtils.sortGenericMessageModels(genericModels);
      }
      setMessageModels(genericModels);

      setMessageModelsLoaded(true);
    },
    [doGetApiCaller]
  );

  const updateMessageModels = useCallback(
    async (actionTypeId, requestModelId) => {
      if (actionTypeId && requestModelId) {
        const apiCaller = doGetApiCaller();
        setMessageModelsLoaded(false);
        const list = await apiCaller(apiClient.getMessageModels, [requestModelId, actionTypeId]);
        const sortedMessages = contentEditorUtils.sortGenericMessageModels(list);
        setMessageModels(sortedMessages);
        setMessageModelsLoaded(true);
      } else if (actionTypeId) {
        setDefaultMessageModels(actionTypeId);
        setMessageModelsLoaded(true);
      } else {
        setMessageModels(null);
        setMessageModelsLoaded(true);
      }
    },
    [doGetApiCaller, setDefaultMessageModels]
  );

  useEffect(() => {
    if (firstRender) {
      updateMessageModels(customTemplate.actionTypeId, rmId);
      setFirstRender(false);
    }
  }, [customTemplate, firstRender, rmId, updateMessageModels]);

  function onCampaignTypeChange(requestModelTypeId) {
    updateEditedTemplate({ requestModelTypeId });
    updateMessageModels(customTemplate.actionTypeId, getRmIdByRmTypeId(requestModelTypeId));
  }

  function onActionTypeChange(actionTypeId) {
    updateEditedTemplate({ actionTypeId });
    if (rmId) updateMessageModels(actionTypeId, rmId);
    else setDefaultMessageModels(actionTypeId);
  }

  const loadContentEditorData = useCallback(
    async ({ storedFileId = null, uploadedData = null }) => {
      await doLoadEditorData({
        rmTypeId: customTemplate.requestModelTypeId,
        actionTypeId: customTemplate.actionTypeId,
        storedFileId: storedFileId || false,
        uploadedData: uploadedData || false,
      }).then(data => {
        setContentData(data);
        showContentEditor();
      });
    },
    [customTemplate, doLoadEditorData, showContentEditor]
  );

  return [
    <AppBar position="static" className={classes.appBar} key={0}>
      <Toolbar>
        <Typography variant="h5" color="inherit" noWrap style={{ flex: 1 }}>
          {(customTemplate && customTemplate.name) ||
            utils.getLang('smartmessaging.customTemplateEditor.emptyNameTitle')}
        </Typography>
        <IconButton
          aria-label="clear"
          onClick={e => {
            e.preventDefault();
            closeTemplateEditor();
          }}
          color="inherit"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>,
    customTemplate && (
      <div key={1} style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', flex: 1 }}>
        <div style={{ width: '100%', overflowY: 'auto', padding: '20px' }}>
          <Paper elevation={0}>
            <hr style={{ marginBottom: '8px', border: 'none' }} />
            <FormLabel required className={classes.typoBody2} htmlFor="tplname">
              {utils.getLang('smartmessaging.customTemplateEditor.templateName')}
            </FormLabel>
            <TextField
              InputProps={{
                className: classes.textFieldInput,
                id: 'tplname',
              }}
              className={classes.textField}
              value={customTemplate.name || ''}
              onChange={event => {
                event.preventDefault();
                updateEditedTemplate({ name: event.target.value });
              }}
              disabled={!customTemplate.isWritable}
            />
            <hr style={{ marginBottom: '8px', border: 'none' }} />
            <FormLabel required className={classes.typoBody2} htmlFor="tpldesc">
              {utils.getLang('smartmessaging.customTemplateEditor.description')}
            </FormLabel>
            <TextField
              InputProps={{
                className: classes.textFieldInput,
                id: 'tpldesc',
              }}
              onChange={event => {
                event.preventDefault();
                updateEditedTemplate({ description: event.target.value });
              }}
              multiline
              value={customTemplate.description || ''}
              className={classes.textField}
              minRows={2}
              maxRows={5}
              disabled={!customTemplate.isWritable}
            />
            <hr style={{ marginBottom: '8px', border: 'none' }} />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!!customTemplate.storedfileId || !customTemplate.isWritable}
                    checked={!customTemplate.generic}
                    onChange={() => {
                      if (!customTemplate.generic && customTemplate.actionTypeId) {
                        setDefaultMessageModels(customTemplate.actionTypeId);
                      } else {
                        updateMessageModels(null);
                      }
                      updateEditedTemplate({
                        generic: !customTemplate.generic,
                        requestModelTypeId: null,
                      });
                    }}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    {utils.getLang('smartmessaging.customTemplateEditor.isCampaignTypeBinded')}
                  </Typography>
                }
              />
            </div>
            {!customTemplate.generic && (
              <Fragment>
                <hr style={{ marginBottom: '8px', border: 'none' }} />
                <FormLabel required className={classes.typoBody2}>
                  {utils.getLang('smartmessaging.customTemplateEditor.selectBindedCampaignType')}
                </FormLabel>

                <div style={{ margin: '8px' }}>
                  <MuiReactSelect
                    isDisabled={
                      !!customTemplate.storedfileId ||
                      customTemplate.generic ||
                      !customTemplate.isWritable
                    }
                    isMulti={false}
                    closeMenuOnSelect
                    value={
                      (customTemplate.requestModelTypeId && [customTemplate.requestModelTypeId]) ||
                      []
                    }
                    onChange={val => onCampaignTypeChange(val)}
                    options={sortedCategories.reduce(
                      (acc, cat) =>
                        (requestModelsByCategoryId[cat.id] &&
                          requestModelsByCategoryId[cat.id].requestModels &&
                          requestModelsByCategoryId[cat.id].requestModels.length && [
                            ...acc,
                            {
                              label: utils.getLang(
                                `smartmessaging.campaignCategory.name.${cat.name}`
                              ),
                              value: null,
                              disabled: true,
                              style: { fontSize: '0.9rem' },
                            },
                            ...requestModelsByCategoryId[cat.id].requestModels.map(rm => ({
                              label: utils.getLang(`smartmessaging.requestmodel.label.${rm.name}`),
                              value: rm.requestModelTypeId,
                              disabled: false,
                              style: {
                                fontStyle: 'italic',
                                padding: '8px',
                              },
                            })),
                          ]) ||
                        acc,
                      []
                    )}
                  />
                </div>
              </Fragment>
            )}

            <hr style={{ marginBottom: '8px', border: 'none' }} />
            <FormLabel required style={{ fontSize: '0.975rem', fontWeight: 400 }}>
              {utils.getLang('smartmessaging.customTemplateEditor.actionType')}
            </FormLabel>
            <div style={{ margin: '8px' }}>
              <FormControlLabel
                className={classes.textFieldInput}
                checked={customTemplate.actionTypeId === 2}
                disabled={!customTemplate.isWritable || !!customTemplate.storedfileId}
                onChange={event => {
                  event.preventDefault();
                  onActionTypeChange(2);
                }}
                control={<Radio />}
                label={
                  <RadioLabel
                    label={utils.getLang('smartmessaging.contentEditor.emailActionName')}
                  />
                }
                labelPlacement="end"
              />

              <FormControlLabel
                checked={customTemplate.actionTypeId === 1}
                disabled={!customTemplate.isWritable || !!customTemplate.storedfileId}
                className={classes.textFieldInput}
                onChange={event => {
                  event.preventDefault();
                  onActionTypeChange(1);
                }}
                control={<Radio />}
                label={
                  <RadioLabel label={utils.getLang('smartmessaging.contentEditor.smsActionName')} />
                }
                labelPlacement="end"
              />
            </div>
            <hr style={{ marginBottom: '8px', border: 'none' }} />
            {(customTemplate.generic || customTemplate.requestModelTypeId) &&
              customTemplate.actionTypeId && (
                <CustomContentEditorTrigger
                  loadData={loadContentEditorData}
                  template={customTemplate}
                  messageModels={messageModels || {}}
                  loaded={messageModelsLoaded}
                  updateEditedTemplate={updateEditedTemplate}
                />
              )}
          </Paper>
        </div>
      </div>
    ),
    <CustomTemplateEditorButtons
      key={3}
      closeTemplateEditor={closeTemplateEditor}
      saveTemplate={doSaveTemplate}
      template={customTemplate}
    />,
    <CustomContentEditor template={customTemplate} data={contentData} key={4} />,
  ];
}

CustomTemplateEditor.propTypes = {
  customTemplate: PropTypes.object,
  doSaveTemplate: PropTypes.func.isRequired,
  closeTemplateEditor: PropTypes.func.isRequired,
  updateEditedTemplate: PropTypes.func.isRequired,
  requestModelsByCategoryId: PropTypes.object.isRequired,
  showContentEditor: PropTypes.func.isRequired,
};

CustomTemplateEditor.defaultProps = {
  customTemplate: null,
};

const CustomTemplatePanel = props => {
  const { classes } = useStyles();
  return (
    <Drawer classes={{ paper: classes.paper }} anchor="right" open={Boolean(props.customTemplate)}>
      {props.customTemplate && <CustomTemplateEditor {...{ ...props, classes }} />}
    </Drawer>
  );
};

CustomTemplatePanel.propTypes = {
  customTemplate: PropTypes.object,
};

CustomTemplatePanel.defaultProps = {
  customTemplate: null,
};

const mapStateToProps = state => ({
  customTemplate: state.customTemplates.editedTemplate,
  requestModelsByCategoryId: state.requestModelList.requestModelsByCategoryId,
  getRmIdByRmTypeId: rmTypeId => rmSelectors.getRmIdByRmTypeId(state, rmTypeId),
  rmId:
    (state.customTemplates.editedTemplate &&
      rmSelectors.getRmIdByRmTypeId(
        state,
        state.customTemplates.editedTemplate.requestModelTypeId
      )) ||
    null,
});

const actionCreators = {
  doLoadEditorData: loadEditorData,
  doSaveTemplate: saveTemplate,
  doGetApiCaller: () => getApiCaller,
  closeTemplateEditor: () => ({ type: 'EDIT_CUSTOM_TEMPLATE', value: null }),
  updateEditedTemplate: changes => ({ type: 'UPDATE_EDITED_TEMPLATE', value: changes }),
  showContentEditor: () => ({ type: 'SHOW_CUSTOM_CONTENT_EDITOR', value: true }),
};

export default connect(mapStateToProps, actionCreators)(CustomTemplatePanel);

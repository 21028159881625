import React, { useState, useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

import { withStyles } from 'tss-react/mui';
import {
  Paper,
  Table,
  TableBody,
  Typography,
  TableFooter,
  TableRow,
  IconButton,
  Tooltip,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/VerticalAlignBottom';
import Refresh from '@mui/icons-material/Refresh';

import Loader from 'src/components/common/Loader';
import HistoryAppBar from './HistoryAppBar';
import HistoryItem from './HistoryItem';
import HistoryListHead from './HistoryListHead';
import Pagination from 'src/components/common/pagination/Pagination';

import withDataResolver from 'src/components/common/withDataResolver';
import statsApi from 'src/utils/api/stats';
import utils from 'src/utils/utils';
import hUtils from './utils';
import filtrable from 'src/components/common/hocs/filtrable';
import LiTabs from 'src/components/common/liTab/LiTab';
import DateInputs from './DateFilterInputs';
import HistorySummary from './HistorySummary';
import ActionType from 'src/enums/actionType';

const styles = theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  tableWrapper: {
    overflow: 'auto',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  historyTopContainer: {
    position: 'relative',
    margin: '8px',
    overflow: 'auto',
    display: 'flex',
  },
});

const NoResult = () => (
  <Typography
    component="span"
    color="textSecondary"
    style={{
      display: 'inline',
      fontSize: '0.975rem',
    }}
  >
    {utils.getLang(`smartmessaging.list.noResult`)}
  </Typography>
);

function HistoryRender({
  campaign,
  history,
  goToDetails,
  exportExecutionResult,
  classes,
  loading,
  excludedProperties,
  rowSpanCols,
  sortable,
  doSort,
  sortConfig,
  loader,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  return (
    (loading && loader && <Loader text={utils.getLang('smartmessaging.loadingHistory')} />) || (
      <div className={classes.historyTopContainer}>
        <Paper className={classes.paper} elevation={0}>
          {!!history.length && (
            <div className={classes.tableWrapper}>
              <Table aria-labelledby="tableTitle">
                <HistoryListHead
                  sort={doSort}
                  sortConfig={sortConfig}
                  columns={hUtils.getColumns(history[0].items[0], excludedProperties)}
                  sortable={sortable}
                />
                <TableBody>
                  {history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(h => (
                    <Fragment key={h.id}>
                      <HistoryItem
                        columnNames={hUtils.getColumns(h.items[0], excludedProperties)}
                        addGoToDetailColum
                        campaign={campaign}
                        item={h.items[0]}
                        exportExecutionResult={exportExecutionResult}
                        rowSpanCols={rowSpanCols}
                        rowSpan={h.items.length || 1}
                        goToDetails={() => {
                          goToDetails(h.items);
                        }}
                        specificRender={{
                          sentMessageNumber: () => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {h.items[0].sentMessageNumber}
                              {!!h.items[0].sentMessageNumber && (
                                <Tooltip
                                  title={utils.getLang('smartmessaging.history.executionExport')}
                                >
                                  <IconButton
                                    style={{ padding: '4px', opacity: 0.4 }}
                                    className="hovershow"
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      exportExecutionResult(
                                        h.items[0].executionLogByClubId,
                                        h.items[0].actionType
                                      );
                                    }}
                                    size="large"
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          ),
                        }}
                      />
                      {h.items.slice(1).map(i => (
                        <HistoryItem
                          columnNames={hUtils.getColumns(i, [
                            ...excludedProperties,
                            ...rowSpanCols,
                          ])}
                          key={uuid()}
                          campaign={campaign}
                          item={i}
                          exportExecutionResult={exportExecutionResult}
                          rowSpanCols={rowSpanCols}
                          goToDetails={() => {
                            goToDetails(h.items);
                          }}
                          specificRender={{
                            sentMessageNumber: (colName, item) => (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {item.sentMessageNumber}
                                {!!item.sentMessageNumber && (
                                  <Tooltip
                                    title={utils.getLang('smartmessaging.history.executionExport')}
                                  >
                                    <IconButton
                                      style={{ padding: '4px', opacity: 0.4 }}
                                      className="hovershow"
                                      onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        exportExecutionResult(
                                          item.executionLogByClubId,
                                          item.actionType
                                        );
                                      }}
                                      size="large"
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            ),
                          }}
                        />
                      ))}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          {!!history.length && history.length > 20 && (
            <Table>
              <TableFooter
                style={{
                  boxShadow:
                    '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)',
                }}
              >
                <TableRow>
                  <Pagination
                    pages={history.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
          {!history.length && <NoResult />}
        </Paper>
      </div>
    )
  );
}

HistoryRender.propTypes = {
  campaign: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  goToDetails: PropTypes.func.isRequired,
  exportExecutionResult: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loader: PropTypes.bool.isRequired,
  excludedProperties: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array.isRequired,
  sortable: PropTypes.array.isRequired,
  doSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
};

const StyledHistory = withStyles(HistoryRender, styles);

const HistoryTabs = props => {
  const [tabVal, setTabVal] = useState('detail');

  const onTabChange = useCallback(val => {
    setTabVal(val);
  }, []);

  return (
    <Fragment>
      <LiTabs
        onChange={onTabChange}
        tabs={[
          { value: 'detail', label: utils.getLang('smartmessaging.history.detail') },
          { value: 'summary', label: utils.getLang('smartmessaging.history.summary') },
        ]}
        value={tabVal}
      />
      {(() => {
        switch (tabVal) {
          case 'detail':
            return <StyledHistory {...props} />;
          case 'summary':
            return <HistorySummary {...props} />;
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};

function HistorySorter({ history, clickedLinkList, ...props }) {
  const [sortConfig, setSortConfig] = useState({
    orderBy: 'date',
    order: 'desc',
  });

  const doSort = useCallback(
    column => {
      let { order } = sortConfig;
      if (sortConfig.orderBy === column) {
        order = sortConfig.order === 'asc' ? 'desc' : 'asc';
      }
      setSortConfig({ order, orderBy: column });
    },
    [sortConfig]
  );

  function getSummary() {
    function sumItem(item1, item2) {
      if (!item1) return item2;
      const sum = { ...item1 };
      const colsToSum = [
        // 'executionLogByClubId',
        // 'date',
        // 'launchedBy',
        // 'targetNumber',
        // 'actionType',
        'sentMessageNumber',
        'openedMessageNumber',
        'openPercentage',
        // 'clubName',
        // 'setupValue',
        // 'setupColumns',
        // 'optinType',
        // 'status',
        'targetWithOptin',
        // 'targetTotal',
      ];
      Object.keys(item2).forEach(k => {
        if (colsToSum.indexOf(k) !== -1) {
          switch (k) {
            case 'openPercentage': {
              if (
                [ActionType.MEMBER_NOTIFICATION.id].indexOf(item1.actionType) === -1 &&
                (!!item1.sentMessageNumber || !!item2.sentMessageNumber)
              ) {
                const prevTotalSent = item1.sentMessageNumber || 0;
                const newSends = item2.sentMessageNumber || 0;
                let p1 = item1.sentMessageNumber ? item1[k] : item2[k];
                let p2 = item2.sentMessageNumber ? item2[k] : p1;
                p1 = Number(p1.slice(0, -1) || 0);
                p2 = Number(p2.slice(0, -1) || 0);
                sum[k] = (p1 * prevTotalSent + p2 * newSends) / (prevTotalSent + newSends);
                sum[k] = Math.round(sum[k] * 100) / 100;
                sum[k] = `${sum[k]}%`;
              }
              break;
            }
            case 'openedMessageNumber':
              if ([ActionType.MEMBER_NOTIFICATION.id].indexOf(item1.actionType) === -1)
                sum[k] = item1[k] + item2[k];
              break;
            default:
              sum[k] = item1[k] + item2[k];
          }
        }
      });
      return sum;
    }

    function sumItems(summary, items) {
      if (!items.length) return summary;
      const { boundDates, clubList, optinTypeList } = summary;
      const firstItem = Object.values(summary.item)[0];
      let targetNumber = firstItem ? firstItem.targetNumber || 0 : 0;
      targetNumber += items[0].targetNumber;
      items.forEach(i => {
        if (clubList.indexOf(i.clubName) === -1) {
          clubList.push(i.clubName);
        }
        if (optinTypeList.indexOf(i.optinType) === -1) {
          optinTypeList.push(i.optinType);
        }
        if (!boundDates.start || new Date(i.date) < boundDates.start) {
          boundDates.start = new Date(i.date);
        }
        if (!boundDates.end || new Date(i.date) > boundDates.end) {
          boundDates.end = new Date(i.date);
        }
      });

      const rez = items.reduce(
        (sum, item) => ({
          ...sum,
          item: {
            ...sum.item,
            [item.actionType]: {
              ...sumItem(sum.item[item.actionType], item),
              targetNumber,
            },
          },
        }),
        { ...summary, boundDates, clubList, optinTypeList }
      );
      return rez;
    }

    const rez = history.reduce((summary, hitem) => sumItems(summary, hitem.items), {
      item: {},
      boundDates: { start: null, end: null },
      clubList: [],
      optinTypeList: [],
    });
    rez.item = Object.values(rez.item);
    return rez;
  }

  function getClickedLinks() {
    const execLogIds = history.map(h => parseInt(h.id, 10));
    const filteredList = clickedLinkList.filter(
      e => execLogIds.indexOf(e.executionLogByClubId) !== -1
    );

    function sumItem(item1, item2) {
      if (!item1) return item2;
      return { ...item1, count: item1.count + item2.count };
    }

    const mapByLinkTypeAndUrl = filteredList.reduce(
      (mapBy, item) => ({
        ...mapBy,
        [`${item.linktype}-${item.url}`]: sumItem(mapBy[`${item.linktype}-${item.url}`], item),
      }),
      {}
    );

    const mapByLinkType = Object.values(mapByLinkTypeAndUrl).reduce(
      (mapBy, entry) => ({
        ...mapBy,
        [entry.linktype]: mapBy[entry.linktype] ? [...mapBy[entry.linktype], entry] : [entry],
      }),
      {}
    );

    return Object.values(mapByLinkType);
  }

  return (
    <HistoryTabs
      history={history.sort(hUtils.getSorter(sortConfig.orderBy, sortConfig.order))}
      summary={getSummary()}
      clickedLinks={getClickedLinks()}
      sortConfig={sortConfig}
      doSort={doSort}
      {...props}
    />
  );
}

HistorySorter.propTypes = {
  history: PropTypes.array.isRequired,
  clickedLinkList: PropTypes.array.isRequired,
};

function HistoryFiltrable({
  filteredData,
  doFilter,
  addFilter,
  removeFilter,
  updateFilter,
  clearFilters,
  selectedFilters,
  ...props
}) {
  const addStartDate = useCallback(
    startDate => {
      const startDateFilter = selectedFilters.find(sf => sf.key === 'startDate');
      if (startDateFilter) {
        updateFilter({ ...startDateFilter, value: startDate }, true);
      } else addFilter({ key: 'startDate', value: startDate }, true);
    },
    [addFilter, selectedFilters, updateFilter]
  );

  const addEndDate = useCallback(
    endDate => {
      const endDateFilter = selectedFilters.find(sf => sf.key === 'endDate');
      if (endDateFilter) {
        updateFilter({ ...endDateFilter, value: endDate }, true);
      } else addFilter({ key: 'endDate', value: endDate }, true);
    },
    [addFilter, selectedFilters, updateFilter]
  );
  const removeStartDate = useCallback(() => {
    const startDateFilter = selectedFilters.find(sf => sf.key === 'startDate');
    if (startDateFilter) removeFilter(startDateFilter, true);
  }, [removeFilter, selectedFilters]);

  const removeEndDate = useCallback(() => {
    const endDateFilter = selectedFilters.find(sf => sf.key === 'endDate');
    if (endDateFilter) removeFilter(endDateFilter, true);
  }, [removeFilter, selectedFilters]);

  return (
    <Fragment>
      <DateInputs
        addStartDate={addStartDate}
        addEndDate={addEndDate}
        selectedFilters={selectedFilters}
        doFilter={doFilter}
        removeStartDate={removeStartDate}
        removeEndDate={removeEndDate}
      />
      <HistorySorter {...props} history={filteredData} />
    </Fragment>
  );
}

HistoryFiltrable.propTypes = {
  filteredData: PropTypes.array.isRequired,
  doFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

const filterCfg = props => ({
  filtrableData: props.history,
  runAlways: false,
  filterFn: filters => item => {
    const startDate = filters.find(f => f.key === 'startDate');
    const endDate = filters.find(f => f.key === 'endDate');
    if (!!startDate && new Date(item.items[0].date) < startDate.value) return false;
    if (!!endDate && new Date(item.items[0].date) > endDate.value) return false;
    return true;
  },
});

const HistoryFiltered = filtrable(filterCfg)(HistoryFiltrable);

const HistoryData = withDataResolver({
  resolve: props => async callApi => {
    const loadData = async () => {
      props.setIsLoading(true);
      const history = await callApi(statsApi.getHistoryByCmp, [props.campaign.id]).finally(() => {
        props.setIsLoading(false);
      });

      props.onReceiveHistory(
        props.receiveHistory
          ? props.receiveHistory(history)
          : Object.entries(history.globalStatsList).reduce(
              (m, [executionLogByClubId, eList]) => [
                ...m,
                {
                  id: executionLogByClubId,
                  items: eList.map(e => ({ ...e, setupValue: JSON.parse(e.setupValue) })),
                },
              ],
              []
            ),
        history.redirectLogCountList || []
      );
    };

    const triggerLoad = async () => {
      loadData().finally(() => {
        props.setReloadTimeOut(triggerLoad);
      });
    };

    props.setReload(() => triggerLoad);

    props.showLoader(true);
    triggerLoad().finally(() => {
      props.showLoader(false);
    });
  },
  onResolved: () => async () => {},
  loader: false,
})(HistoryFiltered);

const BarButtons = ({ exportHistory, reload }) => (
  <Fragment>
    <div
      style={{
        boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
        padding: '0 8px',
      }}
    >
      <Tooltip title={utils.getLang('smartmessaging.history.refresh')}>
        <IconButton
          style={{ margin: '2px' }}
          onClick={e => {
            e.preventDefault();
            reload();
          }}
          size="large"
        >
          <Refresh color="secondary" />
        </IconButton>
      </Tooltip>

      {exportHistory && (
        <Tooltip title={utils.getLang('smartmessaging.history.export')}>
          <IconButton
            style={{ margin: '2px' }}
            onClick={e => {
              e.preventDefault();
              exportHistory();
            }}
            size="large"
          >
            <DownloadIcon color="secondary" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  </Fragment>
);

BarButtons.propTypes = { exportHistory: PropTypes.any, reload: PropTypes.func };
BarButtons.defaultProps = { exportHistory: null, reload: null };

const History = ({
  campaign,
  backToMain,
  exportHistory,
  exportExecutionResult,
  history,
  reload,
  ...otherProps
}) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      textAlign: 'left',
      height: '100%',
    }}
  >
    <HistoryAppBar
      title={utils.getLang('smartmessaging.history.title')}
      campaignName={campaign.name}
      onBack={backToMain}
      barButtons={
        <BarButtons
          reload={!!reload && reload}
          exportHistory={!!history.length && (() => exportHistory(campaign.id))}
        />
      }
    />
    <HistoryData
      campaign={campaign}
      exportExecutionResult={exportExecutionResult}
      history={history}
      {...otherProps}
    />
  </div>
);

History.propTypes = {
  campaign: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  goToDetails: PropTypes.func.isRequired,
  backToMain: PropTypes.func.isRequired,
  exportHistory: PropTypes.func.isRequired,
  exportExecutionResult: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
};

const HistoryHandler = ({ campaign, goToHistoryStep, ...otherProps }) => {
  const [history, setHistory] = useState([]);
  const [clickedLinkList, setClickedLinkList] = useState([]);
  // TODO FIXME function in useState
  const [doReload, setReload] = useState(null);
  const [loader, showLoader] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState(null);

  const reload = useCallback(() => {
    if (!loading) {
      showLoader(true);
      doReload().finally(() => {
        showLoader(false);
      });
    }
  }, [doReload, loading]);

  const setReloadTimeOut = useCallback(loadFn => {
    setTimeoutRef(setTimeout(loadFn, 15000));
  }, []);

  const clearReloadTimeout = useCallback(() => {
    if (timeoutRef) clearTimeout(timeoutRef);
    setTimeoutRef(null);
  }, [timeoutRef]);

  useEffect(() => () => clearTimeout(timeoutRef));

  const onReceiveHistory = useCallback((h, l) => {
    setHistory(h);
    setClickedLinkList(l);
  }, []);

  const goToDetails = useCallback(
    h => {
      goToHistoryStep(1, {
        campaign,
        historyItem: h,
        filters: hUtils.proceedSetup(h[0].setupValue),
        clickedLinkList: Object.values(
          Object.values(
            clickedLinkList
              .filter(e => e.executionLogByClubId === h[0].executionLogByClubId)
              .reduce(
                (mapBy, item) => ({
                  ...mapBy,
                  [`${item.linktype}-${item.url}`]: !mapBy[`${item.linktype}-${item.url}`]
                    ? item
                    : {
                        ...mapBy[`${item.linktype}-${item.url}`],
                        count: mapBy[`${item.linktype}-${item.url}`].count + item.count,
                      },
                }),
                {}
              )
          ).reduce(
            (mapBy, entry) => ({
              ...mapBy,
              [entry.linktype]: mapBy[entry.linktype] ? [...mapBy[entry.linktype], entry] : [entry],
            }),
            {}
          )
        ),
      });
    },
    [campaign, clickedLinkList, goToHistoryStep]
  );

  return (
    <History
      history={history}
      clickedLinkList={clickedLinkList}
      campaign={campaign}
      onReceiveHistory={onReceiveHistory}
      goToDetails={goToDetails}
      reload={reload}
      setReload={setReload}
      loading={loading}
      showLoader={showLoader}
      loader={loader}
      setReloadTimeOut={setReloadTimeOut}
      clearReloadTimeout={clearReloadTimeout}
      setIsLoading={setIsLoading}
      {...otherProps}
    />
  );
};

HistoryHandler.propTypes = {
  campaign: PropTypes.object.isRequired,
  goToHistoryStep: PropTypes.func.isRequired,
};

export default HistoryHandler;

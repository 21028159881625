import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

function FilterHolder({ Cmp, cmpProps, filterConfig }) {
  const [selectedFilters, setSelectedFilters] = useState(
    () =>
      (filterConfig.defaultFilters &&
        filterConfig.defaultFilters.map(f => ({ ...f, id: uuid() }))) ||
      []
  );
  const [appliedFilters, setAppliedFilters] = useState(selectedFilters);

  const addFilter = useCallback((filter, apply) => {
    let newSelection;
    setSelectedFilters(prev => {
      newSelection = [...prev, { ...filter, id: uuid() }];
      return newSelection;
    });
    if (apply) setAppliedFilters(newSelection);
  }, []);

  const setFilters = useCallback((filters, apply) => {
    let newSelection;
    setSelectedFilters(() => {
      newSelection = filters.map(f => ({ ...f, id: uuid() }));
      return newSelection;
    });
    if (apply) setAppliedFilters(filters);
  }, []);

  const removeFilter = useCallback((filter, apply) => {
    let newSelection;
    setSelectedFilters(prev => {
      newSelection = prev.filter(f => f.id !== filter.id);
      return newSelection;
    });
    if (apply) setAppliedFilters(newSelection);
  }, []);

  const doFilter = useCallback(() => {
    setAppliedFilters(selectedFilters);
  }, [selectedFilters]);

  const updateFilter = useCallback((filter, apply) => {
    let newSelection;
    setSelectedFilters(prev => {
      newSelection = prev.map(p => (p.id === filter.id ? { ...p, value: filter.value } : p));
      return newSelection;
    });
    if (apply) setAppliedFilters(newSelection);
  }, []);

  const clearFilters = useCallback(apply => {
    setSelectedFilters([]);
    if (apply) setAppliedFilters([]);
  }, []);

  function getFiltrableData() {
    return filterConfig.toFiltrable
      ? filterConfig.toFiltrable(filterConfig.filtrableData)
      : filterConfig.filtrableData;
  }

  return (
    <Cmp
      filteredData={
        (((appliedFilters && appliedFilters.length) || filterConfig.runAlways) &&
          getFiltrableData().filter(filterConfig.filterFn(appliedFilters))) ||
        getFiltrableData()
      }
      setFilters={setFilters}
      addFilter={addFilter}
      removeFilter={removeFilter}
      doFilter={doFilter}
      clearFilters={clearFilters}
      updateFilter={updateFilter}
      selectedFilters={selectedFilters}
      {...cmpProps}
    />
  );
}

FilterHolder.propTypes = {
  Cmp: PropTypes.any.isRequired,
  cmpProps: PropTypes.object.isRequired,
  filterConfig: PropTypes.object.isRequired,
};

export default getFilterConfig => Cmp => props => (
  <FilterHolder Cmp={Cmp} filterConfig={getFilterConfig(props)} cmpProps={props} />
);

import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteParams } from 'src/reducers/parameters';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(theme => ({
  menuItemDelete: {
    color: theme.palette.warning.light,
  },
  menuItem: {
    outline: 'none',
    textOverflow: 'ellipsis',
  },
}));

function SmParamsListItemMenu({ edit, doDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  const { classes, cx } = useStyles();

  return (
    <Fragment>
      <IconButton
        style={{ padding: '4px' }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            minWidth: 150,
            borderRadius: 0,
          },
        }}
        MenuListProps={{ disablePadding: true }}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
      >
        <MenuItem
          className={classes.menuItem}
          dense
          onClick={e => {
            e.preventDefault();
            edit();
          }}
        >
          {utils.getLang(`smartmessaging.config.paramsList.editParams`)}
        </MenuItem>
        <MenuItem
          className={cx(classes.menuItem, classes.menuItemDelete)}
          dense
          onClick={e => {
            e.preventDefault();
            doDelete();
          }}
        >
          {utils.getLang(`smartmessaging.config.paramsList.deleteParams`)}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

SmParamsListItemMenu.propTypes = {
  edit: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
};

const ClubParameterList = ({ hideList, paramsMap, setCurrentParams, currentParams, doDelete }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        overflow: 'auto',
        flex: 1,
        height: '100%',
        padding: '2px 4px',
      }}
    >
      <Table style={{ width: 'unset', minWidth: '50%' }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: theme.palette.primary.main,
                padding: '4px',
                position: 'sticky',
                top: 0,
                background: '#ffffff',
              }}
            >
              {utils.getLang(`smartmessaging.config.paramsListLabel.clubName`)}
            </TableCell>
            <TableCell
              style={{ color: theme.palette.primary.main, padding: '4px', textAlign: 'center' }}
            >
              {utils.getLang(`smartmessaging.config.paramsListLabel.paramsAlreadyCreated`)}
            </TableCell>
            <TableCell
              style={{
                position: 'sticky',
                top: 0,
                background: '#ffffff',
                zIndex: 1,
              }}
            />
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.entries(paramsMap).map(([uniqueId, params]) => (
            <TableRow
              key={uniqueId}
              hover
              onDoubleClick={e => {
                e.preventDefault();
                setCurrentParams(uniqueId);
                hideList();
              }}
              selected={currentParams === uniqueId}
            >
              <TableCell style={{ padding: '4px', color: theme.palette.text.secondary }}>
                {(params.clubName === 'default' &&
                  utils.getLang('smartmessaging.config.selectClub.default.value')) ||
                  params.clubName ||
                  params.originalClubId}
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {params.id || params.id === 0 ? (
                  <DoneIcon color="primary" />
                ) : (
                  <ClearIcon style={{ color: theme.palette.text.secondary }} />
                )}
              </TableCell>
              <TableCell>
                <SmParamsListItemMenu
                  edit={() => {
                    setCurrentParams(uniqueId);
                    hideList();
                  }}
                  doDelete={() => {
                    doDelete(params.id, uniqueId);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

ClubParameterList.propTypes = {
  paramsMap: PropTypes.object.isRequired,
  setCurrentParams: PropTypes.func.isRequired,
  hideList: PropTypes.func.isRequired,
  currentParams: PropTypes.string,
  doDelete: PropTypes.func.isRequired,
};

ClubParameterList.defaultProps = { currentParams: '' };

const mapStateToProps = state => ({
  currentParams: state.parameters.currentParams,
});

const actionCreators = {
  setCurrentParams: key => ({
    type: 'SET_CURRENT_PARAMS',
    value: key,
  }),
  doDelete: deleteParams,
};

export default connect(mapStateToProps, actionCreators)(ClubParameterList);

import { createReducer } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notify';
import getApiCaller from 'src/utils/apiClientCaller';
import smjApi from 'src/utils/api/smartjourneyApi';

import utils from 'src/utils/utils';

import { loaderActions } from '../loader';
import { loadRequestModels } from '../requestModelList';

const initialState = {
  currentStep: 0,
  smjListsByModel: [], // not by model finally
  smjModelList: [],
  smjInfos: {},
};

export const enableSMJ = smj => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(dispatch, getState);

  const asyncFn = async () => {
    setLoader(true);
    const activationResponse = await callApi(smjApi.activateSMJ, [smj.id, !smj.enabled]);

    if (activationResponse.isSuccess) {
      dispatch(
        enqueueSnackbar({
          message: `${smj.name}: ${
            !smj.enabled
              ? utils.getLang('smartmessaging.notifications.enabledCampaign')
              : utils.getLang('smartmessaging.notifications.disabledCampaign')
          }`,

          options: {
            variant: 'info',
          },
        })
      );
      const upToDateList = [...getState().smartjourney.smjList].map(listSMJ => {
        if (listSMJ.id === smj.id) {
          return { ...smj, enabled: !smj.enabled };
        }
        return listSMJ;
      });

      dispatch({ type: 'RECEIVE_SMJ_LIST', value: upToDateList });
    } else {
      dispatch({
        type: 'SHOW_ALERT',
        value: {
          type: 'warning',
          title: utils.getLang('smartmessaging.errorMessages.smartjourney.notActivable.title'),
          msg: utils.getLang(
            (activationResponse.error &&
              `smartmessaging.errorMessages.smartjourney.notActivable.${activationResponse.error}`) ||
              'smartmessaging.errorMessages.smartjourney.notActivable.message'
          ),
        },
      });
    }
  };
  await asyncFn().finally(() => {
    setLoader(false);
  });
};

const receiveSMJList = (state, { value }) => ({
  ...state,
  smjListsByModel: value,
});

const receiveSMJInfos = (state, { value }) => ({
  ...state,
  smjInfos: value,
});

const receiveSMJModelList = (state, { value }) => ({
  ...state,
  smjModelList: value,
});

export const createSMJ = ({ cmpList, smj, language }, onSuccess, onFail) => async (
  dispatch,
  getState
) => {
  const apiCaller = getApiCaller(dispatch, getState);
  await apiCaller(smjApi.createSMJ, [
    {
      cmpList: cmpList.map(cmp => ({
        ...cmp,
        name: utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${cmp.key}`),
      })),
      smj,
      language,
    },
  ]).then(
    async res => {
      if (!res) {
        dispatch({
          type: 'SHOW_ALERT',
          value: {
            type: 'warning',
            title: utils.getLang('smartmessaging.smartjourney.createdWithErrors.title'),
            msg: utils.getLang('smartmessaging.smartjourney.createdWithErrors.title'),
          },
        });
      }
      dispatch(
        enqueueSnackbar({
          message: `${smj.name}: ${utils.getLang(
            'smartmessaging.notifications.smj.createdSuccess'
          )}`,
          options: {
            variant: 'success',
          },
        })
      );
      if (onSuccess) {
        const newSmj = await apiCaller(smjApi.getCampaignGroup, [res]);
        onSuccess(newSmj);
      }
    },
    () => {
      dispatch(
        enqueueSnackbar({
          message: `${smj.name}: ${utils.getLang(
            'smartmessaging.notifications.smj.createFailure'
          )}`,
          options: {
            variant: 'warning',
          },
        })
      );
      if (onFail) onFail();
    }
  );
};

export const initData = () => async (dispatch, getState) => {
  const apiCaller = getApiCaller(dispatch, getState);
  await loadRequestModels()(dispatch, getState);
  const smjModelList = await apiCaller(smjApi.getSMJModelList);
  dispatch({
    type: 'RECEIVE_SMJ_MODEL_LIST',
    value: Object.entries(smjModelList).map(([id, model]) => ({ id, ...model })),
  });
};

export const loadSMJs = () => async (dispatch, getState) => {
  const apiCaller = getApiCaller(dispatch, getState);
  const smjList = await apiCaller(smjApi.getSMJList);
  let infos = [];
  if (smjList && smjList.length) {
    infos = await apiCaller(smjApi.getSmjInfosById, [smjList.map(s => s.id)]);
  }
  dispatch({ type: 'RECEIVE_SMJ_LIST', value: smjList });
  dispatch({ type: 'RECEIVE_SMJ_INFOS', value: infos });
};

export default createReducer(initialState, {
  RECEIVE_SMJ_LIST: receiveSMJList,
  RECEIVE_SMJ_INFOS: receiveSMJInfos,
  RECEIVE_SMJ_MODEL_LIST: receiveSMJModelList,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  hRoot: { paddingBottom: 0, paddingLeft: 0, paddingTop: 0 },
  sticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.default.paper,
    zIndex: 1,
  },
  visibleOverflow: { overflow: 'visible' },
  title: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    textAlign: 'left',
  },
  cardHeader: { borderBottom: theme.border.primary },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
}));

const CustomCard = ({ action, title, children, avatar, sticky, contentStyle, rootStyle }) => {
  const { classes } = useStyles();
  return (
    <Card elevation={0} classes={{ root: classes.visibleOverflow }} style={rootStyle}>
      {title !== undefined && (
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title,
            action: classes.action,
            root: `${classes.hRoot} ${sticky ? classes.sticky : ''}`,
          }}
          action={action}
          avatar={avatar}
          title={title}
        />
      )}
      <CardContent style={{ /* overflow: 'auto', */ padding: '8px', ...contentStyle }}>
        {children}
      </CardContent>
    </Card>
  );
};

CustomCard.propTypes = {
  action: PropTypes.any,
  avatar: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any.isRequired,
  sticky: PropTypes.bool,
  contentStyle: PropTypes.object,
  rootStyle: PropTypes.object,
};
CustomCard.defaultProps = {
  action: null,
  avatar: null,
  sticky: false,
  children: null,
  contentStyle: {},
  rootStyle: {},
};

export default CustomCard;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Toolbar, Typography, AppBar } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
}));

const SMJCreatorAppBar = ({ title }) => {
  const { classes } = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Typography variant="h5" color="inherit">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

SMJCreatorAppBar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SMJCreatorAppBar;
